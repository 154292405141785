/*=========================================
 * Global navbar styling (all sizes, all subclasses)
 *=======================================*/

.z-index-zero
  z-index: 0 !important

.navbar
  width: 100%
  padding: 0px !important
  opacity: 1
  z-index: 52

  .account-logo-name
    max-width: 300px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &.opaque
    opacity: 0.2

  &.navbar-fixed
    position: fixed
    top: 0px

  // Increase spacing left of toggler to match links
  .navbar-toggler
    padding-left: 1.25rem

  .navbar-nav

    .nav-item

      // Active class for navbar items -- bold
      &.active, &.active .nav-link, .nav-link.active
        font-weight: 600 !important

      // Shift right-hand dropdown menus further to the left
      // Otherwise they're flush with the edge of the window (bootstrap default)
      .dropdown-menu-right
        right: 10px !important

      // Display dropdowns in collapsed menus through Bootstrap collapse method displaying
      // on hover is really bad ux in md and down windows
      @include media-breakpoint-down(md)

        // Keep position of right dropdown menus at absolute, don't move to static on small devices
        // Static position breaks the notifications menu
        .dropdown-menu-right, .dropdown-menu.narrower
          position: absolute !important


        ul.dropdown-menu
          display: block !important
          transition: all 0.3s ease
          background-color: $gray-100
          padding-top: 0px
          padding-bottom: 0px
          border: 0px
          overflow: auto

          li

            a.dropdown-item
              padding-left: 10px
              padding-right: 10px

      // Nav-link coloring -- gray, primary on hover
      .nav-link
        color: $gray-700

        &:hover
          color: $primary

.sticky-header-container
  height: 100%
  width: 100%

  @include media-breakpoint-up(lg)
    position: absolute
    top: 0

.sticky-header
  position: -webkit-sticky
  position: sticky !important
  // Z-index must be below navbar z-index for IE10 and IE11, which render sticky as position-relative
  // https://getbootstrap.com/docs/4.4/utilities/position/
  z-index: 101

  @include media-breakpoint-up(lg)
    transition: 0.3s ease-in    
    top: $app-navbar-height // Offset so content not hidden behind navbar

