/*=========================================
 * Table global custom styling
 *=======================================*/

.table
  // Need to set width 100% as important to make sure it's always 100%
  // Bootstrap 100% table width not 'important'
  width: 100% !important
  color: inherit

  thead

    th      
      border-top: 0px !important 
      border-bottom: 0px
      vertical-align: middle
      font-weight: 600 // Bold font weight for headers

      // Opaque class used when re-sizing tables, e.g., for portfolio builder
      &.opaque
        opacity: 0 !important
  
        // Make sortable headers show pointer to help indicate clickable
      &.sorting, &.sorting_desc, &.sorting_asc
        cursor: pointer

  th, td
    // white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis !important 
    font-size: $h6-font-size

    @include media-breakpoint-down(sm)
      max-width: 230px

  tbody

    td
      min-height: 32px
      vertical-align: top
      padding-top: 8px
      padding-bottom: 7px

  tfoot 
    th, td
      font-weight: 400 !important
      min-height: 32px
      vertical-align: top
      padding-top: 8px
      padding-bottom: 7px

  // Opaque class used when re-sizing or rendering tables
  &.opaque
    opacity: 0 !important

  // Text right
  &.last-right
    tbody td:nth-last-child(2)
      text-align: right !important
  
  // Make rows clickable
  tbody tr:not(.no-click):hover
    cursor: pointer
