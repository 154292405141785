#fileUploadProgress

  .progress
    margin-bottom: 15px
    max-width: none !important

.filepicker-container
  position: relative
  height: 100%
  width: 100%

.filepicker-label
  position: absolute
  top: -2px
  left: 0
  width: 100%
  z-index: 1

// Min height for label when in a modal
.modal-body

  .filepicker-container
    display: flex !important
    align-items: center !important
    justify-content: center !important

    .filepicker
      opacity: 0

    .filepicker-label
      height: 100%
      border: 1px dashed $gray-300
      border-radius: 5px
      background-color: $gray-100

      &:hover
        background-color: darken($gray-100, 2%)


  .filepicker-container, .filepicker-label
    min-height: 300px
