.accordion

  .card:not(:last-child)
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important

  .card:not(:first-child)
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important
    border-top: 0px !important

  .accordion-section:not(:first-child)
    border-top: 1px solid $gray-300


  ul.accordion-item
    padding: 0px
    margin-bottom: 0px !important // Without this the collapse action causes re-sizing when clicked

    li.subcategory-heading
      list-style: none
      border-top: 1px dashed $gray-300

      // Fix for stretched links on collapsing otherwise click on fa-chevron doesn't work
      a.stretched-link:not(.collapsed)
        position: relative !important

      a.accordion-content
        padding: 0.625rem 1rem
        padding-left: 25px !important
        align-items: center
        display: block
        font-size: $h5-font-size !important

        i
          float: right
          font-style: normal
          width: 32px
          text-align: center

        &.active
          font-weight: 600 !important
          color: $primary
          text-decoration: none

          i
            color: $gray-700 !important

      ul.accordion-menu-item
        padding: 0px
        background: rgba(0, 0, 0, 0.02)

        li
          padding-top: 3px
          padding-bottom: 3px

          &:first-child
            padding-top: 6px

          &:last-child
            padding-bottom: 6px

          a.accordion-content, a.accordion-link
            padding: 7px 20px 7px 15px
            text-transform: none !important

