body[data-page="QuestionnairesEmailsNew"]

  #emailTemplatesTab, #emailsSentTab

    th
      border-bottom: 1px solid $gray-300 !important

    tr 
      position: relative

    td, th
      background-color: $white !important

    .dataTables_paginate
      display: inline-block
      margin-bottom: 10px
      font-weight: 300 !important
      font-size: $h5-font-size !important
      position: absolute
      bottom: 0px
      right: 15px

  #emailsSentTab

    td, th
      text-align: left
      width: 25% !important

  #emailTemplatesTab

    td:nth-child(2), th:nth-child(2)
      text-align: left
      width: 50% !important

    td:last-child, th:last-child
      width: 10% !important

    td:first-child, th:first-child
      text-align: left
      width: 40% !important
