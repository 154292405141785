body[data-page="QuestionnairesCollectionResponsesWelcome"], body[data-page="QuestionnairesCollectionResponsesNewAccount"]

  // Connector div
  .data-collection-connector-div
    height: 20px
    width: 0px
    border-left-width: 3px
    border-left-style: solid
    position: absolute
    left: 50%

  // Other impact icons
  .data-collection-icons-container
    margin-top: 20px
    padding-top: 15px
    display: flex !important
    justify-content: center !important
    border-top-width: 3px
    border-top-style: solid
    border-radius: 15px

    // Icons
    span.fa-layers
      opacity: 0.6
      font-size: 6em

  .data-collection-welcome-message
    max-height: 400px
    overflow: scroll

  ::-webkit-scrollbar
    -webkit-appearance: none
    width: 7px

  ::-webkit-scrollbar-thumb
    border-radius: 4px
    background-color: rgba(0, 0, 0, .5)
    box-shadow: 0 0 1px rgba(255, 255, 255, .5)


body[data-page="QuestionnairesCollectionResponsesDashboard"]
  tr
    cursor: pointer !important


