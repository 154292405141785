// Filter controls for assessments
.impact-assessment-filter
  transition: 0.3s all ease

  &.opaque
    opacity: 0

  &:not(.opaque)
    opacity: 1

  &:not(:last-child)
    // border-bottom: 1px solid $gray-200
    padding-bottom: 20px

  // Adjust filter title to just above the actual filter
  .filter-title
    display: flex !important
    align-items: center !important
    font-size: $h4-font-size
    font-weight: 300
    margin-bottom: -23px

    // Filter value indicator
    .filter-indicator
      font-size: $h5-font-size 
      margin-left: auto
      padding: 2px 5px

  // Font size of grid text
  .irs-grid-text
    font-size: $h6-font-size !important
    cursor: pointer !important

  // Don't show grid between numbers
  .irs-grid-pol.small
    display: none

  .irs-line, .irs-bar, .irs-handle, .irs-handle.single i
    cursor: pointer

  // Hide labels above bars (showing in separate labels at top right of bars)
  .irs-single, .irs-from, .irs-to
    display: none

  // Make non-selected bar gray
  .irs-line
    background-color: $gray-300
