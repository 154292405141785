body[data-page="PortfoliosModelsEdit"], body[data-page="PortfoliosModelsOptimizer"], body[data-page="PortfoliosModelsNew"] 

  // Check boxes
  input.purple-check

    &:after
      cursor: pointer !important
      width: 15px
      height: 15px
      top: -2px
      left: -1px
      position: relative
      background-color: $gray-100
      content: ''
      display: inline-block
      visibility: visible
      border: 1px solid $gray-500

    &:checked:after
      cursor: pointer !important
      width: 15px
      height: 15px
      top: -2px
      left: -1px
      position: relative
      background-color: $primary
      content: ''
      display: inline-block
      visibility: visible

  .form-control:disabled
    background-color: $primary-x-light !important

  #exportOptimizerButtonMenu
    min-width: 200px
    width: 200px

  .optimizer-opts-left
    width: 230px

  .optimizer-opts-right
    border-left: 1px solid $gray-300

    .selected-button-container
      padding: 0.5rem
      height: 51px
      display: flex !important
      align-items: center !important

  .optimizer-toast
    width: 350px

  // Name/title
  #setModelInfo h3
    border: 1px solid transparent
    padding: 5px
    margin-left: -5px

    &:hover
      border: 1px solid $gray-200
      background-color: $white
      border-radius: 5px

  .card-footer.table-info-footer
    background-color: darken($primary-x-light, 1%)
    bottom: 0px
    height: 50px

  .model-footer
    position: fixed
    bottom: 0px
    left: 0px
    background-color: darken($primary-light, 1%)
    height: 50px
    border-top: 1px solid $gray-300
    z-index: 52
    display: flex !important
    align-items: center !important

    &.sidebar-offset:not(.minimized)
      @include media-breakpoint-down(sm)
        width: calc(100% - 70px) !important

      @include media-breakpoint-up(lg)
        width: calc(100% - 250px) !important

    &.sidebar-offset.minimized
      width: 100%

    .fix-width
      display: flex !important
      align-items: center !important

  // Main content div
  .model-content
    @include media-breakpoint-down(md)
      min-height: 400vh
    @include media-breakpoint-up(lg)
      min-height: 100vh

  // No min width
  .card-table-search
    min-width: min-content !important

  // Overflow in table names
  #addAssetsSection
    .logo-name-name
      @include media-breakpoint-up(lg)
        max-width: 180px !important

  .model-assets-positioning
    .logo-name-name
      @include media-breakpoint-up(lg)
        max-width: 240px !important

  .edit-assets-positioning
    .logo-name-name
      @include media-breakpoint-up(lg)
        max-width: 380px !important

  .rating-name
    max-width: 150px
    min-width: 85px
    white-space: normal !important

  th
    vertical-align: bottom !important

  td
    font-weight: 300 !important

  // Tables
  #researchResultsCard, #portfolio_model_items_body

    .table-container
      overflow: auto

      tr
        position: relative !important

  #researchResultsCard, .card:not(.full-view) #portfolio_model_items_body
    .table-container
      max-height: 71vh

  // Assets card
  #portfolioAssetsCard.full-view
    margin-top: 0px !important

    td:first-child, th:first-child
      min-width: 125px !important

    td:not(:first-child), th:not(:first-child)
      min-width: 125px !important

  // Items table
  #portfolio_model_items_body

    #portfolio_model_items_table
      margin-top: 0px !important

      td:not(:last-child), th:not(:last-child)
        text-align: left !important

      &.metric-model-items

        td:nth-child(4)
          min-width: 110px
          padding-right: 0px !important

          &:not(:first-child)
            text-align: right

      &:not(.metric-model-items)

        td:nth-child(3)
          min-width: 110px
          padding-right: 0px !important

          &:not(:first-child)
            text-align: right

      .input-group
        justify-content: flex-end !important

        input
          width: 95px !important
          max-width: 95px
          height: 24.5px !important
          border-right: 0px !important

        .input-group-append
          position: absolute
          height: 24.5px !important
          border-left: 0px !important
          
          .input-group-text
            background-color: $white !important
            border-left: 0px !important

    .dt-hover-outer, .dt-hover-outer-fixed
      right: 115px !important

    .dt-buttons 
      float: none !important
      vertical-align: top !important

      .btn
        height: 30px
        border: 1px solid $gray-300
        margin-right: 0px !important

  // Cause modal
  #causeModal, #metricModal

    .cat-link:not(:last-child)
      border-bottom: 1px solid $gray-300

  // Button overflows
  .overflow-ellipsis
    max-width: 125px
    overflow: hidden
    text-overflow: ellipsis


  .opt-modal

    input.typeahead
      font-weight: 300 !important
      height: 35px !important
      background-color: $white !important
      border-color: $gray-400 !important
      cursor: pointer

    input.typeahead:not(.h5)
      font-size: $h6-font-size

    .twitter-typeahead
      min-width: 300px !important

    .tt-menu
      z-index: 50 !important // Below navbar
      width: 100%

    .tt-suggestion
      line-height: inherit !important

      &:first-child
        padding: 14px 10px 7px 10px !important

      &:not(:first-child)
        padding: 7px 0px 7px 10px !important


  .card-list-items
    margin-right: -10px !important
    margin-left: -10px !important
    padding: inherit !important

    .card .description
      max-height: 84px !important
      overflow: visible !important

  .tt-suggestion:not(.no-selections)
    padding: 5px 10px !important
    min-height: 35px

  .tt-suggestion
    align-items: center !important

    &:not(.d-none)
      display: flex !important

    &:not(:last-child)
      border-bottom: 1px dashed $gray-300

    i, svg
      font-size: 20px !important
      margin-right: 1px

    span.checkbox
      width: 15px
      padding: 0px 10px
      border: 1px solid $gray-400
      border-radius: 3px

      &:hover
        -webkit-box-shadow: 0px 2px 3px $gray-500
        -moz-box-shadow: 0px 2px 3px $gray-500
        box-shadow: 0px 2px 3px $gray-500

  .change-filter-section
    cursor: pointer !important
    display: flex !important
    align-items: center !important

    &.btn
      justify-content: center !important

    .delete-filter
      display: none

    .card
      background-color: lighten($primary-light, 1%)

    &:hover

      .card
        background-color: darken($primary-light, 1%)

      .delete-filter
        display: block !important

  // Preview assets section (right side)
  #previewAssetsSection .card.opaque
    opacity: 0.2

  .criteria-row
    min-height: 46px
    padding: 0.5rem 0.5rem 0rem 1rem

    &:not(:last-child)
      border-bottom: 1px solid $gray-200

    .btn
      margin-bottom: 0.5rem
      margin-right: 0.5rem


  #expenseRatioSection, #totalAssetsSection

    // Labels above bars
    .irs-single, .irs-from, .irs-to
      background-color: $primary !important

      &::before
        border-top-color: $primary !important

  .slider-mix-body

    &.opaque
      opacity: 0

    &:not(.opaque)
      opacity: 1

    // Adjust filter title to just above the actual filter
    .filter-title
      display: flex !important
      align-items: center !important
      font-weight: 300
      margin-bottom: -23px

    // Font size of grid text
    .irs-grid-text
      font-size: 14px !important
      cursor: pointer !important
      font-family: $font-family-sans-serif !important
      color: $gray-700 !important

    // Don't show grid between numbers
    .irs-grid-pol.small
      display: none

    .irs-line, .irs-bar, .irs-handle, .irs-handle.single i
      cursor: pointer

    // Make non-selected bar gray
    .irs-line
      background-color: $gray-200

  .spinner-border:not(.xs)
    width: 1.5rem !important
    height: 1.5rem !important

  // Research results
  #researchResultsCard #research_table
    margin-top: 0px !important

    td:first-child
      max-width: 150px

    th:nth-child(3), td:nth-child(3)
      padding-left: 10px

  .research-sidebar
    overflow: visible

    // Remove filter
    .remove-model-filter div div
      white-space: pre-wrap
      text-align: left

  // Search causes
  .modal, .research-sidebar

    .card
      &:not(:first-child)
        border-top-left-radius: 0px !important
        border-top-right-radius: 0px !important

      &:not(:last-child)
        border-bottom: 0px !important
        border-bottom-left-radius: 0px !important
        border-bottom-right-radius: 0px !important

      &.minimized
        overflow: hidden !important

        .card-header
          border-bottom: 0px !important

        .card-body
          padding: 0px !important
          height: 0px !important
          min-height: 0px !important

      &:not(.minimized), .card-body
        overflow: visible !important

        input.typeahead:not(.normal-height), input.number-display:not(.normal-height)
          border-radius: 0px
          border: 0px
          padding-left: 15px !important
          background-color: transparent
          height: 48px !important

    .twitter-typeahead
      width: 100%

      .tt-menu .tt-suggestion
        padding: 8px 0px 8px 10px

        &:not(:last-child)
          border-bottom: 1px dashed $gray-200

        &:hover
          background-color: lighten($primary-light, 2%)

  .modal

    input.typeahead
      font-weight: 300 !important
      height: 35px !important
      background-color: $white !important
      border-color: $gray-400 !important
      cursor: pointer

    input.typeahead:not(.h5)
      font-size: $h6-font-size

    // .twitter-typeahead
    //   min-width: 300px !important

    .tt-menu
      z-index: 50 !important // Below navbar
      width: 100%

    .tt-suggestion
      line-height: inherit !important

      &:first-child
        padding: 14px 10px 7px 10px !important

      &:not(:first-child)
        padding: 7px 0px 7px 10px !important

  .research-filters

    a.remove-cause
      padding-top: 5px
      padding-bottom: 5px
      margin-right: 7px
      border: 1px solid $gray-300
      font-weight: 300
      font-size: $h6-font-size !important

  // Filter controls
  .slider-mix-body
    transition: 0.3s all ease

    &.opaque
      opacity: 0

    &:not(.opaque)
      opacity: 1

    // Selected bar
    .irs-bar
      background-color: darken($primary-light, 4%) !important

    // Color the pointer primary color
    .irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child
      background-color: darken($primary-light, 4%)

    // Height of bar container
    .irs--flat
      height: 50px !important

    // Selected bar
    .irs-bar
      background-color: $gray-200 !important

    // Color the pointer primary color
    .irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child
      background-color: $gray-500

    // Adjust filter title to just above the actual filter
    .filter-title
      display: flex !important
      align-items: center !important
      font-weight: 300
      margin-bottom: -23px

    // Font size of grid text
    .irs-grid-text
      font-size: 14px !important
      cursor: pointer !important
      font-family: $font-family-sans-serif !important
      color: $gray-700 !important

    // Don't show grid between numbers
    .irs-grid-pol.small
      display: none

    .irs-line, .irs-bar, .irs-handle, .irs-handle.single i
      cursor: pointer

    // Labels above bars
    .irs-single, .irs-from, .irs-to
      background-color: $primary !important

      &::before
        border-top-color: $primary !important

    // Make non-selected bar gray
    .irs-line
      background-color: $gray-200

