body[data-page="AssessmentsResults"]

  #causeKeywords .badge
    background-color: #fafafa !important

  #aboutRecommendations
    margin-top: 40px
    z-index: 1

    @include media-breakpoint-up(lg)
      margin-bottom: 40px

  .description .excerpted
    height: 104px
    overflow: hidden
    position: relative

  .down-indicator
    position: absolute
    bottom: 70px !important
    z-index: -1 // Put behind text
    width: 100vw

    span:first-child
      margin-left: 15px
      margin-right: auto

    span:last-child
      margin-right: 15px
      margin-left: auto

#typicalPortfolio, #typical_portfolio, #examplePortfolio

  &.opaque

    h2, .assessment-vis-default
      opacity: 0.2

