// Custom bootstrap column for 1/5th of width
.col-20, .col-sm-20, .col-md-20, .col-lg-20, .col-xl-20
  position: relative
  width: 100%
  padding-right: 10px
  padding-left: 10px

.flex-column-sm
  @include media-breakpoint-down(sm)
    flex-direction: column !important
    align-items: center !important

@include media-breakpoint-up(xs)
  .col-20
    flex: 0 0 20%
    max-width: 20%

@include media-breakpoint-up(sm)
  .col-sm-20
    flex: 0 0 20%
    max-width: 20%

@include media-breakpoint-up(md)
  .col-md-20
    flex: 0 0 20%
    max-width: 20%

@include media-breakpoint-up(lg)
  .col-lg-20
    flex: 0 0 20%
    max-width: 20%

@include media-breakpoint-up(xl)
  .col-xl-20
    flex: 0 0 20%
    max-width: 20%
