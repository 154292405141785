body[data-page="ReportsIndex"]

  .dropdown-item
    padding: 0.5rem 1rem !important

  #clientsFilterDropdown .dropdown-menu 

    input, .twitter-typeahead
      width: 100%
      border-radius: 0px !important
      border-left: 0px !important
      border-right: 0px !important
      height: 42px !important
