body[data-controller-path="public"]

  .text-warning.dark
    color: darken($warning, 15%) !important

  &:after
    display: none
    content: url(https://d1bgieexblpmvb.cloudfront.net/screenshots/advisors_landing/mountains5-xs.png)

  .navbar.nav-public:not(.nav-public-bordered)
    background-color: transparent !important

  .bg-image-top, .bg-image-prev
    position: fixed
    top: 0px
    left: 0px
    width: 100vw
    height: 100vh
    opacity: 0
    transition: 0.6s all ease

    img
      width: 100%
      object-fit: cover
      min-height: 100vh

    &:not(.opaque)
      opacity: 0.32

  .feature-image-top
    opacity: 0
    transition: 0.6s all ease

    &:not(.opaque)
      opacity: 1

  .large-icon-bg
    position: absolute
    color: $primary
    bottom: -100px
    right: -225px
    opacity: 0.2
    font-size: 62px

  // Icons for top section
  .how-ratings-work-icons
    position: absolute
    right: 0
    bottom: 0
    text-align: right
    white-space: nowrap

    .gender-equality-icons
      margin-left: auto
      display: flex !important
      @include media-breakpoint-down(md)
        max-width: 100vw
      @include media-breakpoint-between(lg, lg)
        max-width: 52vw
      @include media-breakpoint-up(xl)
        max-width: 450px
      i, svg
        margin-left: auto
        margin-right: auto

    .db-icon
      opacity: 0.6

  .how-works-title
    padding-bottom: 10px
    border-bottom: 4px solid $primary
    margin-bottom: 15px


  .customer-logos
    margin-top: 50px
    margin-bottom: 50px

  .carousel-control-prev, .carousel-control-next
    opacity: 1 !important
    color: $gray-700 !important

  .auto-slide
    height: 130px
    margin-left: auto
    margin-right: auto
    display: flex !important
    align-items: center !important
    justify-content: center !important
    max-width: 130px

    img
      width: 100%

  .pricing-top
    min-height: 90vh

  .bg-ed-special
    opacity: 0.08
    position: absolute
    top: 0
    width: 100%
    height: 100%
    object-fit: cover

  .pricing-top
    background-color: $primary-x-light

    @include media-breakpoint-down(sm)
      padding-top: 100px
    @include media-breakpoint-up(md)
      padding-top: 65px

  .comparison
    padding-top: 50px
    padding-bottom: 70px

  .pricing-table
    width: 100% !important
    thead
      th
        background-color: transparent !important
        border-bottom: 0px !important
        border-top: 0px !important 
    
    thead th
      font-weight: 600

    th, td
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      vertical-align: middle !important

      &:first-child
        width: 40%
      &:not(:first-child)
        text-align: center
        width: 20%
        padding-left: 10px
        padding-right: 10px

    tbody

      // Background change on hover by default
      tr.clickable:hover
        cursor: pointer

        td
          color: $primary

      td
        height: 55px
        padding-top: 0rem
        padding-bottom: 0rem
        border-top: 1px solid $gray-400

        .pricing-item
          display: flex !important
          align-items: center !important

          &:hover
            color: $primary

  #getStartedAdvisors
    background-color: darken($primary-x-light, 1%)
    position: relative
    @include media-breakpoint-down(sm)
      padding-top: 80px
      padding-bottom: 220px
    @include media-breakpoint-up(md)
      padding-top: 100px
      padding-bottom: 300px

  .public-section.cleared
    background-color: $primary-x-light

  .coin-conveyor

    @include media-breakpoint-up(xl)

      .conveyor-belt
        margin: auto -20px auto 340px

    @include media-breakpoint-between(md,lg)

      .conveyor-belt
        margin: auto -20px auto 290px !important

    @include media-breakpoint-up(lg)

      .coins-container
        margin: auto 0px auto 170px !important

      .coin:last-child
        margin-right: 360px !important

    @include media-breakpoint-down(md)

      .conveyor-belt
        margin: auto -20px auto 260px !important

      .coins-container
        margin: auto 0px auto 240px !important

      .coin:nth-child(2)
        margin: auto !important

      .coin:first-child
        display: none !important

      .coin:last-child
        margin-right: 160px !important


  .coin-conveyor, .piggy-banks
    background-color: transparent !important
    // background-color: $primary-x-light !important

  .public-section#howItWorks

    @include media-breakpoint-down(md)
      padding-top: 40px !important

    @include media-breakpoint-up(lg)
      padding-top: 50px !important

    padding-bottom: 70px !important
    background-color: $primary-x-light !important

    .carousel-indicators
      bottom: -30px !important
      margin-bottom: 0px

    .card.how-it-works-step
      border: 1px solid $gray-300

      .card-body
        border-top: 3px solid transparent
        display: flex !important
        flex-direction: column !important
        padding-top: 35px !important


  .public-section#pricingSection
    padding-top: 100px !important
    padding-bottom: 20px !important

  .public-section.section-smp
    padding-top: 150px !important
    padding-bottom: 100px !important

  .public-section#alignPortfolios
    padding-top: 40px !important
    padding-bottom: 260px !important


body[data-page="PublicLanding"]

  .assessment-results-visual
    height: 360px !important

  .main-top

    .hero-sub-statement
      @include media-breakpoint-down(xs)
        font-size: 4.3vw !important

    // Main search tabs at top of public page
    .top-search-section

      ul
        width: 100%

        li
          &:not(:first-child) a
            border-left: 0px !important

          a
            cursor: pointer
            font-weight: 300
            font-size: $h4-font-size
            border: none
            background-color: transparent !important
            color: $gray-700

            &.active
              font-weight: 600
              color: $primary
              background-color: transparent !important
              border-bottom: 4px solid $primary !important

            &:hover
              color: $primary

      .nav-link
        padding: 0.25rem 1rem

      .tab-content
        width: 100%

      .tab-pane

        input.typeahead
          background-color: $primary-x-light !important
          font-size: $h4-font-size
          font-weight: 300 !important
          height: 52px !important
          padding-top: 5px
          cursor: pointer
          color: $gray-700 !important

        .form-control::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $gray-600
          opacity: 1 /* Firefox */

        .form-control:-ms-input-placeholder /* Internet Explorer 10-11 */
          color: $gray-600

        .form-control::-ms-input-placeholder /* Microsoft Edge */
          color: $gray-600

        .tt-menu
          z-index: 50 !important // Below navbar
          width: 100%

        .tt-suggestion
          font-size: $h5-font-size !important

          &:first-child
            padding: 14px 0px 7px 10px !important

          &:not(:first-child)
            padding: 7px 0px 7px 10px !important


// Graphics backgrounds
.coin-conveyor, .piggy-banks
  background-color: darken($primary-x-light, 1%)

// Image max width/height
.screenshot-container, .screenshot-container a
  max-width: 600px !important

#assessPublicSection
  background-color: darken($primary-x-light, 1%)
  padding-top: 65px
  padding-bottom: 70px !important

#discoverPublicSection
  background-color: darken($primary-x-light, 1%)
  @include media-breakpoint-down(sm)
    padding-top: 40px
    padding-bottom: 60px
  @include media-breakpoint-up(md)
    padding-top: 55px
    padding-bottom: 60px

#alignPortfolioSection
  background-color: darken($primary-x-light, 1%)
  @include media-breakpoint-down(sm)
    padding-top: 80px
    padding-bottom: 220px
  @include media-breakpoint-up(md)
    padding-top: 100px
    padding-bottom: 280px
  position: relative

// Learn more
.learn-more-assessment
  position: absolute
  bottom: 15px
  display: flex !important
  align-items: center !important

  .hero-sub-statement
    white-space: nowrap

  @include media-breakpoint-down(sm)
    width: 95%
    text-align: center
    left: 2.5%
    right: 2.5%

  @include media-breakpoint-up(md)
    right: 15px

  button
    font-size: $h5-font-size
    width: 100%

