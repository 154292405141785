body[data-page="QuestionnairesResponsesShow"], body[data-page="QuestionnairesCollectionModulesIndex"]

  .respondent-access-container
    width: 100%
    overflow: auto
    font-size: $h6-font-size !important

    tr

      &:hover
        background-color: transparent !important

    th
      white-space: normal !important
      vertical-align: bottom !important

    th, td
      min-width: 70px !important
      padding-left: 10px 
      padding-right: 10px
      cursor: default !important

      &:first-child
        text-align: left !important

      &:not(:first-child)
        text-align: center !important

    td
      border: 1px solid $gray-400


  // Preview cards for drag/drop
  .questionnaire-options-card
    height: 120px !important
    border: 1px solid $gray-300

    &.completed

      .card-body
        background-color: lighten($a-rating, 40%)
        color: $gray-700 !important

      &:hover .card-body
        background-color: $a-rating !important
        color: $white !important

        i, svg
          color: $white !important

    &.started

      .card-body
        background-color: lighten($c-rating, 50%)
        color: $gray-700 !important

      &:hover .card-body
        background-color: $c-rating !important
        color: $white !important

        i, svg
          color: $white !important

    &.lightest

      .card-body
        background-color: lighten($f-rating, 55%)
        color: $gray-700 !important

      &:hover .card-body
        background-color: $f-rating !important
        color: $white !important

        i, svg
          color: $white !important

body[data-color-mode="black"]

  .questionnaire-options-card

    &.completed

      .card-body
        background-color: $black_success !important
        color: $white !important

      &:hover .card-body
        background-color: $black_success !important
        color: $white !important

    &.started

      .card-body
        background-color: lighten($black_warning, 40%) !important

      &:hover .card-body
        background-color: $black_warning !important
        color: $white !important

    &.lightest

      .card-body
        background-color: lighten($black_danger, 40%) !important

      &:hover .card-body
        background-color: $black_danger
        color: $white !important

