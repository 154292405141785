body[data-page="AssessmentsRankCategories"]

  .rank-cause-name
    white-space: nowrap
    @include media-breakpoint-down(sm)
      font-size: $h5-font-size
    @include media-breakpoint-up(md)
      font-size: $h4-font-size

  .drag-category
    cursor: move !important

  .available-category-space
    border: 1px dashed $gray-300
    background-color: $gray-100
    height: 60px
    margin-bottom: 1.5rem
    width: 100%