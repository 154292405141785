// Notifications table
// Used in notificaitons index and in notications mailers

.notifications-table
  width: 100%
  display: flex !important
  align-items: flex-start !important
  font-weight: 300

  tbody
    width: 100%

    tr

      &.unread td
        background-color: $primary-light

      &:not(:last-child) td
        border-bottom: 1px solid $gray-300

      td
        padding: 10px
        height: 62px !important
        border-top: 0px !important

        &:first-child
          width: 75px

          img
            max-width: 50px

        &:nth-child(2)
          text-align: left !important
          width: 100%

        &:last-child
          padding: 0px !important
          overflow: visible

          .dt-hover-outer
            position: relative !important

          .dropleft

            .dropdown-toggle
              cursor: pointer

              &::before
                display: none

            .dropdown-menu
              padding: 0px !important

              .dropdown-header
                padding: 10px
                font-weight: 400
                font-size: $h6-font-size
                background-color: darken($primary-x-light, 1%)
                border-bottom: 1px solid $gray-300

              .dropdown-item
                font-weight: 300
                font-size: $h6-font-size
                display: flex !important
                align-items: center !important
                min-width: 200px
                padding: 10px

                &:not(:last-child)
                  border-bottom: 1px solid $gray-300

