// Disabled inputs
.form-control:disabled
  background-color: $gray-100
  color: $gray-800 !important
  font-weight: 400 !important

label
  font-weight: 600

// Visibility toggle
.toggle-visibility
  position: absolute
  right: 5px
  top: 8px
  cursor: pointer  

// Remove an image
.remove-image
  position: absolute
  top: 10px
  right: 10px
  opacity: 0.5

  &:hover
    opacity: 0.9
