// Global carousels styling

.carousel.slide
  position: relative

  .suggested-title
    z-index: 1 // Must have z-index 1 to go above below containers

  .carousel-inner.suggestions-slice
    display: flex
    align-items: center
    overflow: visible

    .carousel-item
      overflow: visible


.suggestions-arrow
  opacity: 1 !important
  width: 30px !important
  height: 100%

  i, svg          
    font-size: $h2-font-size

  &.left
    justify-content: flex-start !important
    left: -12px

  &.right
    justify-content: flex-end !important
    right: -12px

