.coming-soon-note
  position: absolute
  height: 100%
  width: 100%
  text-align: center
  display: flex !important
  flex-direction: column !important
  align-items: center !important
  justify-content: center !important
  top: 0px


