/*=========================================
 * Stripe main subscribe feature
 *=======================================*/

// Pricing plan boxes (shared across multiple pages)
.pricing-plan
  text-align: center

  .card
    border-top: 3px solid $gray-200
    overflow: hidden
    background-color: lighten($primary, 35%)
    cursor: pointer

    &:not(.disabled):not(.no-click)
      cursor: pointer

    &.disabled
      opacity: 0.5

    &:hover, &.active
      box-shadow: 0 .25rem .75rem rgba($black, .1)
      border-top: 3px solid $primary

      i, svg
        opacity: 0.25

      .card-img-top
        background-color: lighten($primary, 5%)
        color: $white !important

        i, svg
          color: $white !important

    .card-img-top
      width: 100%
      object-fit: cover
      background-color: $primary-x-light
      overflow: hidden

      @include media-breakpoint-down(sm)
        max-height: 35vw
      @include media-breakpoint-up(md)
        height: 6vw

    .card-body
      padding-bottom: 0px !important

    h1
      line-height: 1 !important

    .fa-container
      height: 100%
      width: 100%
      i, svg
        font-size: 146px
        opacity: 0.55

    .card-footer
      background-color: $white
      border: 0px !important

    .plan-description
      height: 20px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      width: 95%


// Invoices notice about next invoice
body[data-page="SettingsAccountsStripeInvoicesBilling"]
  .swal2-content
    font-size: $h5-font-size !important
    font-weight: 300 !important  

// Root for subscription form
#subscriptionForm

  // Summary of selected plan
  .plan-summary
    // Position relative to anchor spinner position when user changes plan
    position: relative

    table
      tr
        cursor: default !important

        td
          padding-left: 0px !important
          font-weight: 300 !important
          font-size: $h5-font-size !important
          white-space: normal !important
          padding-bottom: 5px !important
          padding-top: 5px !important
          width: auto !important

          &:first-child
            padding-right: 20px

  // Payment details
  .payment-details

    // Default payment method
    .default-payment-method
      min-height: 35px

// Subscription form errors
#newSubscriptionCardErrors
  padding: 1rem
  color: $danger !important
  font-weight: 400 !important

// Subscription form and update card form
#subscriptionForm, #updateCardForm, #paymentModal

  .card-subscribe, input
    height: 40px
    padding: 10px 12px
    width: 100%
    color: #32325d
    background-color: white
    border: 1px solid transparent
    border-radius: 4px
    box-shadow: 0 1px 3px 0 #e6ebf1
    -webkit-transition: box-shadow 150ms ease
    transition: box-shadow 150ms ease
    font-size: 16px
    background-clip: padding-box

  .card-subscribe--focus
    box-shadow: 0 1px 3px 0 #cfd7df

  .card-subscribe--invalid
    border-color: #fa755a

  .card-subscribe--webkit-autofill
    background-color: #fefde5 !important

  // Card errors -- only when has 'with-error' class (added in JS)
  .card-errors.with-error
    width: 100%
    background-color: $danger
    color: $white
    padding: 5px
    border-radius: 5px
    margin-top: 5px 
    margin-bottom: 5px

  // Maintain height of button whether showing text or loading spinner
  .submit-payment
    height: 35px


