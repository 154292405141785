.large-center-logo
  padding: 25px 0px 45px 0px
  width: 100%
  height: 150px
  display: flex !important
  flex-direction: column !important
  justify-content: center !important
  align-items: center !important
  // background-color: $primary-x-light

  img
    max-height: 90px
    max-width: 250px


/* Shows a circle with user's first initial in case user does not have an image */
.image-container
  margin: 15px
  display: flex !important
  align-items: center !important
  justify-content: center !important
  padding: 10px
  background: $white
  border: 1px solid $gray-300
  position: relative
  height: 200px
  width: 200px
  cursor: pointer

  &:hover, &.active
    border: 1px solid $primary
    background: $primary-x-light


.company-logo-default, .fund-logo-default
  border-radius: 100%
  background: $gray-500
  vertical-align: middle
  color: $white
  width: 25px
  height: 25px
  display: inline-block
  text-align: center
  font: 1rem "Rubik", sans-serif
  padding: 6px 0px 0 1px

.xs-logo
  max-width: 50px
  max-height: 22px

  &.fit-content
    height: fit-content

.small-logo
  max-width: 75px
  max-height: 50px
  
  &.fit-content
    height: fit-content

.medium-logo
  max-width: 85px
  max-height: 75px

.large-logo
  max-width: 125px
  max-height: 125px  

.admin-image-container
  margin: 15px
  display: flex !important
  align-items: center !important
  justify-content: center !important
  padding: 10px
  background: $white
  border: 1px solid $gray-300
  position: relative
  height: 200px
  width: 200px
  cursor: pointer

  &:hover, &.active
    border: 1px solid $primary
    background: $primary-x-light

.admin-filepicker-container
  position: relative

  .admin-filepicker
    opacity: 0

  .admin-filepicker-label
    position: absolute
    top: -2px
    left: 5px
    height: 31px
    z-index: 1