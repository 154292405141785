// Small score badges for profiles
.score-badge-sm
  border-radius: 5px !important
  display: flex !important
  flex-direction: column !important
  align-items: center !important
  justify-content: center !important
  padding: 3px
  overflow: hidden
  border: 1px solid $gray-200
  box-shadow: 0 .05rem .25rem rgba($black, .1)
  width: 100%

  .title
    font-weight: 600
    line-height: 1.2
    padding: 5px 0px

    @include media-breakpoint-down(sm)
      font-size: 6.5vw
    @include media-breakpoint-between(sm, md)
      font-size: 5.5vw
    @include media-breakpoint-between(md, lg)
      font-size: 4.5vw
    @include media-breakpoint-up(lg)
      font-size: $h4-font-size

  .score
    font-weight: 600
    margin-bottom: 5px
    @include media-breakpoint-down(xs)
      font-size: 6.5vw
    @include media-breakpoint-between(sm, md)
      font-size: 5.5vw
    @include media-breakpoint-between(md, lg)
      font-size: 3.5vw
    @include media-breakpoint-up(lg)
      font-size: 2.5vw

  // Background icon
  .bg-icon
    opacity: 0.13
    position: absolute
    font-size: 76px !important

