body[data-page="PortfoliosControversiesIndex"]

  .dataTables_empty
    padding-top: 15px

  .holding-controversy-details

    .controversy-details
      display: none
      border-left: 1px solid $gray-300
      border-right: 1px solid $gray-300
      overflow: auto
      position: relative
      padding-bottom: 50px

    .controversy-title
      font-weight: 300

    &.expanded

      .controversy-details
        display: block !important
        border-bottom: 1px solid $gray-300

      .controversy-title
        font-weight: 400 !important
        border-left: 1px solid $gray-300
        border-right: 1px solid $gray-300
        padding-left: 5px
        padding-right: 5px

      .controversy-title
        border-bottom: 1px solid $gray-300

    &:not(:last-child) .controversy-title
      border-bottom: 1px solid $gray-300


  table
    tr.expanded

      .cell-text
        font-weight: 400 !important

    td
      height: auto !important
      min-height: 40px
      vertical-align: top !important

    td:nth-child(2), th:nth-child(2)
      text-align: left !important
      width: 100%
      min-width: 200px

  .controversies-info
    margin-top: 5px
    white-space: pre-wrap
    display: unset


  .dataTables_paginate
    float: none !important
    justify-content: flex-end !important
    width: 100%
    font-weight: 300 !important
    font-size: $h5-font-size !important

    &:not(.d-none)
      display: flex !important    

  .dataTables_length, .dataTables_filter, .dataTables_info
    display: inline-block
    margin-bottom: 10px
    font-weight: 300 !important
    font-size: $h5-font-size !important

  .dataTables_length

    label
      display: inline-flex !important
      align-items: center !important
      font-weight: 300 !important

    select
      margin-left: 6px
      margin-right: 6px
      padding: 0.25rem 1.75rem 0.25rem 0.5rem !important
      font-size: $h5-font-size !important
      font-weight: 300 !important

  .dataTables_filter
    float: right

    @include media-breakpoint-down(md)
      width: 100%

      label
        width: 100%

    input
      font-size: $h5-font-size !important
      font-weight: 300 !important
      
      @include media-breakpoint-down(md)
        width: 100% !important

      @include media-breakpoint-up(lg)
        min-width: 400px !important

