// Module menu
.module-menu
  margin: 0 0 -6px 0
  height: 55px
  width: 17px
  z-index: 101

  .btn
    transition: 0.3s all ease
    width: 17px
    height: 35px
    min-height: 35px
    max-height: 35px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    border-bottom: 1px solid transparent
    justify-content: center !important

    &:first-child
      border-radius: 0px 5px 0px 0px !important

    &:last-child
      border-radius: 0px 0px 5px 0px !important

    &:not(:first-child):not(:last-child)
      border-radius: 0px !important

    &:not(:last-child)
      border-bottom: 1px solid $white

    .text
      display: none

  &:hover

    .btn
      width: 250px
      justify-content: left !important
      
      .text
        display: block
        width: 245px
        text-align: left
        overflow: hidden
        text-overflow: ellipsis 

      .list
        display: none


