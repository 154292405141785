.city-env-scene
  width: 100%
  margin-bottom: 0px
  justify-content: center !important
  align-items: flex-end !important
  font-size: 1.5rem
  overflow-x: hidden
  
  position: absolute
  bottom: 0px
  height: 200px
  overflow: hidden !important

  i, svg
    position: absolute

  .ship-1
    bottom: 0px
    left: 30px

  .fa-trees, .fa-tree
    bottom: 0

  .trees-1
    left: 150px

  .trees-2
    left: 220px

  .trees-3
    right: 280px

  .trees-4
    right: 200px

  .trees-5
    right: 160px

  .trees-6
    right: 150px

  .elephant-1
    bottom: 0px
    left: 350px

  .city-container
    position: absolute
    left: 650px
    bottom: 0px

    i, svg
      bottom: 0px

    .building-1
      right: 70px

    .building-2
      left: -80px

    .building-3
      left: 0px

    .industry-1
      bottom: -6px
      left: 110px

  .wind-1
    bottom: 0px
    right: 70px

  .wind-2
    bottom: 0px
    right: 50px

  .wind-3
    bottom: 0px
    right: 20px