body[data-page="QuestionnairesIncludedQuestionsNew"], body[data-page="QuestionnairesIncludedQuestionsEdit"]

  .card-list.active
    background-color: $gray-100 !important

  .question-search-option:hover
    background-color: $gray-200 !important

  .tt-menu
    max-height: 350px !important



