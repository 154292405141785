/*=========================================
 * Companies banner
 *=======================================*/

.banner-buildings
  bottom: 0
  width: 100%
  margin: auto
  z-index: 48
  display: flex !important
  justify-content: center !important
  overflow: hidden

  .buildings-container
    display: flex !important
    align-items: center !important
    justify-content: center !important

    span
      display: flex !important
      align-items: center !important
      justify-content: center !important

      &:first-child
        margin: 0px -15px 0px -6px

      &:nth-child(2)
        margin: 0px -20px 10px -10px

      &:nth-child(3)
        margin: 0px -11px -15px -17px

      &:nth-child(4)
        margin: 0px -10px 25px -10px

      &:nth-child(5)
        margin: 0px -10px

      &:nth-child(6)
        margin: 0px 0px -10px -5px

      &:nth-child(7)
        margin: 0px -5px -10px -5px

      &:nth-child(8)
        margin: 0px -5px -10px -5px

      &:last-child
        margin: 0px -5px -7px -5px

      i, svg
        color: $primary


