.progress 
  margin: 0.2em 0 0.2em 0
  line-height: inherit
  background-color: $gray-100

.progress .bar
  padding: 0.3em
  color: white
  display: none
  font-size: $h5-font-size
  font-weight: 300
  border-radius: 5px


/*=========================================
 * Spinner to show while div content loads
 *=======================================*/
/* https://getbootstrap.com/docs/4.2/components/spinners/ */

body[data-color-mode="black"]
  .progress-bar
    background-color: $cobalt
    color: $black

body[data-color-mode="aca"]
  .progress-bar
    background-color: $aca_blue_light

body[data-color-mode="legacy"], body[data-color-mode="high_contrast"]
  .progress-bar
    background-color: $primary_light


.turbo-progress-bar
  background-color: $primary !important

.progress.controversies-progress, .progress.characteristics-progress
  border-radius: 0px !important
  margin: 0 !important

.spinner-container
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.spinner-border
  margin: auto

.small-spinner-container
  position: relative
  width: 30px
  min-width: 30px // Must have min-width as well as width, or two-line text next to spinner pushes into the 30px

  .spinner-border
    width: 20px !important
    height: 20px !important
    color: darken($primary-light, 3%)

.xs-spinner-container
  position: relative
  width: 30px
  min-width: 30px // Must have min-width as well as width, or two-line text next to spinner pushes into the 30px

  .spinner-border
    width: 15px !important
    height: 15px !important