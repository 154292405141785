// Account guide pages
body[data-page="GuidesShow"]

  .guide-wrapper .city-env-scene
    margin-bottom: 52px !important

.guide-wrapper
  height: 92vh

  span.unchecked
    padding: 12px
    border-radius: 15px
    display: block

  .guide-content span.unchecked
    border: 2px solid $primary

  .guide-sidebar
    height: 100vh
    border-right: 1px solid $gray-300
    z-index: 51
    font-size: $h5-font-size
    border-top-left-radius: 5px

    span.unchecked
      border: 1px solid $primary

    .guide-sidebar-step
      padding: 1rem
      display: flex !important
      align-items: center !important 
      width: 100%

      &.active, &:hover
        font-weight: 600

      &:not(.active)
        font-weight: 400

      &:not(:last-child)
        border-bottom: 1px solid $gray-300

  .guide-content
    height: 85vh
    display: flex !important
    flex-direction: column !important
    padding-left: 15px

    .guide-logo
      padding: 10px 0px 5px 0px
      width: 100%
      height: 85px
      display: flex !important
      flex-direction: column !important
      justify-content: center !important
      align-items: center !important

      img
        max-height: 70px
        max-width: 170px

    .picker
      height: 31.5px
      width: 50px
      border-top-left-radius: 0px !important
      border-bottom-left-radius: 0px !important
      border: 1px solid $gray-300

    .guide-scrollable
      height: calc(100vh - 130px)
      overflow: auto

  .modal-footer
    width: 100%
    font-size: $h5-font-size
    font-weight: 300
    height: 55px
    position: absolute
    bottom: 0px
    left: 0px
    z-index: 51

    &.guide-actions
      padding: 0px 15px !important

    &:not(.guide-actions)
      padding: 15px !important

  .input-group-prepend, .input-group-append
    height: 31.5px

// Picker buttons
.pcr-save
  background-color: $primary !important

.pcr-type:not(.active)
  border: 1px solid transparent
.pcr-type.active
  background-color: #f1f3f4 !important
  color: $gray-700 !important
  border: 1px solid $gray-300
