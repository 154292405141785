/*=========================================
 * Buttons
 *=======================================*/
body[data-color-mode="black"]

  .btn-primary
    background-color: $black
    border-radius: 15px
    border-color: $black !important

    &:hover
      background-color: $taupe_50 !important
      color: $white !important

    &.active
      background-color: $white !important
      color: $black !important

  .btn-primary-light
    background-color: $taupe_50 !important
    color: $white !important

    &:hover
      background-color: $black !important
      color: $white !important

  .btn-primary-mid
    background-color: darken($taupe_20, 4%)

    &:hover
      background-color: $taupe_50 !important
      color: $white !important

  .btn-white
    background: $white !important
    color: $black !important

    &:hover
      background: $black !important
      color: $white !important

body[data-color-mode="aca"]

  .btn-primary
    background-color: $aca_blue_medium

  .btn-primary-light
    background-color: $aca_blue_light

  .btn-primary-mid
    background-color: darken($aca_blue_light, 4%)

body[data-color-mode="guide"]

  .btn-primary
    background-color: $guide_green !important

  .btn-primary-light
    background-color: $guide_green_light !important

    &.disabled:hover
      background-color: $guide_green_light !important
      color: inherit !important

    &:not(.disabled):hover
      background-color: $guide_green !important
      color: $white !important

  .btn-primary-mid
    background-color: darken($guide_green_light, 4%)

  .btn-gray
    background-color: $gray-300 !important
    color: $gray-700 !important

    &:hover
      background-color: $gray-700 !important
      color: $white !important

.btn-primary-mid
  background-color: darken($primary_light, 4%)
  color: $gray-800

// Update light button from bootstrap default
.btn

  // No wrap text by default
  white-space: nowrap

  &.btn-rounded-more
    border-radius: 15px !important

  &.btn-half-circle
    display: inline-block
    margin: 0 -6px 0 0
    height: 90px
    width: 45px
    border-bottom-left-radius: 90px
    border-top-left-radius: 90px

  &.subscribe-button
    background-color: lighten($warning, 35%)
    color: inherit

    &:hover
      background-color: lighten($warning, 15%)

  &.btn-facebook
    background-color: #1877f2
    color: white

  &.btn-google
    background-color: $gray-200
    color: inherit

  &.btn-microsoft
    background-color: $gray-200
    color: inherit

  &.btn-okta
    background-color: $gray-200
    color: inherit

  &.disabled
    cursor: default !important

  &.btn-primary-dark
    background-color: darken($primary, 6%)
    color: $white !important
    border: 0px
    &:hover
      background: darken($primary, 8%) !important

  &.btn-white
    background: $white !important
    border: 1px solid $gray-200
    color: $gray-700 !important
    &:hover
      background: $primary-light !important
      color: inherit !important

  &.btn-dark
    background: $gray-700 !important
    color: $white !important
    &:hover
      background: $gray-800 !important

  &.btn-light
    background: $gray-100 !important
    color: $gray-700 !important
    border: 0px
    &:hover
      background: $gray-200 !important
      color: $gray-800 !important

  // Change btn-secondary to primary light
  &.btn-secondary, &.btn-default, &.btn-primary-light
    background: $primary-light !important
    color: inherit !important
    border: 0px
    &:hover
      background: darken($primary-light, 3%) !important

  // Warning buttons -- need to add !important flags to override Swal buttons
  &.btn-warning-light
    color: #212529
    background-color: lighten($warning, 15%)

    &.disabled
      color: inherit

  &.btn-warning
    color: #212529 !important
    background-color: $warning

  &.btn-warning:hover
    color: #212529
    background-color: darken($warning, 10%)
    border-color: #ffab01

  &.btn-danger
    color: $white !important

  &.clients-dropdown-btn::after
    display: none !important
