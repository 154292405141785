.coin-conveyor
  width: 100%
  margin-bottom: 0px
  justify-content: center !important
  align-items: flex-end !important
  font-size: 1.5rem
  overflow-x: hidden
  position: relative
  height: 200px

  // Funnel showing coins coming out
  .funnel-container
    @include media-breakpoint-down(sm)
      display: none
    @include media-breakpoint-up(md)
      display: flex !important
    position: absolute
    top: 0
    right: 0
    margin-right: -40px
    z-index: 2
    color: darken($primary-light, 3%)

  // Coins
  .coins-container
    position: absolute
    top: 55px
    width: 100%
    margin: auto
    @include media-breakpoint-down(sm)
      padding: 0px
    @include media-breakpoint-between(sm, md)
      padding: 0px 70px 0px 0px
    @include media-breakpoint-up(lg)
      padding: 0px 50px 0px 0px

  .conveyor-belt
    position: absolute
    bottom: 88px !important
    @include media-breakpoint-down(md)
      margin: auto -20px auto 25px
    @include media-breakpoint-up(lg)
      margin: auto -20px auto 40px