body[data-page="PortfoliosModelsBulkImportsMap"]

  table.table
    td:first-child, th:first-child
      width: 150px !important

body[data-page="PortfoliosModelsImportsMap"], body[data-page="PortfoliosModelsBulkImportsMap"]

  .modal-body
    height: 70vh
    overflow: auto

  table.table

    &.opaque
      opacity: 0.2

    // Limit width for long fund names
    .matching-info .logo-name-name div
      white-space: normal !important

    tr:hover
      background-color: inherit !important

    th
      vertical-align: bottom !important

    td, th
      text-align: left !important
      white-space: pre-wrap !important

    td
      overflow: visible !important

      .twitter-typeahead
        flex: none !important
        width: 300px !important

        .form-control
          cursor: pointer !important
          width: 300px !important

        .tt-suggestion:not(:last-child), .tt-dataset:not(:last-child)
          border-bottom: 1px dashed $gray-300

