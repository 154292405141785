// Tabs used on multiple pages
body[data-color-mode="black"]

  #profileNavTabs, .profile-nav-tabs

    li.nav-item

      a.nav-link.active:not(.vantage)
        border-bottom: 4px solid $black

      a.nav-link.active.vantage
        border-bottom: 4px solid $black

    li.nav-item.manager-item
      background-color: $taupe_20
      color: $black
      border-top-left-radius: 5px
      border-top-right-radius: 5px
      border-color: $black_gray2
      
body[data-color-mode="aca"]

  #profileNavTabs, .profile-nav-tabs

    li.nav-item

      a.nav-link.active:not(.vantage)
        border-bottom: 4px solid $aca_blue

      a.nav-link.active.vantage
        border-bottom: 4px solid $aca_blue_dark

    li.nav-item.manager-item
      background-color: lighten($aca_blue_light, 2%)
      border-top-left-radius: 5px
      border-top-right-radius: 5px
      border-color: $black_gray2
      
body[data-color-mode="guide"]

  #profileNavTabs, .profile-nav-tabs

    li.nav-item

      a.nav-link.active:not(.vantage)
        border-bottom: 4px solid $guide_green

      a.nav-link.active.vantage
        border-bottom: 4px solid $guide_green


#profileNavTabs, .profile-nav-tabs
  flex-wrap: nowrap !important

  li.nav-item
    margin-bottom: 0px !important
    margin-right: 5px

    &.manager-item
      background-color: lighten($primary-light, 2%)
      border-top-left-radius: 5px
      border-top-right-radius: 5px

      a
        white-space: nowrap
    
    a.nav-link
      background-color: transparent !important
      color: inherit
      border: 0px
      font-size: $h5-font-size

      @include media-breakpoint-down(sm)
        padding: 0.5rem 0.85rem !important

      &:not(.active)
        border-bottom: 4px solid transparent
        font-weight: 300

        &:hover
          color: $primary !important

      &.active
        font-weight: 400
        border-bottom: 4px solid $primary

      &.active.text-warning-dark
        border-bottom: 4px solid darken($c-rating, 5%)


.text-option-tabs
  flex-wrap: nowrap !important

  li.nav-item
    margin-bottom: 0px !important

    @include media-breakpoint-up(md)
      padding-left: 5px
      padding-right: 5px

    a.nav-link
      background-color: transparent !important
      color: inherit
      border: 0px

      @include media-breakpoint-down(sm)
        padding: 0.5rem 0.85rem !important

      &:not(.active)
        font-weight: 300

        &:hover
          color: $primary !important

      &.active
        font-weight: 400

