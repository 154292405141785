.form-footer
  position: fixed
  bottom: 0px
  left: 0px
  height: 50px
  border-top: 1px solid $gray-300
  z-index: 52
  display: flex !important
  align-items: center !important
  padding: 1rem

  &.sidebar-offset.minimized
    width: 100%

  &.sidebar-offset:not(.minimized)

    @include media-breakpoint-down(sm)
      width: calc(100% - 70px)

    @include media-breakpoint-up(lg)
      width: calc(100% - 250px)

