body[data-page="ClientsAssessmentsSetCauses"]

  // Sections 
  .section-option
    margin-bottom: 8px    

    &.minimized, .card.minimized
      max-height: 48px !important

    &.excluded
      opacity: 0.6

      .card-header
        background-color: $primary-x-light !important

      input
        background-color: $gray-100

      .category-name
        font-weight: 400

    .category-name
      font-weight: 600

    .card-header
      padding-top: 5px !important
      padding-bottom: 5px !important
      height: 48px
      min-height: 48px

    select
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      cursor: pointer !important

  .drag-section
    cursor: move !important

  .available-section-space
    border: 1px dashed $gray-300
    background-color: $gray-100
    height: 60px
    margin-bottom: 1.5rem