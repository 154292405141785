// Sorting arrows
table.dataTable 
  thead 

    th.sorting
      .fa-sort-up
        --fa-primary-opacity: 0.15
        --fa-secondary-opacity: 0.15

    th.sorting_asc, th.sorting_asc_disabled

      .fa-sort-up
        --fa-primary-opacity: 0.8
        --fa-secondary-opacity: 0.15

      // Reverse class for when using a hidden field to sort 
      // E.g., rank to sort ratings
      .fa-sort-up.reverse
        --fa-primary-opacity: 0.15 !important
        --fa-secondary-opacity: 0.8 !important

    th.sorting_desc, th.sorting_asc_disabled

      .fa-sort-up
        --fa-primary-opacity: 0.15
        --fa-secondary-opacity: 0.8

      // Reverse class for when using a hidden field to sort 
      // E.g., rank to sort ratings
      .fa-sort-up.reverse
        --fa-primary-opacity: 0.8 !important
        --fa-secondary-opacity: 0.15 !important

