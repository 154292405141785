.generating-message
  width: 100%
  height: 200px
  top: 0
  display: flex !important
  flex-direction: column !important
  align-items: center !important
  justify-content: center !important
  font-size: $h4-font-size
  font-weight: 400
