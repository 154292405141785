/*=========================================
 * Loading function for buttons
 *=======================================*/
// We use a JS spinner for page and container loading
// This is for when a user clicks on a submit button

.button-loading
  display: inline-block
  width: 17px
  height: 17px
  border: 3px solid rgba(255, 255, 255, 0.3)
  border-radius: 50%
  border-top-color: #fff
  animation: spin 1s ease-in-out infinite
  -webkit-animation: spin 1s ease-in-out infinite

@keyframes spin
  to
    -webkit-transform: rotate(360deg)

@-webkit-keyframes spin
  to
    -webkit-transform: rotate(360deg)