/* Color palette used in app */

/*=========================================
 * Main theme
 *=======================================*/
/* Must be on one line in SASS file */

$theme-colors: ("primary": #8c81d1, "primary-medium": #aea6df, "primary-light": #efedff, "primary-x-light": #fafaff, "primary-alt": #efedff, "success": #36bea6, "info": #009efb, "warning": #ffbc34, "danger": #e871ae)

$primary: theme-color("primary")
$primary-medium: theme-color("primary-medium")
$primary_dark: darken(#8c81d1, 10%)
$primary-light: theme-color("primary-light")
$primary-x-light: theme-color("primary-x-light")
$primary-alt: theme-color("primary-alt")
$success: theme-color("success")
$info: theme-color("info")
$warning: theme-color("warning")
$danger: theme-color("danger")
$aca_blue: #00A9EB
$aca_blue_medium: #006CA9
$aca_blue_dark: #00325D
$aca_blue_light: #ebf9ff
$aca_blue_x_light: #f5fcff
$aca_gray: #53585f
$ethos_purple: theme-color("primary")
$guide_green: #28a745
$guide_green_light: #a3daaf
$guide_green_lightest: lighten(#a3daaf, 23%)

/*=========================================
 * Black colors
 *=======================================*/
$cobalt: #006492
$cobalt_10: #CCE0E9
$cobalt_50: #006492
$taupe_50: #c39d78
$taupe_20: lighten(#c39d78, 20%)
$black_gray1: #F4F5F7
$black_gray2: #DFE2E7
$black_gray3: #D4D8DF
$black_a_rating: #00B050 // Green
$black_b_rating: #C6EFCE // Lighter green
$black_c_rating: #FFFF8F // Yellow
$black_d_rating: #FFD487 // Light orange
$black_f_rating: #FFC7CE // Red
$black_success: #1B5E5C // Green
$black_success_shade: #AFBFBE // Lighter green
$black_warning: #F1C21B // Yellow
$black_danger_shade: #A4BFBE // Light orange
$black_danger: #C12D27 // Red

/*=========================================
 * Grays
 *=======================================*/

$white:    #fff !default
$gray-100: #f8f9fa !default
$gray-200: #e9ecef !default
$gray-300: #dee2e6 !default
$gray-400: #ced4da !default
$gray-500: #adb5bd !default
$gray-600: #6c757d !default
$gray-700: #495057 !default
$gray-800: #343a40 !default
$gray-900: #212529 !default
$black:    #000 !default


/*=========================================
 * Impact::Rating colors
 *=======================================*/

/* Main colors */
$a-rating: #28a745 // Green
$b-rating: #79d979 // Lighter green
$c-rating: #b8b514 // Yellow
$d-rating: #c2820c // Light orange
$f-rating: #c2510c // Red
$no-rating: $gray-500 // Gray-500

/* Opaque versions */
$a-rating-opaque: #a3daaf
$b-rating-opaque: #daf4da
$c-rating-opaque: #e9e9bd
$d-rating-opaque: #edd9ba
$f-rating-opaque: #edcbb9
$no-rating-opaque: $gray-200

/* Dark versions */
$a-rating-dark: darken($a-rating, 15%)
$b-rating-dark: darken($b-rating, 10%)
$c-rating-dark: darken($c-rating, 10%)
$d-rating-dark: darken($d-rating, 10%)
$f-rating-dark: darken($f-rating, 10%)
$no-rating-dark: darken($no-rating, 10%)


/*=========================================
 * Causes colors
 *=======================================*/
$faith-based-values-bg: #C3E5ED // Blue-green
$faith-based-values-color: darken(#C3E5ED, 35%)

$climate-bg: #bfe5c7 // Green
$climate-color: darken(#bfe5c7, 35%)

$life-bg: #dbe4be // Green-yellow
$life-color: darken(#dbe4be, 45%)

$peace-bg: #D5E174 // Dark-yellow
$peace-color: darken(#D5E174, 35%)

$water-bg: #B6D0F2 // Blue
$water-color: darken(#B6D0F2, 35%)

$health-bg: #bef4f4 // Light red
$health-color: darken(#bef4f4, 35%)

$economy-bg: #f2d4b5 // Orange 
$economy-color: darken(#f2d4b5, 35%)

$education-bg: #ffe6b3 // Yellow orange
$education-color: darken(#ffe6b3, 35%)

$gender-bg: #d1d1e0 // Light red
$gender-color: darken(#d1d1e0, 35%)

$innovation-bg: #ffc6b3 // Light red
$innovation-color: darken(#ffc6b3, 30%)

$sustain-bg: #d1e0e0 // Blue
$sustain-color: darken(#d1e0e0, 35%)

