/*=========================================
 * Navbar public xs (all sizes and up)
 *=======================================*/

.navbar.nav-public
  height: $public-navbar-height
  z-index: 52 // Make sure stays above fa-icons
  border-bottom: transparent
  background-color: $primary-x-light
  flex-direction: column !important

  &:not(.nav-public-bordered) 
    height: 80px

    &.alert-present
      height: 102px !important

    @include media-breakpoint-down(md)
      .navbar-toggler
        z-index: 51

      .navbar-collapse
        top: 0px !important

    @include media-breakpoint-up(lg)
      .navbar-collapse
        height: 60px

  &.nav-public-bordered
    height: 60px
    border-bottom: 1px solid $gray-300
    background-color: $primary-light

    .navbar-collapse
      background-color: $primary-light

    .navbar-brand img
      width: 152px

  a.nav-link
    text-transform: uppercase

  .nav-item.active .nav-link
    color: $primary !important

  .navbar-brand .navbar-brand-picture
    transition: 0.3s all ease

  @include media-breakpoint-up(lg)

    .top-nav-dropdown .dropdown-menu .dropdown-items .dropdown-item
      min-height: 40px !important

    // Make logo aligned with rest of navbar
    .navbar-brand img
      width: 216px

    .navbar-nav .nav-item

      // Make first-level nav links 100% line height of the navbar
      .nav-link
        line-height: 49px
        padding-top: 0px
        padding-bottom: 0px
        font-weight: 600

      ul
        a
          text-transform: none !important

  @include media-breakpoint-down(md)

    .navbar-collapse
      top: $public-navbar-height
      left: 0
      width: 100%
      position: fixed
      border-bottom: 1px solid $gray-400
      background-color: $primary-x-light
      margin: 0 auto

      @include media-breakpoint-down(sm)
        padding: 0 20px

      @include media-breakpoint-up(md)
        padding: 0 30px


      &.show
        display: block

      // Padding on top
      .navbar-nav.first-navbar .nav-item:first-child
        margin-top: 10px
