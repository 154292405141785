body[data-page="PublicImpactRatings"]

  .source-logos
    opacity: 0.8

    a img
      margin: 17px


  // Materiality table
  .main-content-column

    @include media-breakpoint-up(xl)
      padding-left: 20px

    #materialityCard
      font-size: 12px !important
      font-weight: 400 !important
      transition: none !important

      &:not(.full-view)
        height: 522px

        div.material-table-container
          max-height: 454px

      &.full-view

        div.material-table-container
          max-height: calc(100vh - 70px)

      div.material-table-container
        max-width: 100%
        overflow: scroll
        position: relative
        transition: none !important

      table
        position: relative
        border-collapse: separate
        border-spacing: 0px
        border-color: $gray-300

      td, th
        padding: 0px

        &.sector-cell
          width: 80px !important
          min-width: 80px !important
          max-width: 80px !important
          text-align: left !important

        &.industry-cell
          width: 175px !important
          min-width: 175px !important
          max-width: 175px !important
          text-align: left !important
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

      thead 

        th
          position: -webkit-sticky
          background-color: lighten($primary-light, 2%)
          font-weight: 600 !important

          /* for Safari */
          position: sticky

          i, svg
            font-size: $h5-font-size !important

          &.sector-cell, &.industry-cell
            text-align: left !important
            z-index: 2

          &.sector-cell
            padding: 0px 10px

          &.industry-cell
            padding: 0px 5px

          &:not(.sector-cell):not(.industry-cell)
            z-index: 1

          &:first-child
            left: 0

          &:nth-child(2)
            left: 80px

        tr:first-child th
          top: 0
          height: 32px !important
          text-align: center !important

          &:not(.industry-cell):not(.sector-cell)
            border-left: 1px solid $gray-300

        tr:nth-child(2) th
          top: 32px
          border-bottom: 1px solid $gray-300
          width: 60px !important
          max-width: 60px !important
          min-width: 60px !important

          &:not(.industry-cell):not(.sector-cell)
            text-align: center !important
            padding: 5px 0px !important

      tbody 

        th
          position: -webkit-sticky
          background-color: lighten($primary-light, 2%)
          z-index: 1

          /* for Safari */
          position: sticky
          border-right: 1px solid $gray-300
          height: 20px !important

          &.sector-cell
            left: 0
            padding: 0px 10px
            text-align: left
            font-weight: 600 !important

            &:not(:last-child)
              border-bottom: 1px solid $gray-300

          &.industry-cell
            left: 80px
            padding: 5px
            text-align: left
            border-bottom: 1px solid $gray-300
            font-weight: 400 !important

        td
          border-bottom: 1px solid $gray-300
          border-right: 1px solid $gray-300

          &.factor-a
            background-color: lighten($f-rating, 53%)

          &.factor-b
            background-color: lighten($f-rating, 42%)

          &.factor-c
            background-color: lighten($f-rating, 30%)

          &.factor-d
            background-color: lighten($f-rating, 15%)

          &.factor-f
            background-color: lighten($f-rating, 5%)


  .pricing-table
    width: 100% !important
    thead
      th
        background-color: transparent !important
        border-bottom: 0px !important
        border-top: 0px !important 
    
    thead th
      font-weight: 600

    th, td
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      vertical-align: middle !important
      width: 25%

      &:first-child
        min-width: 150px
        padding: 10px 0px
      &:not(:first-child)
        text-align: center
        padding: 10px

    tbody

      td
        height: 55px
        border-top: 1px solid $gray-400

        .pricing-item
          display: flex !important
          align-items: center !important

          &:hover
            color: $primary

body[data-page="PublicImpactCalculator"]

  #impactCalculatorDetailsTable

    td, th
      text-align: left !important

      &:hover
        cursor: default !important

    td
      vertical-align: top !important
      white-space: normal !important
      padding-top: 15px !important
      padding-bottom: 15px !important

    td:first-child, th:first-child
      width: 150px !important
      min-width: 150px !important
      padding-right: 15px !important

    td:last-child, th:last-child
      width: 100% !important



