// Opacity for backdrop
.modal-backdrop.show
  opacity: 0.6 !important
  z-index: 1041 !important // More than top navbar

.modal.show
  opacity: 1
  transform: none

  &.opaque
    opacity: 0.2

body[data-color-mode="black"]

  .modal-header, .modal-footer
    background-color: $black_gray1 !important
    color: $black

body[data-color-mode="aca"]

  .modal-header, .modal-footer
    background-color: $aca_blue_light !important

.modal

  .modal-header-actions

    .btn-group .btn
      padding-top: 6px
      padding-bottom: 6px

    svg, i
      font-size: 18px !important

  // Remove border from modal content otherwise doubles with border in header and body, 
  // causing issues when colors are different
  .modal-content
    border: 0px
    overflow: visible // Show typeahead forms outside of modal

    &.max-height
      max-height: 90vh

      .modal-body
        max-height: 70vh
        overflow: auto

    .modal-body
      padding-bottom: 50px

  .modal-xl
    max-width: none !important

    @include media-breakpoint-down(md)
      width: 98vw

    @include media-breakpoint-up(lg)
      width: 92vw

  .modal-header
    background-color: $primary-light
    border-bottom: 1px solid $gray-500

  .modal-footer
    border-top: 1px solid $gray-500
    background-color: $white
    width: 100% 

    &.modal-footer-absolute
      min-height: 58px
      position: absolute
      bottom: 0px
      left: 0px


  // Dashed rows
  .form-group.dashed-bottom:not(:last-child)
    margin-left: -16px
    margin-right: -16px
    padding-left: 16px
    padding-right: 16px
    border-bottom: 1px dashed $gray-300

  .success-notice
    padding: 1rem
    background-color: $primary-light
    border-radius: 5px
    width: 100%
    font-size: $h5-font-size
    font-weight: 300
    margin-bottom: 1rem
    display: flex !important

