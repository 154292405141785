// Icons for causes
.cause-icon
  height: 85px
  width: 75px
  border-radius: 5px !important
  cursor: pointer
  display: flex !important
  flex-direction: column !important
  align-items: center !important
  justify-content: center !important
  padding: 5px
  overflow: hidden
  border: 1px solid $gray-200
  box-shadow: 0 .05rem .25rem rgba($black, .1)

  @include media-breakpoint-down(xs)
    font-size: 7.5vw
  @include media-breakpoint-between(sm, md)
    font-size: 6.5vw
  @include media-breakpoint-between(md, lg)
    font-size: 4.5vw
  @include media-breakpoint-up(lg)
    font-size: 3.5vw

  .cause-title
    font-size: $h3-font-size
    font-weight: 600

    @include media-breakpoint-down(sm)
      padding: 1rem 0px 10px 0px

    @include media-breakpoint-up(md)
      padding: 1rem

  // Background icon
  .bg-icon
    opacity: 0.13
    position: absolute
    font-size: 96px !important

  &.sm
    height: 50px
    width: 45px
    font-size: 30px !important
    .bg-icon
      font-size: 76px !important

  &.xs
    height: 35px
    width: 35px
    font-size: 20px !important
    .bg-icon
      font-size: 66px !important

  &.xxs
    height: 22px
    width: 25px
    font-size: 14px !important

  &.opaque

    &.active, &:hover
      opacity: 1 !important

    &:not(.active)
      opacity: 0.3

  &.disabled
    opacity: 0.3

  &.bg-cause-default
    background-color: $primary-light
    color: $primary
