body[data-page="AccountsImpactCalculatorsShow"]

  #my_causesTab .include-options.excluded

    .result-container, .impact-persona
      opacity: 0.3


  #featured_metricsTab, #sdgsTab, #screensTab

    // Preview cards for drag/drop
    .calc-options-card
      height: 60px !important

    .available-section-space
      border: 1px dashed $gray-300
      background-color: $gray-100
      height: 60px
      margin-bottom: 1.5rem
      width: 100%
      position: relative
      padding-left: 10px
      padding-right: 10px

      @media (max-width: 991.99px)
        flex: 0 0 50%
        max-width: 50%

      @media (min-width: 992px)
        flex: 0 0 33.33333%
        max-width: 33.33333%

      @media (min-width: 1200px)
        flex: 0 0 25%
        max-width: 25%

  #previewTab

    .available-section-space
      border: 1px dashed $gray-300
      background-color: $gray-100
      height: 50px
      margin-bottom: 1.5rem

    .calc-sidebar-section
      transition: 0.3s all ease
      margin-bottom: 15px
      padding-bottom: 15px
      border-bottom: 1px solid transparent

      &.minimized
        height: 40px
        overflow: hidden
        border-bottom: 1px solid $gray-300

        h6, ul
          display: none !important

    .input-group .btn-input-opt
      flex: 1 1 auto
      width: 1%
      min-width: 0
      margin-bottom: 0
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      color: $gray-700 !important
      border: 1px solid $gray-200 !important
      background-color: #fff !important

  .include-options.excluded .card
    opacity: 0.4

    .card-body
      opacity: 0.5
      background-color: $primary-x-light !important
      color: $black !important

      .included-indicator
        display: none !important

  .card.excluded
    opacity: 0.6

    .card-body
      background-color: $primary-x-light !important
      color: $black !important

      i, svg
        color: $black !important

  .calculator-card
    position: relative
    margin-bottom: 0.5rem

    .account-logo
      width: 100%
      padding-top: 10px
      text-align: center
      
  .card.embed-code
    background-color: $gray-800
    color: lighten($gray-100, 5%)

  .calculator-cols
    
    @include media-breakpoint-up(md)
      .col-12:first-child
        border-right: 1px dashed $gray-300

    .input-group-text
      width: 135px
      padding-left: 10px
      padding-right: 10px

    .display-options
      transition: 0.3s all ease
      width: 525px
      min-width: 325px

      &.minimized
        margin-left: -525px !important
        opacity: 0

    .toggle-options-button
      border-left: 1px dashed $gray-300

    .toggle-display-options
      border-radius: 25px !important
      padding: 0px !important
      width: 35px
      height: 35px
      display: flex !important
      align-items: center !important
      justify-content: center !important

    #calculatorPreview
      overflow: auto

  .twitter-typeahead
    flex: 1 1 auto
    width: 1%
    min-width: 0
    margin-bottom: 0
