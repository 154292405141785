/*=========================================
 * Search input
 *=======================================*/
// Search input in navbars
// Can either be fixed width (default below) or full (100%) width 
// If full-width, starts as hidden on page, toggled visibility in js

.search-form
  z-index: 110
  margin: 0
  width: 100%

  .input-group

    .input-group-text
      padding: 5px 0.5rem 5px 0.75rem
      background-color: $white !important

    .input-group-append .input-group-text
      border-left: 0px

  input
    padding: 15px 0px 16px 0px !important
    border-left: 0px
    border-right: 0px
    border-top-left-radius: 0px !important
    border-bottom-left-radius: 0px !important

  .close-search-input
    position: absolute
    cursor: pointer
    right: 2.5rem


  &:not(.full-width-form)

    @include media-breakpoint-up(md)  
      min-width: 325px
    @include media-breakpoint-up(lg)
      min-width: 375px
    @include media-breakpoint-up(xl)
      min-width: 450px

    input
      max-width: 100%
      min-width: 200px

  &.full-width-form
    display: none
    position: absolute
    top: 0
    left: 0
    
    .input-group
      height: 50px !important // App navbar height

    // Make input form and twitter typeaheads expand on click
    // Flex behavior follows bootstrap form-control style
    .twitter-typeahead
      position: relative
      -webkit-box-flex: 1
      flex: 1 1 auto
      width: 1%
      margin-bottom: 0

      .tt-menu
        width: 100vw !important

    input
      height: 50px !important // App navbar height
      width: 100%

    .close-search-input
      top: 15px
