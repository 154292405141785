.accounts-banner-container

  &:not(.firm-banner-container)
    margin-top: $app-navbar-height

  .cover-container
    margin: 0 auto
    overflow: hidden
    height: 200px
    position: relative
    width: 100%

    img
      min-height: 100%
      min-width: 100%
      opacity: 0.8

      &.ui-draggable:not(.ui-draggable-disabled)
        cursor: move !important

    .controls, .controls-save, .accounts-banner-container img
      position: absolute

    .controls
      top: 15px
      right: 15px

    .controls-save
      bottom: 15px
      right: 15px

    .controls, .controls-save
      a
        opacity: 0.2
        display: inline-block

    &:hover

      .controls a, .controls-save a
        opacity: 0.8
        color: $gray-700 !important


  .banner-logo-container
    height: 0px
    position: absolute
    width: 100%
    margin: 0 auto
    z-index: 50
    bottom: 0

    .banner-logo-name
      height: 90%
      overflow: hidden
      display: flex !important
      align-items: center
      justify-content: center
      @include media-breakpoint-down(xs)
        font-size: 3.5vw
      @include media-breakpoint-between(sm, md)  
        font-size: 3vw
      @include media-breakpoint-between(md, lg)  
        font-size: 2vw
      @include media-breakpoint-up(lg)
        font-size: 1.5vw

    .fix-width
      height: 0px

    .logo-container
      background-color: $white
      bottom: 0
      width: min-content
      max-width: 250px
      min-width: 120px
      height: 120px
      margin-top: -87px
      position: relative
      overflow: hidden
      padding: 5px

      i.fa-pencil, svg.fa-pencil
        position: absolute
        top: 5px
        right: 5px
        opacity: 0.1

      &:hover 
        i.fa-pencil, svg.fa-pencil
          opacity: 0.8
