/*==============================================================
 * Background display helpers
 *==============================================================*/

.shadow
  box-shadow: 0 .05rem .25rem rgba($black, .1)

body[data-color-mode="black"]

  .bg-primary-dark
    background-color: $black !important

  .bg-primary
    background-color: $black !important

  .bg-primary-medium
    background-color: $cobalt !important

  .bg-primary-shade
    background-color: $taupe_50 !important
    color: $white !important

    &:hover
      background-color: $black !important
      color: $white !important

  .bg-primary-light
    background-color: $black_gray1 !important
    color: $black !important

  .bg-primary-lighter
    background-color: $black_gray1
    color: $black !important

  .bg-primary-x-light
    background-color: lighten($black_gray1, 1%) !important
    color: $black !important


body[data-color-mode="aca"]

  .bg-primary-dark
    background-color: $aca_blue_dark !important

  .bg-primary
    background-color: $aca_blue !important

  .bg-primary-medium
    background-color: $aca_blue_medium !important

  .bg-primary-shade
    background-color: $aca_blue_light !important

  .bg-primary-light
    background-color: $aca_blue_light !important

  .bg-primary-lighter
    background-color: lighten($aca_blue_light, 1%) !important

  .bg-primary-x-light
    background-color: $aca_blue_x_light !important


body[data-color-mode="guide"]

  .bg-primary-dark
    background-color: $guide_green !important

  .bg-primary
    background-color: $guide_green !important

  .bg-primary-light
    background-color: $guide_green_light !important

  .bg-primary-shade
    background-color: $guide_green_light !important

  .bg-primary-lighter
    background-color: lighten($guide_green_light, 1%) !important

  .bg-primary-lightest
    background-color: $guide_green_lightest !important

  .bg-dark
    background-color: $gray-700 !important
    color: $white !important


.bg-primary, .bg-primary-dark, .bg-primary-medium
  color: $white !important
  a
    color: $white !important

.bg-primary-dark
  background-color: $primary_dark !important

.bg-primary
  background-color: $primary !important
  color: $white !important
  a
    color: $white !important

.bg-primary-shade
  background-color: $primary-light !important
  color: inherit !important

.bg-primary-light
  background-color: $primary-light !important
  color: inherit !important

.bg-primary-lighter
  background-color: lighten($primary-light, 1%) !important
  color: inherit !important

.bg-primary-x-light
  background-color: $primary-x-light !important
  color: inherit !important

.bg-white
  background-color: $white

.bg-gray100
  background-color: $gray-100 !important

.bg-gray100-odd:nth-child(odd)
  background-color: $gray-100 !important

.bg-gray100-even:nth-child(even)
  background-color: $gray-100 !important

.bg-gray200
  background-color: $gray-200 !important

.bg-gray300
  background-color: $gray-300 !important

.bg-gray400
  background-color: $gray-400 !important

.bg-gray600
  background-color: $gray-600 !important
  color: $white !important

.bg-gray700
  background-color: $gray-700 !important
  color: $white !important

.bg-success
  background-color: $success !important

  &.opaque
    background-color: lighten($success, 30%) !important

.bg-info
  background-color: $info !important

  &.opaque
    background-color: lighten($info, 49%) !important

.bg-warning
  background-color: $warning !important

  &.opaque
    background-color: lighten($warning, 25%) !important

.bg-danger
  background-color: $danger !important

  &.opaque
    background-color: lighten($danger, 25%) !important

.bg-faith-based-values
  background-color: $faith-based-values-bg !important
  color: $faith-based-values-color

  &.opaque
    background-color: lighten($faith-based-values-bg, 10%) !important

.bg-climate-action
  background-color: $climate-bg !important
  color: $climate-color

  &.opaque
    background-color: lighten($climate-bg, 10%) !important

.bg-inclusive-economies
  background-color: $economy-bg !important
  color: $economy-color

  &.opaque
    background-color: lighten($economy-bg, 10%) !important

.bg-life-on-earth
  background-color: $life-bg !important
  color: $life-color

  &.opaque
    background-color: lighten($life-bg, 10%) !important

.bg-peace-and-justice
  background-color: $peace-bg !important
  color: $peace-color

  &.opaque
    background-color: lighten($peace-bg, 10%) !important

.bg-quality-education
  background-color: $education-bg !important
  color: $education-color

  &.opaque
    background-color: lighten($education-bg, 10%) !important

.bg-innovation
  background-color: $innovation-bg !important
  color: $innovation-color

  &.opaque
    background-color: lighten($innovation-bg, 10%) !important

.bg-gender-equality
  background-color: $gender-bg !important
  color: $gender-color

  &.opaque
    background-color: lighten($gender-bg, 10%) !important

.bg-sustainable-resource-use
  background-color: $sustain-bg !important
  color: $sustain-color

  &.opaque
    background-color: lighten($sustain-bg, 10%) !important

.bg-water-and-sanitation
  background-color: $water-bg !important
  color: $water-color

  &.opaque
    background-color: lighten($water-bg, 10%) !important

.bg-health-and-well-being
  background-color: $health-bg !important
  color: $health-color

  &.opaque
    background-color: lighten($health-bg, 10%) !important

