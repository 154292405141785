body
  .sidebar-app
    background-color: $primary-light

    .card:not(.minimized), .card-body
      input, a, .gray-link, .sidebar-link
        height: 36px !important

.sidebar-column
  position: relative
  max-width: 270px !important
  min-width: 270px !important

.sidebar-app
  position: fixed
  top: 0
  left: 0
  height: 100vh
  transition: 0.3s all ease
  z-index: 51
  font-size: $h5-font-size
  padding-top: $app-navbar-height
  box-shadow: $shadow
  border-right: 1px solid $gray-300
  overflow: hidden

  .sidebar-menu-container
    height: calc(100vh - 100px)
    overflow: auto

  &.minimized
    width: 0px

  &:not(.minimized)

    @include media-breakpoint-down(sm)
      width: 70px

    @include media-breakpoint-up(md)
      width: 250px

  .card-header
    padding: 10px 15px

  .card, .card-header
    border-radius: 0px !important
    box-shadow: none !important
    border-right: 0px
    border-top: 0px !important
    // white-space: nowrap

    &.minimized
      overflow: hidden !important

      .card-header
        border-bottom: 0px !important

      .card-body
        padding: 0px !important
        height: 0px !important
        min-height: 0px !important

    .card-header h5
      @include media-breakpoint-down(sm)
        justify-content: center !important

    &:not(.minimized), .card-body
      font-weight: 300 !important

      .position-relative:first-child
        border-top: 1px solid $gray-300

      .position-relative:not(:last-child)
        border-bottom: 1px solid $gray-400

      input, a, .gray-link, .sidebar-link
        border-radius: 0px
        border: 0px
        background-color: transparent

        @include media-breakpoint-down(sm)
          justify-content: center !important

        @include media-breakpoint-up(md)
          padding-left: 15px !important

      a
        color: inherit

        &:hover
          color: $primary !important
