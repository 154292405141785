// Recaptcha
.g-recaptcha 

  div
    width: 100% !important
    display: flex !important
    align-items: center !important
    justify-content: center !important

.iti--allow-dropdown
  width: 100% !important

.phone-number

  .phone-error-message, .field_with_errors .message
    float: left
    height: 0px
    margin-bottom: 0px !important
    margin-top: 0px !important


.signup-form, form#new_user

  input
    font-size: $h5-font-size !important

    &::-webkit-input-placeholder
      color: darken($gray-600, 9%) !important

  input:not(.btn)
    font-weight: 300 !important

  .btn
    font-size: $h5-font-size !important

.social-buttons
  font-size: $h5-font-size !important


.auth-side
  height: 100vh
  width: 100%
  position: relative
  background: $primary-light
  display: flex !important
  flex-direction: column !important
  margin-bottom: 0px

  .icon-row

    i, svg
      opacity: 0.15

    &.opaque i, &.opaque svg
      opacity: 0.05

    @include media-breakpoint-down(md)

      .auth-icon:nth-child(4)
        display: none !important

      .auth-icon:nth-child(5)
        display: none !important

      .auth-icon:last-child
        display: none !important


.password-help, .phone-help, .initial-sign-up-help
  position: absolute
  height: 100vh
  top: 0

  ul
    margin-left: -10px

    li:not(:last-child)
      margin-bottom: 10px

// Sign up form
.signup-form, .phone-number-form

  .phone-error-message
    float: right
    height: 0px
    margin-bottom: 0px

  // No margin top for agreed to terms error message
  label[for="signup_form_agreed_to_terms"]
    margin-top: 0px !important

  .two-fa-phone-group

    .assets-lead-message p:last-child
      margin-bottom: 5px

    .phone-number

      .phone-error-message, .field_with_errors .message
        float: left
        height: 0px
        margin-bottom: 0px !important
        margin-top: 0px !important

  // Phone input group
  .phone-input-group

    // Fix for country code / phone number positioning
    .iti
      position: absolute

    // Phone input
    .input-group.phone-number

      // Prepend + sign
      .input-group-prepend
        position: absolute
        left: 46px
        z-index: 1

        .input-group-text
          border: 0px !important
          padding-left: 5px
          padding-right: 5px
          background: transparent !important
          height: calc(1.25em + 0.75rem + 2px)
          padding-top: 8px !important

      // Country code
      #country_code
        position: relative
        left: 54px
        width: 100px
        z-index: 1
        border: 0px !important
        padding-left: 5px 
        padding-right: 5px
        min-width: 25px
        max-width: 40px
        background: transparent

      // Phone number
      #phone_number
        padding-left: 70px

        &.pl-l
          padding-left: 78px

        &.pl-xl
          padding-left: 86px

      // Fix error message for phone numbers
      label[for="phone_number"]
        float: right
        margin-top: 0.25rem

      .iti--allow-dropdown

        // Flags for country select dropdown
        .iti__flag
          background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/img/flags.png")

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
          .iti__flag
            background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/img/flags@2x.png")

        // Keep height of selected flag static -- necessary with error validations
        .iti__selected-flag
          height: 31.5px !important

        // Change color of highlighted rows
        .iti__country:hover, .iti__country.iti__active
          background-color: $primary-light !important

  // Sign up form checkbox -- adjust to align with adjacent text
  #agreed_to_terms
    margin-top: 3px
