body[data-color-mode="aca"]

  .card-list-container-wide
    .card-footer
      .action
        background-color: $aca_blue_x_light

        &:hover
          background-color: $aca_blue


body[data-color-mode="guide"]

  .card-list-container-wide
    .card-footer
      .action
        background-color: $guide_green_light

        &:hover
          background-color: $guide_green

// Set impact profiles container with position relative 
// for spinner placement on loading
.card-list-container-wide
  position: relative

  // Custom cards for profiles and net impact index pages */
  .list-card
    
    &:not(.sm)
      min-height: 170px

    &.narrow-card
      max-height: 200px

    &.sm
      height: 110px

    .card-body      

      &.clickable
        color: $gray-700 !important

        &:hover
          background-color: $gray-100
          color: $gray-700 !important

      .text-with-max-height
        max-height: 60px
        position: relative
        overflow: hidden
        padding-bottom: 10px

        &:before
          content: ''
          width: 100%
          height: 100%    
          position: absolute
          left: 0
          top: 0
          pointer-events: none

          @include media-breakpoint-up(md)
            background: linear-gradient(transparent 40px, white)

      .profile-card-rating, .badge-narrow
        overflow: hidden !important
        wrap: nowrap
        text-align: center !important
        @include media-breakpoint-up(md)
          width: 150px
          margin-left: auto

      .profile-card-rating
        @include media-breakpoint-down(sm)
          font-size: 1.75rem
        @include media-breakpoint-up(md)
          font-size: 2rem
        padding: 0.5rem

      .mission-statement-container
        height: 22px
        position: relative
        width: 90%
        overflow: hidden

        .mission-statement
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

      .record-name
        margin-bottom: 3px

        @include media-breakpoint-down(sm)
          font-size: $h4-font-size
        @include media-breakpoint-between(sm, md)
          font-size: $h3-font-size
        @include media-breakpoint-up(lg)
          font-size: $h2-font-size

    .card-footer

      .action
        background-color: $primary-light
        padding: 0.625rem

        &:first-child
          border-right: 1px solid $gray-300

        &:hover
          background-color: $primary
          color: $white
