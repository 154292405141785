.tiers-badge
  padding: 2px 4px
  text-align: center
  white-space: nowrap
  border-radius: 0.25rem
  line-height: 1.1

  &.sidebar-badge
    font-size: 10px
    margin-top: -7px
    margin-left: 3px
    font-weight: 400 !important

