.bar-container
  border: 1px dashed $gray-300

  @include media-breakpoint-down(sm)
    width: 50%

  .comparison-chart-bar
    opacity: 0.25

  .carbon-metric-score
    font-size: 70%
    overflow: visible
    white-space: nowrap

  .comparison-info-tooltip
    border: 1px solid $gray-300
    position: absolute
    z-index: 101
    top: 0px

    tbody td
      padding: 5px !important
      vertical-align: top
      white-space: normal !important
      background-color: $white
      &:first-child
        min-width: 200px
        max-width: 200px
      &:last-child
        min-width: 150px
        max-width: 150px

    &.offset-left
      left: -300px
    &:not(.offset-left)
      left: 0px

