body[data-page="ReportsShow"]

  tr.featured-benchmarking-row
    border: 2px dashed $black

  .rendering-container
    min-height: 100px

  .policy-assessment-list .table

    .help-circle
      margin-left: 3px !important

    tr

      &:first-child td
        border-top: 0px !important

      td
        height: 20px !important
        padding-top: 7px
        padding-bottom: 7px

        &:first-child
          width: 40px !important
          padding-left: 5px

        &:not(:last-child)
          text-align: left !important

        &:nth-child(2) div
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap

        &:last-child
          padding-right: 8px
          div
            // width: 75px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

  .dashboard-item-card

    .card-body
      overflow: auto

    .dashboard-link
      overflow: hidden
      height: 60px

    .dashboard-item-name
      height: 45px
      line-height: 1
      overflow: hidden
      width: 175px
      min-width: 175px


  #companyScorecardList, #moduleScorecardList

    .card:not(:first-child)
      border-top-left-radius: 0px !important
      border-top-right-radius: 0px !important

    .card:not(:last-child)
      border-bottom-left-radius: 0px !important
      border-bottom-right-radius: 0px !important

    .card-body
      transition: 0.3s all ease

      &.minimized
        height: 0px
        max-height: 0px
        min-height: 0px !important
        overflow: hidden

    .recommendations-card-body .mb-2:last-child ul
      margin-bottom: 0px !important

  .table.summary-scorecard

    &.company-scorecard

      th, td
        &:first-child
          width: 200px
          min-width: 200px

        &.small-cell
          width: 120px
          min-width: 120px
          text-align: center !important

        &.medium-cell
          width: 160px
          min-width: 160px
          padding-left: 15px

        &.large-cell
          width: 100%
          min-width: 300px
          padding-left: 15px
          
      th
        &.large-cell
          vertical-align: bottom !important

      td
        &.large-cell, &.medium-cell
          vertical-align: top !important

        &:first-child
          vertical-align: top !important


    &:not(.company-scorecard)

      th, td
        &:first-child
          width: 250px
          min-width: 250px

        &:nth-child(2)
          width: 100px
          min-width: 100px

        &:nth-child(3)
          width: 100px
          min-width: 100px

    th
      vertical-align: bottom !important

    th, td
      text-align: left !important
      white-space: normal

      &:not(:last-child)
        padding-right: 10px !important
        padding-left: 10px !important

    td
      padding-top: 5px
      padding-bottom: 5px

  .table.questionnaire-summary-scorecard

    th
      vertical-align: bottom !important

    th, td
      text-align: left !important
      white-space: normal

      &:not(:last-child)
        padding-right: 10px !important

      &:first-child
        width: 250px
        min-width: 250px

      &:nth-child(2), &:nth-child(3), &:nth-child(4)
        width: 100px
        min-width: 100px

  #controversiesDetailsList

    .controversy-row
      padding: 4px 10px !important

    #controversiesList .btn-group .btn.active
      background-color: $primary-light !important


  #fundWeightTabs
    flex-wrap: nowrap !important

    li.nav-item
      margin-bottom: 0px !important

      @include media-breakpoint-up(md)
        padding-left: 5px
        padding-right: 5px

      a.nav-link
        background-color: transparent !important
        color: $gray-700
        border: 0px
        font-size: $h5-font-size

        @include media-breakpoint-down(sm)
          padding: 0.5rem 0.85rem !important

        &:not(.active)
          border-bottom: 4px solid transparent
          font-weight: 300

          &:hover
            color: $primary !important

        &.active
          font-weight: 400
          border-bottom: 4px solid $primary

  .assets, .all-metrics

    .chart-container

      .chart
        height: 135px

  span.checkbox
    width: 20px
    height: 20px
    border: 1px solid $gray-400
    border-radius: 3px

    &:hover
      -webkit-box-shadow: 0px 2px 3px $gray-500
      -moz-box-shadow: 0px 2px 3px $gray-500
      box-shadow: 0px 2px 3px $gray-500

  .advocacy

    .advocacy-title
      @include media-breakpoint-up(xl)
        white-space: nowrap
        overflow: visible

  .card-footer.table-info-footer
    background-color: darken($primary-x-light, 1%)
    bottom: 0px
    height: 50px

  .controversy-row
    padding: 4px 10px !important

  .sdg-contribution-row
    padding: 8px !important

  .sdg-revenue-row
    padding: 0px 6px !important

    .progress
      border-radius: 0px !important

.banner-container.account-reports-banner

  .cover-container
    margin: 0 auto
    overflow: hidden
    height: 400px
    position: relative
    width: 100%

    img.repositionable
      min-height: 100%
      min-width: 100%
      opacity: 0.25

      &.ui-draggable
        cursor: move !important

    .controls, .controls-save, .accounts-banner-container img
      position: absolute

    .controls
      top: 15px
      right: 15px

    .controls-save
      bottom: 15px

      // Adjust right position for social media icons on md and above
      @include media-breakpoint-down(sm)
        right: 15px

      @include media-breakpoint-up(md)
        right: 60px

    .controls, .controls-save
      a
        opacity: 0.2
        display: inline-block

    &:hover

      .controls a, .controls-save a
        opacity: 0.8
        color: $gray-700 !important


  .report-banner-logo-container
    position: absolute
    top: 0
    display: flex !important
    flex-direction: column !important
    align-items: center !important
    justify-content: center !important
    width: 100%
    height: 100%

  .report-title-container
    position: absolute
    top: 0
    display: flex !important
    flex-direction: column !important
    align-items: center !important
    justify-content: center !important
    width: 100%
    height: 100%

    .report-title
      color: $primary
      font-weight: 600
      line-height: 1.1 !important
      @include media-breakpoint-down(sm)
        font-size: 2rem
      @include media-breakpoint-up(md)
        font-size: 3rem

    .report-subtitle
      padding-top: 5px
      justify-content: center !important
      line-height: 1.15
      @include media-breakpoint-down(sm)
        font-size: 1.25rem
      @include media-breakpoint-up(md)
        font-size: 1.5rem
      @include media-breakpoint-up(lg)
        max-width: 80%



// Report profile sections (used on assessment show pages as well as reports)
.report-profile-section
  padding: 15px 15px 0px 15px
  margin-bottom: 1.5rem
  border: 1px solid $gray-200
  background-color: darken($primary-x-light, 1.1%)
  border-radius: 5px

  .cause-image-container
    width: calc(100% + 30px)
    height: 150px
    overflow: hidden
    border-top-left-radius: 5px
    border-top-right-radius: 5px
    margin: -15px -15px 0px -15px
    cursor: pointer

    img
      position: absolute
      width: 100%
      opacity: 0.1
      height: 100%
      object-fit: cover

// Expandable content
.expand-parent

  .expandable-content-title
    @include media-breakpoint-down(xs)
      font-size: 5vw
    @include media-breakpoint-up(sm)
      font-size: $h1-font-size

  .expand-content
    cursor: pointer

  .expandable-content      
    padding-top: 15px

    &:not(.expanded)
      display: none

// Cause keywords card
#causeKeywords
  .badge
    border: 1px solid $gray-200

// Metrics section in impact assessment
#myMetrics.metric-examples, .my-metrics.metric-examples

  .card:not(:first-child)
    border-top-right-radius: 0px
    border-top-left-radius: 0px

  .card:not(:last-child)
    border-bottom-right-radius: 0px
    border-bottom-left-radius: 0px
    border-bottom: 0px


.my-portfolio p:last-child
  margin-bottom: 0px !important


