#carbon_underground_chart
  min-height: 170px

  .carbon-underground-container
    height: 170px
    display: flex !important
    justify-content: center !important
    position: relative !important
    width: 100%
    flex-direction: column !important
    overflow: hidden

    .fa-cu-container
      width: 100%
      height: 100%
      top: 0
      left: 0
      position: absolute
      opacity: 0.07
      display: flex !important
      align-items: center !important
      justify-content: center !important

      i, svg
        font-size: 226px

    .cu-measurement.progress
      display: block
      position: relative
      overflow: visible
      background-color: $gray-200
      margin-bottom: 20px
      z-index: 52

    .stem.progress-bar
      height: 70px

    .cu-fa-container
      position: absolute
      left: 0px
      top: 0px
      width: 100%
      height: 100%
      display: flex !important
      align-items: center !important
      justify-content: center !important
      font-size: 174px
      opacity: 0.03
      color: $gray-700

    .marker
      height: 70px
      position: absolute
      top: 0px
      display: flex !important
      align-items: flex-end !important
      background-color: $white
      width: 3px

      .marker-label
        margin-bottom: -22px
        margin-left: -16px

    .cu-chart-footer
      z-index: 53
