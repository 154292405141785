.piggy-banks
  width: 100%
  margin-bottom: 0px
  justify-content: center !important
  align-items: flex-end !important
  font-size: 1.5rem
  overflow-x: hidden
  position: relative
  height: 100px

  .conveyor-belt
    margin: auto auto 0px auto

  // Piggy banks
  .piggy-bank-container
    position: absolute
    bottom: 17px
    width: 100%

    &.first
      margin: auto 20px auto -50px

    &.second
      margin: auto -50px auto 20px    

    .piggy-bank
      margin: auto auto 0px auto
      z-index: 51

      &.first
        font-size: 60px

      &.second
        font-size: 54px

      &.third
        font-size: 44px
        margin-left: -15px

      &.fourth
        font-size: 66px

      &.sixth
        font-size: 74px
        margin-left: -5px