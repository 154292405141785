// Theme customization related to sidebars

body[data-color-mode="black"]

  .sidebar  

    @include media-breakpoint-up(lg)
      background-color: $white
      color: $black !important

      a
        color: $black !important

  .sidebar-content

    ul

      li:hover
        background-color: darken($cobalt_10, 1%) !important

        a
          color: $black !important

  .sidebar-app
    background-color: $black_gray1
    color: $black

    .card, .card-header, .card-footer
      border-left: 0px !important
      background-color: $black_gray1 !important
      color: $black !important

      .card-body
        background-color: $white
        color: $black

    .card

      &:not(.minimized), .card-body
        a
          color: inherit

          &:hover
            color: $taupe_50 !important

body[data-color-mode="aca"]

  .sidebar  

    @include media-breakpoint-up(lg)
      background-color: $white

  .sidebar-content

    ul

      li:hover
        background-color: darken($aca_blue_x_light, 1%) !important

  .sidebar-app
    background-color: lighten($aca_blue, 50%)

    .collapse-item.clickable:hover
      color: $aca_blue !important

    .card

      &:not(.minimized), .card-body
        a
          color: inherit

          &:hover
            color: $cobalt !important


body[data-color-mode="guide"]

  .sidebar  

    @include media-breakpoint-up(lg)
      background-color: $white

  .sidebar-content

    ul

      li:hover
        background-color: darken($guide_green_light, 1%) !important

  .sidebar-app
    background-color: lighten($guide_green, 50%)

    .card:not(.minimized), .card-body
      input, a, .gray-link, .sidebar-link
        min-height: 42px !important
        padding-top: 5px
        padding-bottom: 5px


body[data-color-mode="legacy"]
  .sidebar-app
    .card:not(.minimized), .card-body
      input, a, .gray-link, .sidebar-link
        background-color: $primary-x-light !important


body[data-color-mode="high_contrast"]
  .sidebar-app
    background-color: lighten($primary-light, 1%)



