body[data-page="SettingsAccountsRolesEdit"], body[data-page="SettingsAccountsRolesNew"]

  .drag-module
    cursor: move !important

  .role-dashboard-name
    height: 28.5px

  #dashboardInclusionsRolesEditTable

    td
      padding-top: 0px !important
      padding-bottom: 0px !important
      vertical-align: middle !important

    th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3)
      min-width: 250px !important
      width: 35%
      text-align: left !important

    th:nth-child(4), td:nth-child(4)
      min-width: 150px !important
      width: 15%
      text-align: left !important

    th:nth-child(5), td:nth-child(5), th:last-child, td:last-child
      min-width: 100px !important
      width: 7.5%
      text-align: center !important


