/*=========================================
 * Global tooltip tables
 *=======================================*/

/* C3 chart tooltips are shown on hover over chart data */

.c3-tooltip-container
  max-width: 400px

.c3-tooltip

  tr

    th
      font-weight: 600 !important
      color: $gray-700

    td
      border-left: 0px !important

    td, th
      padding: 2px 8px !important
      font-size: $font-size-base !important

.google-visualization-tooltip
  min-width: 400px
