.characteristics-chart

  .fund-stories-tabs
    font-size: $h6-font-size

  .characteristics-tabs:not(.fund-stories-tabs)
    font-size: $h5-font-size

  .characteristics-tabs
    font-weight: 300

    li.nav-item
      width: 100%

      .nav-link
        border-style: solid
        border-color: $gray-300
        border-radius: 0px

        &.active
          font-weight: 400 !important

      &:first-child .nav-link
        border-width: 0px 1px 1px 0px
        border-top-left-radius: 0.25rem

      &:not(:first-child) .nav-link
        border-width: 1px 1px 1px 0px

      &:last-child .nav-link
        border-bottom-left-radius: 0.25rem

  .tab-content
    height: 450px

.characteristic-row
  display: flex !important
  align-items: center !important
  padding: 10px
  z-index: 0

  &:not(:last-child)
    border-bottom: 1px dashed $gray-400

  .progress
    background: transparent !important
    max-width: none !important
    z-index: -1


.characteristic-row-comparison
  display: flex !important
  align-items: center !important
  padding: 5px 10px
  z-index: 0

  &:first-child
    border-bottom: 1px dashed $gray-300

  &:not(:first-child)
    border-bottom: 1px solid $gray-300

  .progress
    background: transparent !important
    max-width: none !important
    z-index: -1


