@import 'colors'
@import 'bootstrap-config'

/* Configuration variables for app styles */

/*=========================================
 * Headers
 *=======================================*/

$public-navbar-height: 50px
$app-navbar-height: 70px

/*=========================================
 * Sidebar
 *=======================================*/

$sidebar-width: 300px
$sidebar-border: $gray-100
$sidebar-icons: $primary
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08)
$shadow-dark: 1px 0px 20px rgba(0, 0, 0, 0.2)

/*==============================================================
 * Body scaffolding
 *==============================================================*/

body
  
  .page-wrapper
    background: $body-bg
    position: relative
    transition: 0.2s ease-in

    &:not(.app-wrapper)
      min-height: calc(100vh - 70px) // Make sure footer always at bottom of page

    &.app-wrapper
      min-height: calc(100vh - 70px) // Min height less top two banners, so footer always below initial view
      margin-top: $app-navbar-height

    &.admin-wrapper
      min-height: calc(100vh - 70px) // Min height less top two banners, so footer always below initial view
      margin-top: 100px

    &.personal-wrapper
      min-height: 100vh // Min height less top two banners, so footer always below initial view

  .fix-width
    max-width: 1270px
    width: 100%
    margin: 0 auto

    &.narrower
      max-width: 1170px !important

    &.wider
      max-width: none !important

    @include media-breakpoint-down(sm)
      padding: 0 20px
      .container-fluid
        padding-left: 0px !important
        padding-right: 0px !important

    @include media-breakpoint-up(md)
      padding: 0 30px

  input
    &::-webkit-input-placeholder
      color: $gray-600 !important

  input:disabled
    &::-webkit-input-placeholder
      color: $gray-700 !important

/*==============================================================
 * Body high-contrast scaffolding
 *==============================================================*/

body[data-color-mode="high_contrast"]
  color: #000
  background: $white

  .page-wrapper
    background: $white

  input
    &::-webkit-input-placeholder
      color: $gray-600 !important


/*==============================================================
 * Body ACA scaffolding
 *==============================================================*/

body[data-color-mode="aca"]
  color: #000
  background: $white

  a:not(.btn):not(.gray-link)
    color: $aca_blue_dark

  .page-wrapper
    background: $white

  input
    &::-webkit-input-placeholder
      color: $gray-600 !important


/*==============================================================
 * Body black scaffolding
 *==============================================================*/

body[data-color-mode="black"]
  background: $white
  color: $black
  font-family: $font-family-guardian-sans !important

  a:not(.btn):not(.gray-link):not(.card)
    color: $taupe_50

  .page-wrapper
    background: $white
    color: $black


/*==============================================================
 * Body Guide scaffolding
 *==============================================================*/

body[data-color-mode="guide"]
  background: $white
  color: #000

  a:not(.btn):not(.gray-link)
    color: $gray-700

  .page-wrapper
    background: $white

  input
    &::-webkit-input-placeholder
      color: $gray-600 !important

