// Metrics infographics
.metrics-infographic-col
  padding: 0px !important

  .thermometer-container
    position: absolute !important
    top: 0px
    left: 0px
    height: 100%
    width: 100%
    opacity: 0.35

    .thermometer.progress
      height: 50% !important

    .stem
      height: 100% !important

    .bulb
      top: 0px !important

  .metrics-infographic-score
    z-index: 51

    .score
      white-space: nowrap

  .metrics-infographic-card
    min-height: 200px
    border: 1px solid $gray-300
    background: transparent !important
    box-shadow: none !important
    border-radius: 0px !important

    .info-abs
      display: flex !important
      flex-wrap: wrap !important
      overflow: hidden

    .card-body
      overflow: hidden !important
      height: 200px !important

    .metrics-infographic-footer
      background: transparent !important
      height: 60px
      border-top: 0px !important


