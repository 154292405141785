.toast
  position: fixed
  width: 400px
  z-index: 1051 !important // One more than modals doesn't show on dashboard otherwise
  right: 15px
  top: 15px

body[data-color-mode="guide"]

  .toast-guide
    position: fixed
    top: unset !important
    right: unset !important
    flex-basis: $toast-max-width
    font-size: $h6-font-size
    color: $toast-color
    background-color: $toast-background-color
    background-clip: padding-box
    border: $toast-border-width solid $toast-border-color
    box-shadow: $toast-box-shadow
    opacity: 0
    border-radius: 3
    z-index: 1051 !important // One more than modals doesn't show on dashboard otherwise

    &:not(.minimized):not(.maximized)
      width: 450px
      max-width: 450px !important
      left: 15px !important
      bottom: 15px !important

      .toast-guide-body
        padding: $toast-padding-x

      .toast-guide-footer
        padding: 0.5rem

    &.minimized:not(.maximized)
      width: 400px
      max-width: 400px !important
      left: 0px !important
      bottom: 0px !important

      .toast-guide-header
        height: 35px
        min-height: 35px

      .toast-guide-body, .toast-guide-footer
        padding: 0px
        height: 0px
        overflow: hidden

    &.maximized
      width: calc(100vw - 30px)
      height: calc(100vh - 30px)
      left: 15px !important
      bottom: 15px !important
      display: flex !important
      flex-direction: column !important

      .toast-guide-body
        padding: $toast-padding-x

      .toast-guide-footer
        padding: 0.5rem
        margin-top: auto


    &:not(:last-child)
      margin-bottom: $toast-padding-x

    &.showing
      opacity: 1

    &.show 
      display: block
      opacity: 1

    &.hide
      display: none

    .toast-guide-header 
      display: flex
      align-items: center
      padding: $toast-padding-y $toast-padding-x
      color: $toast-header-color
      background-color: $toast-header-background-color
      background-clip: padding-box
      border-bottom: $toast-border-width solid $toast-header-border-color
      border-top-radius: 0
