.assessment-pages-wrapper
  min-height: 100vh
  margin-left: auto !important
  margin-right: auto !important
  // background-color: #fff

.assessment-pages-wrapper, .assessment-results-wrapper
  display: flex !important
  flex-direction: column !important
  // padding-bottom: 65px !important

  input.checkbox:after
    top: -6px
    left: -1px

  input.checkbox:checked:after
    top: -6px
    left: -1px

  .assessment-actions
    position: fixed
    bottom: 0px
    left: 0px
    // background-color: $body-bg
    border-top: 1px solid $gray-200
    padding: 15px 0px
    width: 100%
    margin-top: auto
    font-size: $h5-font-size
    font-weight: 300
    z-index: 51

    .fix-width
      display: flex !important
      align-items: center !important
      justify-content: center !important

  .assessment-content
    margin-top: auto
    margin-bottom: auto

    .assessment-title
      width: 100%
      font-weight: 300
      line-height: 1.2

      @include media-breakpoint-down(md)
        font-size: 1.5rem
      @include media-breakpoint-up(lg)
        font-size: 1.75rem    

  .assessment-instructions
    margin-bottom: 30px
    font-weight: 300
    font-size: $h3-font-size

  .assessments-custom-disclosure
    p:last-child
      margin-bottom: 0px !important
