body[data-color-mode="black"]

  .text-primary-dark
    color: $cobalt !important

  .text-primary
    color: $cobalt !important

  .text-primary-medium
    color: $cobalt !important

  .text-primary-light
    color: $cobalt_10 !important

  .text-primary-x-light
    color: $cobalt_10 !important


body[data-color-mode="aca"]

  .text-primary-dark
    color: $aca_blue_dark !important

  .text-primary
    color: $aca_blue !important

  .text-primary-medium
    color: $aca_blue_medium !important

  .text-primary-light
    color: $aca_blue_light !important

  .text-primary-x-light
    color: $aca_blue_x_light !important


body[data-color-mode="guide"]

  .text-primary-dark
    color: $guide_green !important

  .text-primary
    color: $guide_green !important

  .text-primary-light
    color: $guide_green_light !important


/*=========================================
 * Gray text color helpers
 *=======================================*/

.text-ethos
  color: $ethos_purple !important

.text-gray800
  color: $gray-800 !important

.text-gray700
  color: $gray-700 !important

.text-gray600
  color: $gray-600 !important

.text-gray500
  color: $gray-500 !important

.text-gray400
  color: $gray-400 !important

