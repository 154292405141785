body[data-page="ResearchCausesShow"], body[data-page="ResearchCausesIndex"]

  .navbar.nav-public:not(.nav-public-bordered)
    background-color: $primary-light !important

    .navbar-collapse
      background-color: $primary-light !important

body[data-page="ResearchCausesShow"]

  // Cause keywords card
  #causeKeywords
    .badge
      border: 1px solid $gray-200


  #companyPerformanceSection

    .dataTables_wrapper
      display: flex !important
      flex-direction: column !important

    .dataTables_paginate
      margin-top: auto !important
      margin-left: auto !important


  .modal-title, .page-title
    font-size: $h3-font-size
    display: flex // Do not make important override in view for social share icons
    align-items: center !important

  .modal-subtitle
    font-size: $h5-font-size
    font-weight: 300

  // Tabs
  #profileNavTabs
    li.nav-item
      padding-right: 10px

      a.nav-link
        background-color: transparent !important
        color: inherit
        border: 0px
        font-size: $h5-font-size

        &:not(.active)
          border-bottom: 4px solid transparent
          font-weight: 300

          &:hover
            color: $primary !important

        &.active
          font-weight: 400
          border-bottom: 4px solid $primary

  .modal-body

    .tab-pane
      padding-top: 15px

      // Transparent cards
      .card.t-card
        margin-left: 10px
        margin-right: 10px

        .card-header
          padding: 0px 0px 0.75rem 0px
          background-color: transparent !important

        .metric-scores-table
          margin-top: 0px !important
          thead
            background-color: darken($primary-x-light, 2%)

      