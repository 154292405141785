/*=========================================
 * Chart navbar (below charts)
 *=======================================*/
// Used to control / interact with charts
// Includes chart legend, date selection
.navbar.nav-chart
  display: flex !important
  align-items: center !important

  .date-selection-group

    .btn
      border: 1px solid transparent
      border-radius: 5px

      // Color active date selection (bottom-right of charts)
      &.active
        background-color: $primary-light
        font-weight: 600

      &:hover
        background-color: $primary-light