.data-collection-logo
  padding: 25px 0px 45px 0px
  width: 100%
  height: 150px
  display: flex !important
  flex-direction: column !important
  justify-content: center !important
  align-items: center !important
  // background-color: $primary-x-light

  img
    max-height: 90px
    max-width: 250px

.data-collection-wrapper
  min-height: 100vh
  margin-left: auto !important
  margin-right: auto !important
  display: flex !important
  flex-direction: column !important
  padding-bottom: 65px !important
  background-color: #fff

  .navbar
    height: 60px

  .navbar.nav-questionnaire-bordered
    border-bottom: 1px solid $gray-300
    background-color: $primary-x-light

    .navbar-collapse
      background-color: $primary-x-light


  span.unchecked
    padding: 12px
    border: 1px solid $primary
    border-radius: 15px
    display: block

  .data-collection-content
    margin-bottom: auto
    padding-bottom: 200px

    .data-collection-title
      width: 100%
      font-weight: 300
      line-height: 1.2

      @include media-breakpoint-down(md)
        font-size: 1.5rem
      @include media-breakpoint-up(lg)
        font-size: 1.75rem

    .card
      overflow: visible !important

  .data-collection-instructions
    margin-bottom: 30px
    font-weight: 300
    font-size: $h3-font-size

  .data-collection-custom-disclosure
    p:last-child
      margin-bottom: 0px !important

.data-collection-tracker
  height: 35px
  display: flex !important
  justify-content: center !important
  align-items: center !important
  position: relative !important
  width: 100%
  max-width: 550px

  .data-collection-progress.progress
    display: block
    position: relative
    overflow: visible
    border-radius: 15px
    background-color: $gray-300
    border: 1px solid $gray-400

  .progress-bar
    height: 35px

    &.completed
      border-radius: 15px

    &:not(.completed)
      border-top-left-radius: 15px
      border-bottom-left-radius: 15px

