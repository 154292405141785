// Cookies request
#cookiesRequest
  width: 100vw
  height: 70px
  display: flex !important
  overflow: hidden
  @include media-breakpoint-down(sm)
    font-size: $h6-font-size
  @include media-breakpoint-up(md)
    font-size: $h5-font-size
  position: fixed
  bottom: 0px
  z-index: 53
  transition: 0.3s all ease
  background-color: $gray-600
  color: $white
  border-top: 1px solid $gray-300

  a:not(.btn)
    color: $white
    text-decoration: underline !important

  p
    margin-top: 0px !important
    margin-bottom: 0px !important

  .fix-width
    margin: auto
    display: flex !important
    align-items: center !important
