body[data-page="AccountsResearchLocationsShow"]

  .modal-header

    .modal-title, .page-title
      font-size: $h3-font-size
      display: flex // Do not make important override in view for social share icons
      align-items: center !important

    .modal-subtitle
      font-size: $h5-font-size
      font-weight: 300

  .modal-body
    background-color: $primary-x-light

    .tab-pane
      padding-top: 15px

      // Transparent cards
      .card.t-card
        margin-left: 10px
        margin-right: 10px

        .card-header
          padding: 0px 0px 0.75rem 0px
          background-color: transparent !important

        .metric-scores-table
          margin-top: 10px !important

  .about-section
    font-size: $h5-font-size

    .sub-section
      overflow: hidden
      margin-right: 0px !important
      margin-left: 0px !important

      &:not(:first-child)
        padding-top: 8px

      &:not(:last-child)
        border-bottom: 1px solid $gray-200

      .col-auto
        padding-bottom: 8px
        overflow: hidden
        padding-right: 0px !important
        padding-left: 0px !important

  // Google map of business address
  .business-address-map
    height: 300px
    width: 100%

  // Add some margin to LIs in descriptions
  li
    margin-bottom: 15px

  p:last-child
    margin-bottom: 0px

