// Controversies

#cont_table_wrapper

  .dataTables_length
    margin-bottom: 0px !important

#contListTab

  .card
    transition: 0s all ease

  .card:not(.full-view) 

    .dataTables_paginate
      position: absolute

    .dataTables_length
      position: absolute

    td:first-child, th:first-child
      padding-right: 0px !important
      width: 250px

    .logo-name-name
      max-width: 570px !important

    .logo-name-link
      max-width: 250px !important
      overflow: hidden
      text-overflow: ellipsis

    .cont-title
      max-width: 315px !important
      overflow: hidden
      text-overflow: ellipsis

  .card-body, .modal-body

    // Show scrollbars
    ::-webkit-scrollbar
      -webkit-appearance: none
      height: 10px !important
      width: 10px !important

    ::-webkit-scrollbar-thumb
      border-radius: 4px
      background-color: rgba(0, 0, 0, .5)
      box-shadow: 0 0 1px rgba(255, 255, 255, .5)

    .dataTables_length, .dataTables_paginate
      font-weight: 300 !important
      font-size: $h6-font-size !important
      z-index: 2
      bottom: 15px

    .dataTables_paginate
      float: none !important
      right: 15px

    .dataTables_length
      left: 15px

      label
        font-weight: 300 !important
        margin-bottom: 0px !important
        @include media-breakpoint-down(xs)
          display: none !important
        @include media-breakpoint-up(sm)
          display: inline-flex !important
          align-items: center !important

      select
        margin-left: 6px
        margin-right: 6px
        padding: 0.25rem 1.75rem 0.25rem 0.5rem !important
        font-size: $h6-font-size !important
        font-weight: 300 !important


.controversy-row, .screen-row, .sdg-contribution-row, .sdg-revenue-row

  &:not(:last-child)
    border-bottom: 1px dashed $gray-300

.controversy-row, .screen-row, .sdg-contribution-row, .sdg-revenue-row
  display: flex !important
  align-items: center !important
  padding: 6px
  z-index: 0

  .progress
    background: transparent !important
    max-width: none !important
    z-index: -1

.controversy-row .progress, .screen-row .progress
  opacity: 0.4

.sdg-contribution-row .progress
  opacity: 0.6

.controversy-row-report
  min-height: 34px

  &:not(:last-child) 
    .col-4, .col-3, .col-20, .col-2
      border-bottom: 1px dashed $gray-300

.screen-row-report

  &:not(:first-child) .card
    border-top-left-radius: 0px
    border-top-right-radius: 0px

  &:not(:last-child) .card
    border-bottom: 1px solid transparent
    border-bottom-left-radius: 0px
    border-bottom-right-radius: 0px

