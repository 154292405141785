body[data-page="ResearchScreensIndex"]

  td:first-child, th:first-child
    padding-right: 0px !important

  th:not(:last-child), td:not(:last-child)
    text-align: left !important

  .logo-name-name
    max-width: 570px !important

  .logo-name-link
    max-width: 415px !important
    overflow: hidden
    text-overflow: ellipsis

  .screen-title
    max-width: 275px !important
    overflow: hidden
    text-overflow: ellipsis

  // Adjust sticky sidebar
  @include media-breakpoint-up(lg)
    .sticky-sidebar
      top: 20px !important

  .spinner-border
    width: 1.5rem !important
    height: 1.5rem !important

  .research-sidebar
    overflow: visible

    // Remove filter
    .remove-filter div div
      white-space: pre-wrap
      text-align: left

    .card
      &:not(:first-child)
        border-top-left-radius: 0px !important
        border-top-right-radius: 0px !important

      &:not(:last-child)
        border-bottom: 0px !important
        border-bottom-left-radius: 0px !important
        border-bottom-right-radius: 0px !important

      &.minimized
        overflow: hidden !important

        .card-header
          border-bottom: 0px !important

        .card-body
          padding: 0px !important
          height: 0px !important
          min-height: 0px !important

      &:not(.minimized), .card-body
        overflow: visible !important

        input:not(.normal-height)
          border-radius: 0px
          border: 0px
          background-color: transparent
          padding-left: 15px !important
          height: 48px !important

  .modal

    input.typeahead
      font-size: $h6-font-size
      font-weight: 300 !important
      height: 35px !important
      background-color: $white !important
      border-color: $gray-400 !important
      cursor: pointer

    .twitter-typeahead
      min-width: 300px !important

    .tt-menu
      z-index: 50 !important // Below navbar
      width: 100%

    .tt-suggestion
      font-size: $h6-font-size !important
      line-height: inherit !important

      &:first-child
        padding: 14px 10px 7px 10px !important

      &:not(:first-child)
        padding: 7px 0px 7px 10px !important

  .research-filters

    a.remove-cause
      padding-top: 5px
      padding-bottom: 5px
      margin-right: 7px
      border: 1px solid $gray-300
      font-weight: 300
      font-size: $h6-font-size !important

  // Slider in modal
  #marketValueModal 

    .irs, .irs-from, .irs-to
      font-size: 16px !important

    .irs-bar, .irs-line
      height: 25px !important

    .irs-handle
      height: 30px !important

  // Filter controls
  .slider-mix-body
    transition: 0.3s all ease

    &.opaque
      opacity: 0

    &:not(.opaque)
      opacity: 1

    // Selected bar
    .irs-bar
      background-color: darken($primary-light, 4%) !important

    // Disabled
    .irs-disable-mask
      cursor: pointer !important

    // Color the pointer primary color
    .irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child
      background-color: darken($primary-light, 4%)

    // Height of bar container
    .irs--flat
      height: 50px !important

    // Selected bar
    .irs-bar
      background-color: $gray-200 !important

    // Color the pointer primary color
    .irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child
      background-color: $gray-500

    // Adjust filter title to just above the actual filter
    .filter-title
      display: flex !important
      align-items: center !important
      font-weight: 300
      margin-bottom: -23px

    // Font size of grid text
    .irs-grid-text
      font-size: 14px !important
      cursor: pointer !important
      font-family: $font-family-sans-serif !important
      color: $gray-700 !important

    // Don't show grid between numbers
    .irs-grid-pol.small
      display: none

    .irs-line, .irs-bar, .irs-handle, .irs-handle.single i
      cursor: pointer

    // Labels above bars
    .irs-single, .irs-from, .irs-to
      background-color: $primary !important

      &::before
        border-top-color: $primary !important

    // Make non-selected bar gray
    .irs-line
      background-color: $gray-200

