body[data-page="QuestionnairesTablesShow"]

  .questionnaire-table
    font-size: $h6-font-size !important

    th, td
      white-space: normal !important
      padding: 10px !important
      height: 35px !important
      text-align: center !important
      border-top: 1px solid $gray-300 !important
      border: 1px solid $gray-300 !important
      background-color: $white !important
      max-width: 100px
      min-width: 100px
      width: 100px

    tr:hover
      background-color: inherit !important

    thead

      th:first-child
        border: 0px !important
        background-color: transparent !important
