.card.featured-card-long
	min-height: 125px
	width: 100%
	box-shadow: none !important
	border-width: 0px 0px 1px 0px !important
	border-radius: 0px !important

	.card-body
		display: flex !important
		padding-bottom: 25px
		padding-top: 25px

		.card-tag
			background-color: $gray-100
			border: 1px solid $gray-200
			border-radius: 5px
			padding: 5px 10px
			font-size: 12px

		.card-logo
			display: flex !important
			justify-content: center !important
			margin-right: 10px
			padding-top: 5px
			width: 50px
			min-width: 50px
			height: 50px
			min-height: 50px

			i, svg
				font-size: 38px

		.card-content
			padding-left: 10px

	&:hover
		background-color: $gray-100
