.questionnaire-card

  &.no-image

    .card
      border: 2px dashed $gray-400 !important

    .card-body
      background-color: $gray-100

  &.image

    .card-body
      background-color: $white

  &.account-questionnaires

    .card-body
      background-color: $white

  &:hover 

    .display-text
      display: none !important

    .questionnaire-action-buttons
      opacity: 1 !important
      color: $gray-700 !important

    .card-body
      background-color: $primary !important
      color: $white !important

      .title-hidden
        display: flex !important

      img
        display: none !important
