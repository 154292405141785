body[data-page="AccountsManagedFundsEdit"]

  #editAccountFundModal

    .modal-content
      height: 90vh

      .modal-footer
        height: 64px

      .modal-header
        height: 62px

      .modal-body
        overflow: auto

    // Sections 
    .section-option
      margin-bottom: 8px
      vertical-align: bottom

      .card.minimized
        max-height: 48px !important

      .card-header:not(.note-toolbar)
        padding-top: 5px !important
        padding-bottom: 5px !important
        height: 48px
        min-height: 48px

      select
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        padding-right: 15px !important

    .drag-section
      cursor: move !important

    .tab-section-title
      font-weight: 400 !important
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden

    .available-section-space
      border: 1px dashed $gray-300
      background-color: $gray-100
      height: 60px
      margin-bottom: 1.5rem

    .available-section-space, .section-option
      width: 100%

    .sortable-sidebar

      .available-section-space, .section-option
        position: relative
        padding-right: 10px
        padding-left: 10px

        &.sorting
          flex: 0 0 50%
          max-width: 50%

        &:nth-child(odd):not(.sorting)
          flex: 0 0 33.3333%
          max-width: 33.333%

        &:nth-child(even):not(.sorting)
          flex: 0 0 66.6667%
          max-width: 66.6667%

    .sortable-split

      .available-section-space, .section-option
        flex: 0 0 50%
        max-width: 50%
        position: relative
        padding-right: 10px
        padding-left: 10px

  #edit_account_fund_form
    font-size: $h5-font-size

    .form-check:not(:last-child)
      border-bottom: 1px dashed $gray-300

    .card.note-editor
      border-radius: 0px !important
      border-left-width: 0px !important
      border-right-width: 0px !important
      box-shadow: none !important
      border-color: $gray-300 !important

    .note-toolbar
      background-color: $primary-x-light

      .btn
        border: 1px solid $gray-300

    .layout-opt
      width: 70px

      &.active .layout-graphic
        background-color: $primary-light

      .layout-graphic
        width: 60px
        height: 60px
        border: 1px solid $gray-400
        border-radius: 5px
        display: flex !important

        .half-line
          height: 60px
          width: 1px 
          background-color: $gray-400
          margin-left: auto
          margin-right: auto

        .side-line
          height: 60px
          width: 1px 
          background-color: $gray-400
          margin-left: 20px


  .dropdown-fontname
    height: 250px
    overflow: auto
    will-change: unset !important
    transform: none !important

