.extra-info-container
  white-space: normal !important
  cursor: default // Avoid pointer cursor
  font-size: $h6-font-size

  p
    margin-bottom: 0.5rem !important

  .metric-info
    font-weight: 300

    &:not(:last-child)
      padding-bottom: 1rem
      border-bottom: 1px dashed $gray-300
      margin-bottom: 1rem

    p:last-child, ul:last-child, ol:last-child
      margin-bottom: 0px !important

.expand-table

  td:last-child, th:last-child
    padding-left: 30px !important

  tr.shown 
    border-left: 1px solid $gray-300 !important
    border-right: 1px solid $gray-300 !important

    td
      background-color: $primary-light
      font-weight: 400 !important
      padding-left: 5px !important
      padding-right: 5px !important

    td:last-child
      padding-left: 25px !important

  tr.expanded-row td
    padding: 0px !important
    border-left: 1px solid $gray-300
    border-right: 1px solid $gray-300
    border-bottom: 1px solid $gray-300

