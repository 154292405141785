.help-circle-inline
  margin-left: 2px
  margin-top: auto
  margin-bottom: auto
  
  &:not(.d-none)
    display: inline-block !important

  &.darker
    i, svg
      color: darken($gray-500, 1%)

  i, svg
    color: darken($gray-400, 1%)
    font-size: 0.85rem

    @include media-breakpoint-down(xs)
      display: none !important

.help-circle
  margin-left: 5px
  margin-top: auto
  margin-bottom: auto
  
  &:not(.d-none)
    display: flex !important

  &.darker
    i, svg
      color: darken($gray-500, 1%)

  i, svg
    color: darken($gray-400, 1%)
    font-size: 0.85rem

    @include media-breakpoint-down(xs)
      display: none !important