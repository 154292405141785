/*=========================================
 * Public pages
 *=======================================*/
// Re-used section styling across pages and apps
.public-fadeIn
  opacity: 1
  transition: 1s opacity ease

  &.opaque
    opacity: 0

.public-icon
  opacity: 0.4

.public-section
  display: flex
  align-items: center

  // Hero statements (large text featured in a section)
  .hero-statement
    line-height: 1
    font-weight: 600 !important
    @include media-breakpoint-down(xs)
      font-size: 7.5vw

    @include media-breakpoint-between(sm, md)
      // Make slightly smaller for longer text to fit on 2 lines
      &.smaller
        font-size: 1.75rem
      &:not(.smaller)
        font-size: 2.25rem

    @include media-breakpoint-between(md, lg)
      font-size: 2.75rem

    @include media-breakpoint-up(lg)
      font-size: 3.5rem

  // Hero sub-statements (slightly smaller text featured in a section)
  .hero-sub-statement
    line-height: 1.15
    font-size: $h4-font-size

    @include media-breakpoint-down(xs)
      font-size: 3.5vw

    @include media-breakpoint-between(sm, md)
      font-size: 1.75rem

    @include media-breakpoint-between(md, lg)
      font-size: 2rem

    @include media-breakpoint-up(lg)
      font-size: 2.75rem


  // Display demo screenshots
  .demo-display
    display: flex !important
    justify-content: space-around !important

    .tab-pane
      text-align: center

    .nav

      a.nav-link
        padding-left: 0px !important
        @include media-breakpoint-down(md)
          padding-top: 10px
          padding-bottom: 10px
        @include media-breakpoint-up(lg)
          padding-top: 25px
          padding-bottom: 25px

        &.active
          background-color: inherit !important
          font-weight: 600 !important
          color: inherit

    .tab-pane img
      cursor: pointer
      max-height: 450px

  &.main-top
    display: flex
    margin-top: 100px
    padding-bottom: 0px !important
    min-height: calc(100vh - 100px)

  &.financial-advisors-top
    display: flex
    padding-bottom: 0px !important
    height: calc(100vh - 60px)
    position: relative

  &.solutions-top
    display: flex
    margin-top: 100px
    padding-bottom: 0px !important
    min-height: 65vh
    position: relative

  &.ctas-section
    padding-top: 40px !important
    padding-bottom: 260px !important

  &.section-top
    position: relative
    display: flex
    align-items: center
    height: 100vh

    .top-content
      height: calc(var(--vh, 1vh)*85)

  // Spacing for different sections to interact with the dividers (conveyor belts)
  &.section-two
    @include media-breakpoint-down(sm)
      padding-top: 60px
      padding-bottom: 30px
    @include media-breakpoint-up(md)
      padding-top: 100px
      padding-bottom: 30px

  &.section-three
    padding-top: 40px
    @include media-breakpoint-up(lg)
      padding-bottom: 30px

  &.section-four
    @include media-breakpoint-down(sm)
      padding-top: 60px
      padding-bottom: 30px
    @include media-breakpoint-up(md)
      padding-top: 60px
      padding-bottom: 30px

  &.section-five
    padding-top: 60px
    padding-bottom: 100px

  &.section-learn-more
    padding-top: 70px !important
    padding-bottom: 70px !important
    background-color: $white !important