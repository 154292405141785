.fixed-footer
  position: fixed
  bottom: 0px
  left: 0px
  background-color: $body-bg
  border-top: 1px solid $gray-200
  padding: 15px 0px
  width: 100%
  margin-top: auto
  font-size: $h5-font-size
  font-weight: 300
  z-index: 103

  .fix-width
    display: flex !important
    align-items: center !important
    justify-content: center !important

