body[data-page="QuestionnairesTablesEdit"]

  .questionnaire-table
    font-size: $h6-font-size !important

    th, td
      white-space: normal !important

    td, th
      padding: 10px !important
      height: 35px !important
      text-align: center !important

    th:not(.add-cell), td:not(.add-cell)
      border-top: 1px solid $gray-300 !important
      border: 1px solid $gray-300 !important
      background-color: $white !important

    td:first-child, th:first-child
      width: 200px !important
      min-width: 200px !important

    td:not(:first-child), th:not(:first-child)
      max-width: 100px
      min-width: 100px
      width: 100px

    tr:hover
      background-color: inherit !important

    tfoot

      td:first-child, td:last-child
        border: 0px !important
        background-color: transparent !important

    thead

      th:first-child, th:last-child
        border: 0px !important
        background-color: transparent !important
