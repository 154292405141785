body[data-page="SettingsAccountsMethodologiesIndex"]

  .progress-bar
    height: 15px !important


body[data-page="SettingsAccountsMethodologiesEdit"]

  // Tables
  .table-container
    overflow: auto
    max-height: 100%

  .card-footer

    form, .twitter-typeahead
      height: 100%

      input
        height: 100%  

body[data-page="SettingsAccountsMethodologiesCompaniesIndex"]

  .card-footer
    position: absolute
    bottom: 0px
    width: 100%

  .card-body: 
    padding-bottom: 55px


body[data-page="SettingsAccountsMethodologiesEdit"], body[data-page="SettingsAccountsMethodologiesSectorsIndex"], body[data-page="SettingsAccountsMethodologiesCompaniesIndex"]

  #methodologySector
    border: 1px solid $gray-500 !important

  .title-name-short
    width: 90%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  input::-webkit-input-placeholder
    color: $gray-600 !important

  #setMethodologyInfo h3
    border: 1px solid transparent
    padding: 5px
    margin-left: -5px

    &:hover
      border: 1px solid $gray-200
      background-color: $white
      border-radius: 5px

  .card-footer.table-info-footer
    bottom: 0px
    height: 50px
    min-height: 50px

  .methodology-footer
    position: fixed
    bottom: 0px
    left: 0px
    height: 50px
    border-top: 1px solid $gray-300
    z-index: 52
    display: flex !important
    align-items: center !important

    &.sidebar-offset:not(.minimized)
      @include media-breakpoint-down(sm)
        width: calc(100% - 70px) !important

      @include media-breakpoint-up(lg)
        width: calc(100% - 250px) !important

    &.sidebar-offset.minimized
      width: 100%

    .fix-width
      display: flex !important
      align-items: center !important

  // Main content div
  .methodology-content
    @include media-breakpoint-down(md)
      min-height: 400vh
    @include media-breakpoint-up(lg)
      min-height: 100vh

  // No min width
  .card-table-search
    min-width: min-content !important

  .methodology-positioning
    .logo-name-name
      @include media-breakpoint-up(lg)
        max-width: 170px !important

body[data-page="SettingsAccountsMethodologiesEdit"], body[data-page="SettingsAccountsMethodologiesSectorsIndex"]

  // Table
  .table
    margin-top: 0px !important

    tr
      position: relative !important

      &.active td
        background-color: $primary-light
        font-weight: 400 !important

    th
      vertical-align: bottom !important

    td
      font-weight: 300 !important
      height: 40px !important

    td:first-child, th:first-child
      min-width: 250px !important
      width: 250px !important
      text-align: left !important

    td:nth-child(2), th:nth-child(2)
      text-align: right

    td:last-child, th:last-child
      width: 1px
      max-width: 1px
      overflow: hidden

    .metric-name-short
      max-width: 250px !important
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    .input-group
      justify-content: flex-end !important

      input
        width: 95px !important
        max-width: 95px
        height: 31.5px !important
        border-right: 0px !important

      .input-group-append
        position: absolute
        height: 31.5px !important
        border-left: 0px !important
        
        .input-group-text
          background-color: $white !important
          border-left: 0px !important

    .dt-hover-outer
      top: 0px !important

      .dt-hover-inner 
        .btn:not(:last-child)
          margin-right: 0px !important

  .table.weighting-table

    .dt-hover-outer
      right: 115px !important

  // Cause modal
  #causeModal, #metricModal

    .cat-link:not(:last-child)
      border-bottom: 1px solid $gray-300

  // Button overflows
  .overflow-ellipsis
    max-width: 125px
    overflow: hidden
    text-overflow: ellipsis

  .tt-suggestion:not(.no-selections)
    padding: 5px 10px !important
    min-height: 35px

  .tt-suggestion
    align-items: center !important

    &:not(.d-none)
      display: flex !important

    &:not(:last-child)
      border-bottom: 1px dashed $gray-300

    i, svg
      font-size: 20px !important
      margin-right: 1px

    span.checkbox
      width: 15px
      padding: 0px 10px
      border: 1px solid $gray-400
      border-radius: 3px

      &:hover
        -webkit-box-shadow: 0px 2px 3px $gray-500
        -moz-box-shadow: 0px 2px 3px $gray-500
        box-shadow: 0px 2px 3px $gray-500


  .spinner-border:not(.xs)
    width: 1.5rem !important
    height: 1.5rem !important


  // Search causes
  .card
    overflow: visible !important

    input.typeahead
      border-radius: 0px
      border: 0px
      padding-left: 15px !important
      height: 100% !important

    .twitter-typeahead

      .tt-menu 
        bottom: 100% !important
        top: auto !important

        .tt-suggestion
          padding: 8px 0px 8px 10px

          &:not(:last-child)
            border-bottom: 1px dashed $gray-200

          &:hover
            background-color: lighten($primary-light, 2%)

