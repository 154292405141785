.summernote-content, .summernote-content p span, .summernote-content li span 
  line-height: inherit!important
  font-size: $h5-font-size!important
  font-family: $font-family-sans-serif

.note-editable
  background-color: $white !important

// Summernote modal
.note-modal
  .modal-header
    background-color: $gray-200 !important

  .modal-title
    font-size: $h5-font-size !important
    font-weight: bold !important

.note-editor
  width: 100%

.note-placeholder
  font-weight: 300