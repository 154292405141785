body[data-page="PublicCompaniesShow"], body[data-page="PublicFundsShow"]

  #profileNavTabs li.nav-item 
    display: flex !important
    align-items: end !important

    a.nav-link
      text-align: center


  .navbar.nav-public:not(.nav-public-bordered)
    background-color: $primary-light !important

    .navbar-collapse
      background-color: $primary-light !important

  .about-section
    font-size: $h5-font-size

    .sub-section
      overflow: hidden
      margin-right: 0px !important
      margin-left: 0px !important

      &:not(:first-child)
        padding-top: 8px

      &:not(:last-child)
        border-bottom: 1px solid $gray-200

      .col-auto
        padding-bottom: 8px
        overflow: hidden
        padding-right: 0px !important
        padding-left: 0px !important

  .impact-screens-list .table
    font-size: $h6-font-size

    .help-circle
      margin-left: 3px !important

    tr

      &:first-child td
        border-top: 0px !important

      td
        height: 20px !important
        white-space: normal !important
        padding-top: 4px
        padding-bottom: 4px

        &:first-child
          padding-left: 5px

        &:nth-child(2) div
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap

        &:last-child
          padding-right: 8px
          div
            // width: 75px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

  // About tab
  #aboutTab

    // Google map of business address
    .business-address-map
      height: 300px
      width: 100%

    // Add some margin to LIs in descriptions
    li
      margin-bottom: 15px

    p:last-child
      margin-bottom: 0px

  #allFormulas, #metricScoreDetailsCard, #controversiesDetailsCard
    .chart-container
      height: 110px

      .chart
        height: 100px

  // Commentary on company impact
  #commentaryCard
    
    .metric-name, .formula-name
      font-style: italic !important

