/*=========================================
 * Funds app banner
 *=======================================*/

.banner-piggy-banks
  overflow: hidden

  // Piggy banks
  .piggy-bank-container
    position: absolute
    bottom: 0
    width: 100%
    margin: auto 20px -15px -50px
    display: flex !important
    align-items: center !important
    justify-content: flex-end !important

    span
      margin: auto auto 0 auto
      z-index: 48
      color: $primary

      &:first-child
        margin-bottom: -5px

      &:nth-child(1)
        margin-left: -5px

      &:nth-child(3)
        margin-left: -5px

      &:nth-child(5)
        margin-left: -18px
        margin-bottom: -10px

      &:nth-child(6)
        margin-left: -15px

      &:nth-child(7)
        margin-left: 15px
        margin-bottom: 15px