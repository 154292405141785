.sidebar-container
  height: 100%
  width: 100%

  @include media-breakpoint-up(lg)
    position: absolute
    top: 0
    padding-right: 15px

.sticky-sidebar, .sidebar
  max-width: 350px

.sticky-sidebar
  position: -webkit-sticky
  position: sticky !important
  // Z-index must be below navbar z-index for IE10 and IE11, which render sticky as position-relative
  // https://getbootstrap.com/docs/4.4/utilities/position/
  z-index: 48

  @include media-breakpoint-up(lg)
    max-height: calc(100vh - 100px)
    transition: 0.3s ease-in    
    top: $app-navbar-height + 25px // Offset so content not hidden behind navbar

.sidebar  

  @include media-breakpoint-up(lg)
    max-height: calc(100vh - 100px)
    background-color: $primary-x-light
    box-shadow: $shadow
    border: 1px solid $gray-300
    border-radius: 5px
    overflow: auto

  @include media-breakpoint-down(md)

    .sidebar
      padding-bottom: 30px

  a
    &:not(.btn)
      color: inherit

      &:hover
        color: $primary

    &.active
      font-weight: 600 !important
      color: $primary

  .sidebar-section
    margin-bottom: 0px
    transition: all 0.3s ease

    &:not(.minimized)
      min-height: 42px
      max-height: none

    &:not(:last-child)
      border-bottom: 1px solid $gray-300

    &.minimized
      max-height: 42px !important
      min-height: 42px !important
      overflow: hidden

    .sidebar-title
      font-size: $h5-font-size
      padding-bottom: 10px
      font-weight: 600

      .indicator
        font-size: $h6-font-size
    
    .sidebar-section-title
      
      @include media-breakpoint-up(lg)
        height: 50px
        padding: 15px

    .sidebar-sub-section

      &.minimized
        max-height: 42px
        min-height: 42px

        &:not(.overflow-visible)
          overflow: hidden

      .sidebar-section-subtitle
        font-size: 1rem

      .sub-cause
        border-top: 1px solid $gray-200

      @include media-breakpoint-up(lg)

        &:not(:last-child)
          border-bottom: 1px solid $gray-200

        &:not(.active), &.minimized
          background-color: darken($primary-x-light, 1%)

        &:not(.minimized)
          background-color: $primary-x-light

        .sidebar-section-subtitle
          height: 42px
          padding: 10px 15px

          @include media-breakpoint-down(lg)
            font-size: $h6-font-size
          @include media-breakpoint-up(xl)
            font-size: 1rem

        // Make typeaheads match style of other sub-sections
        .twitter-typeahead
          flex-basis: 0
          flex-grow: 1
          max-width: 100%

        input.typeahead
          border-radius: 0px
          border: 0px
          padding-left: 15px !important
          height: 42px !important
          background-color: transparent

    .sidebar-content
      font-weight: 300

      ul
        list-style: none
        padding-left: 0px
        font-size: 1rem

        li
          padding: 10px 15px

          &:hover
            background-color: darken($primary-x-light, 1%)


        &.featured-list
          margin-bottom: 0px

          li:first-child
            padding: 10px 10px 2px 10px !important
            border-bottom: 1px solid $gray-400

            &:hover
              background-color: inherit !important

          li:last-child
            padding: 2px 10px 10px 10px !important

          li:not(:first-child):not(:last-child)
            padding: 2px 10px !important
            border-bottom: 1px dashed $gray-400


    // Keep formula look-up typeahead dropdown within bounds of sidebar
    .tt-menu
      max-width: 300px
