.chevrons-row

  .chevron-block

    .card
      height: 100px
      box-shadow: none !important

    @include media-breakpoint-down(sm)
      .description
        font-size: $h4-font-size
      .number
        font-size: $h2-font-size

    @include media-breakpoint-between(md, lg)
      .description
        font-size: $h5-font-size
      .number
        font-size: $h3-font-size

    @include media-breakpoint-up(lg)
      .description
        font-size: $h4-font-size
      .number
        font-size: $h2-font-size

    @include media-breakpoint-up(md)

      &:not(:first-child)
        border-left: 0px !important

        .chevron::before
          content: ""
          position: absolute
          height: 0
          width: 0
          left: 0
          top: 0
          border: 50px solid transparent
          border-left: 20px solid $body-bg
          z-index: 2

      .card
        border-radius: 0px !important
        border-right: 0px !important

      .chevron
        position: relative
        text-align: center
        line-height: 40px

        &::after
          content: ""
          position: absolute
          height: 0
          width: 0
          left: 100%
          top: 0
          border: 50px solid transparent
          z-index: 3

        &.bg-primary-light:after
          border-left: 20px solid $primary-light

        &.bg-c-rating:after
          border-left: 20px solid $c-rating-opaque

        &.bg-b-rating:after
          border-left: 20px solid $b-rating-opaque

        &.bg-a-rating:after
          border-left: 20px solid $a-rating-opaque

