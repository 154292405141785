// Large icon in background of a card
.card-bg-icon
  position: absolute
  top: 0px
  left: 0px
  height: 100%
  width: 100%
  i, svg
    font-size: 286px
    opacity: 0.08


