body[data-page="QuestionnairesNew"], body[data-page="QuestionnairesEdit"]

  .framework-select
    opacity: 0.8

    &.active
      opacity: 1
      border: 2px solid $primary !important

body[data-page="QuestionnairesEdit"]

  // Name/title
  #setQuestionnaireInfo h3
    border: 1px solid transparent
    padding: 5px
    margin-left: -5px

    &:hover
      border: 1px solid $gray-200
      background-color: $white
      border-radius: 5px

  // Preview cards for drag/drop
  .questionnaire-options-card
    height: 120px !important
    border: 1px solid $gray-300

    .grip
      cursor: move !important
      z-index: 51
      height: 25px
      width: 25px

    &:hover 
      .module-title:not(.show)
        opacity: 0 !important

      .module-icon
        opacity: 0 !important

      .module-action-buttons
        opacity: 1 !important

  .add-module-card
    height: 120px !important
    border: 2px dashed $gray-400
    background-color: $gray-100

    &:hover
      background-color: $primary-light

  .available-section-space
    border: 2px dashed $gray-400
    background-color: $gray-100
    height: 120px
    margin-bottom: 1.5rem
    width: 100%
    position: relative
    padding-left: 10px
    padding-right: 10px

    @media (max-width: 991.99px)
      flex: 0 0 50%
      max-width: 50%

    @media (min-width: 992px)
      flex: 0 0 33.33333%
      max-width: 33.33333%

    @media (min-width: 1200px)
      flex: 0 0 25%
      max-width: 25%

