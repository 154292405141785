/*=========================================
 * Swal alerts
 *=======================================*/

// Main formatting for sweet alert popups
.swal2-popup
  padding: 0px !important // Remove padding around entire modal, add back in header and body

  .swal2-header
    background: $primary-light
    padding: 0.875rem
    border-top-left-radius: 0.3125em
    border-top-right-radius: 0.3125em

    .swal2-title // Main title within header
      margin-bottom: 0px !important
      font-size: 1.5rem !important // h2 size
      font-weight: 600
      color: $gray-700 !important

  .swal2-content
    font-size: 0.9375rem // h5 size
    color: $gray-700 !important
    padding: 1rem

  .swal2-actions
    margin-top: 0px !important
    margin-bottom: 10pxw
    padding: 0.75rem

    .swal2-styled
      font-size: $font-size-base !important
      padding: 0.375rem 0.75rem

    .swal2-styled:focus, .swal-button:focus
      box-shadow: none !important

    .swal2-confirm
      font-weight: 600
      border-left-color: $primary !important
      border-right-color: $primary !important
      background-color: $primary


// Adjust button styling
.swal-button--cancel
  color: $gray-700

.swal-button--confirm.btn-warning:hover
  background-color: darken($warning, 15%) !important

.swal-button--confirm.btn-primary:hover
  background-color: darken($primary, 15%) !important