/*=========================================
 * Research show banner (companies, securities show pages)
 *=======================================*/

.banner-globe-background

  .icon-container
    position: relative
    overflow: hidden
    height: 75px
    max-width: 1170px
    width: 100%
    margin: 0 auto
    z-index: 49

  .banner-logo-container
    height: 75px
    position: absolute
    max-width: 1170px
    width: 100%
    margin: 0 auto
    z-index: 50

    @include media-breakpoint-down(lg)
      padding: 0 30px

    .logo-container
      background-color: $white
      bottom: 0
      width: 100px
      height: 100px
      margin-bottom: -40px

  // Icons
  .fa-globe-americas
    font-size: 912px
    opacity: 0.2

  // City / buildings
  .city-container
    position: absolute
    right: 120px
    bottom: 0

    i, svg
      position: absolute
      bottom: 0px

    .building-1
      right: 70px

    .building-2
      left: -80px

    .building-3
      left: 0px

    .building-4
      left: 10px

    .industry-1
      bottom: -6px
      left: 60px
