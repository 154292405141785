#AssistantContainer

	.AssistantLoading
		padding: 8px 12px
		color: $gray-700
		border-radius: 12px
		margin-bottom: 10px

	.thread-row

		.thread-actions
			display: none

		&:hover .thread-actions
			display: block

	&.closed
		padding: 10px 15px
		display: flex !important
		align-items: center !important
		justify-content: center !important
		color: $gray-700
		background-color: $gray-100
		font-weight: 600

		.Open
			display: block
			cursor: pointer !important

		.AssistantHeader, .AssistantForm, #AssistantContent
			display: none !important

	&.fixed
		position: fixed
		bottom: 15px
		right: 15px
		z-index: 10001
		background-color: $white
		border: 1px solid $gray-300
		border-radius: 5px
		box-shadow: $box-shadow

		#AssistantContent
			height: 350px
			min-height: 350px

	&:not(.fixed)

		#AssistantContent
			height: 65vh
			min-height: 65vh

	&.open
		height: 500px
		width: 400px
		display: flex !important
		flex-direction: column

		.Open
			display: none

		.AssistantHeader
			height: 40px
			padding: 10px
			display: flex !important
			align-items: center !important
			justify-content: flex-end !important
			border-bottom: 1px solid $gray-300

		.AssistantForm
			padding: 10px

			#AssistantSuggestions
				margin-top: 5px
				font-size: 12px

		#AssistantContent
			padding: 10px 10px 0px 10px
			overflow: auto
			display: flex
			flex-direction: column

			.MessageContainer
				padding: 12px
				color: $gray-700
				border-radius: 12px
				margin-bottom: 10px

				li
					margin-bottom: 1rem

				&.FromAssistant
					background-color: $gray-200
					margin-left: 20px

				&.FromUser
					background-color: $gray-100
					border: 1px solid $gray-200
					margin-right: 20px

				.MessageInfo
					padding: 3px 0px
					margin-bottom: 5px
					font-size: 11px
					display: flex !important
					align-items: flex-center !important
					width: 100%

					.MessageDate
						margin-right: auto

					.MessageDestroy
						margin-left: auto

