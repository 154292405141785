body[data-page="AssessmentsEditCategories"]

  // SDGs toggle
  #toggleSdgs

    &.opaque
      opacity: 0.4

      &:hover
        opacity: 1 !important

  // Impact icons
  .categories-container
    margin-top: 20px
    padding-top: 15px
    display: flex !important

    @include media-breakpoint-down(sm)
      justify-content: center !important
    
    .impact-assessment-icon
      display: flex !important
      margin-right: auto
      align-items: center !important
      text-align: center

      @include media-breakpoint-down(sm)
        width: 90px
      @include media-breakpoint-between(md, lg)
        width: 100px
      @include media-breakpoint-up(lg)
        width: 112px

      // Icons
      span.fa-layers
        opacity: 0.3
        font-size: 9em
        svg, i
          cursor: pointer !important

        &:hover, &.active
          opacity: 1

          .fa-check
            display: flex !important

      // Name
      .impact-category-name
        font-size: $h5-font-size
        font-weight: 300
