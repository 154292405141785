#dashboardModulesModal

  .drag-module
    cursor: move !important

  .available-module-space
    border: 1px dashed $gray-300
    background-color: $gray-100
    height: 30px


#dashboardContainer

  .card:not(.full-view):not(.custom-height)

    .dataTables_scrollBody
      height: 208px !important
      max-height: 208px !important

  .card:not(.full-view):not(.custom-height)
    .table-responsive
      min-height: 229px !important

  .progress
    background-color: transparent !important
    border-radius: 0px !important

  #contListTab 

    .card

      .dataTables_scrollBody
        height: 305px !important
        max-height: 305px !important

      .cont-title
        max-width: 515px !important

  .dashboard-item-card
    min-height: 380px

    .dashboard-link
      overflow: hidden
      height: 60px

    .progress-bar

      &.narrow
        height: 15px

      &:not(.narrow)
        height: 30px

    .dropdown .btn::after
      display: none !important

    .dashboard-item-name
      height: 45px
      line-height: 1
      overflow: hidden
      width: 125px
      min-width: 125px

    .pagination li
      margin-bottom: 0px !important

  .dashboard-content
    ul
      width: 100%
      z-index: 1 // Above background icons

      li
        &:nth-child(2) a
          border-left: 0px !important

        a
          cursor: pointer
          font-weight: 300
          border: 1px solid $gray-400 !important

          &:not(.active)
            background-color: $white
            color: $gray-700

            &:hover
              background-color: darken($primary-x-light, 1%)

          &.active
            background-color: $primary-light

    .tab-content
      width: 100%

    .dashboard-to-do-section
      
      .card
        overflow: hidden

    .tab-pane

      input.typeahead
        font-size: $h4-font-size
        font-weight: 300 !important
        height: 52px !important
        background-color: $white !important
        padding-top: 5px
        border-color: $gray-400 !important
        cursor: pointer
        border-top-left-radius: 0px !important

      .tt-menu
        z-index: 50 !important // Below navbar
        width: 100%

      .tt-suggestion
        font-size: $h5-font-size !important

      &#searchAccountsInvestments

        .tt-suggestion
          line-height: 30px

      &:not(#searchAccountsInvestments)

        .tt-suggestion
          line-height: inherit !important

          &:first-child
            padding: 14px 10px 7px 10px !important

          &:not(:first-child)
            padding: 7px 0px 7px 10px !important


  .card-list-items .card .description
    max-height: 84px !important
    overflow: visible !important


  .card-footer
    z-index: 1
    bottom: 0px
    width: 100%

  .dataTables_paginate, .dataTables_length, .dataTables_filter, .dataTables_length select
    font-size: $h6-font-size !important

  .dataTables_paginate
    position: absolute

  .dataTables_length
    position: absolute

  .dataTables_paginate
    float: none !important
    right: 15px
    z-index: 2
    bottom: 10px

  .dataTables_length
    left: 15px
    z-index: 2
    bottom: 10px
    margin: 0px !important

    label
      font-weight: 300 !important
      margin-bottom: 0px !important
      @include media-breakpoint-down(xs)
        display: none !important
      @include media-breakpoint-up(sm)
        display: inline-flex !important
        align-items: center !important

    select
      margin-left: 6px
      margin-right: 6px
      padding: 0.25rem 1.75rem 0.25rem 0.5rem !important
      font-weight: 300 !important



// Shared dashboards style
// Impact banner section
#impactSectionTitle
  position: relative

  @include media-breakpoint-up(lg)
    height: 35px

  @include media-breakpoint-down(md)

    .position-absolute
      display: none !important

  .position-absolute
    bottom: -15px
    z-index: 1040 // Below modal, above banner impact

  &.scrolled
    background-color: darken($primary-x-light, 2%)

// Dashboard sections
.app-section

  &:not(.expanded)
    padding: 15px 0px

  &.expanded

    &:first-child
      padding: 15px 0px 30px 0px

    &:not(:first-child)
      padding: 30px 0px

  &.last
    padding-bottom: 40px !important

  &:not(:last-child)
    border-bottom: 1px dashed $gray-300

.new-container
  height: calc(100vh - 70px)

// Main search section on dashboards
.dashboard-search-content
  padding: 15px 0px
  width: 100%

  // Typeahead menu
  .tt-menu
    max-height: 250px !important

    .tt-suggestion
      font-size: $h5-font-size !important
      line-height: 30px !important

  .search-form

    .input-group
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08)

      .input-group-text
        border-color: $gray-300 !important

      input
        height: 55px !important
        font-size: $h5-font-size
        font-weight: 300
        border-left: 1px solid
        border-color: $gray-300 !important
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px
        padding-left: 10px !important

        &:hover
          cursor: pointer

      .tt-menu
        width: calc(100% + 33px) !important
        left: 0px !important

// Recommendations
.recs-content
  padding: 15px 0px

  .add-recs .btn
    color: inherit
    border: 1px solid $gray-300
    background-color: darken($primary-x-light, 5%)
    border-color: $gray-400 !important

    &:hover
      background-color: darken($primary-x-light, 8%)

// Make circles less obtrusive
.list-circles
  display: flex !important
  align-items: center !important

  .circle
    height: 8px !important
    width: 8px !important
    border-radius: 50%
    display: inline-block
    margin: 0px 5px 0px 3px

    &:not(.active)
      background-color: $gray-200 !important


// Impact banner
.banner-impact
  width: 100%
  padding: 15px 0px

  // Sticky top only on medium or up
  @include media-breakpoint-up(md)
    top: 0
    z-index: 1020

    &.scrolled
      border-bottom: 1px solid $gray-400
      background-color: darken($primary-x-light, 2%)

  .fix-width
    position: relative
    width: 100%

    &:hover .persona-hero:not(.scrolled)
      z-index: 1 // Below icons

// Persona box
.persona-hero
  height: 95px
  z-index: 50 // Above icons
  background-color: darken($primary-x-light, 1%)

  .card-body
    padding: 10px 15px 5px 15px

  .rating-title
    font-size: $h5-font-size
    white-space: nowrap
    text-align: center
    line-height: 1
    display: flex !important
    align-items: center !important
    color: inherit

    i, svg
      color: $gray-400

    &:hover
      color: $primary

      i, svg
        color: $primary

  .row
    margin-left: -2px

  .cause-icon-container
    min-width: 60px
    width: 100%

    &:not(:last-child)
      padding-right: 6px

    &:not(:first-child)
      padding-left: 6px

    @include media-breakpoint-down(md)
      margin-bottom: 10px

    .cause-icon
      height: 40px
      width: auto
      font-size: 20px
      background-color: lighten($primary-light, 1%)

      &:not(:last-child)
        margin-right: 3px

// Chart rating bell curve
.rating-bell-curve-container
  z-index: 51

  .rating-bell-curve-chart
    margin-top: -10px

    .c3-lines .c3-line 
      opacity: 0 !important

    .c3-areas .c3-area
      opacity: 0.15 !important

  .c3-tooltip-container
    max-width: 300px

// Impact scale
.impact-scale
  width: 100%
  position: relative
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08)
  
  &.narrow
    height: 70px

    .score-indicator
      height: 85px

    .regions, .regions .region
      height: 70px

  &:not(.narrow)
    height: 95px

    .score-indicator
      height: 110px

    .regions, .regions .region
      height: 95px

  .score-indicator
    position: absolute
    top: -5px
    width: 2px
    transition: 0.3s all ease

  .impact-score
    position: absolute
    top: 40%
    height: 20px
    display: flex !important
    font-size: $h4-font-size
    z-index: 2
    width: 100%

    @include media-breakpoint-down(sm)
      min-width: 50vw !important

  .regions
    display: flex !important
    justify-content: center !important
    opacity: 0.5

    .region
      border-top: 1px solid $gray-300
      border-bottom: 1px solid $gray-300
      border-right: 1px solid $gray-300
      background-clip: border-box
      width: 20%

      &:first-child
        border-left: 1px solid $gray-300
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px

      &:last-child
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px

      &.a-rating
        color: $a-rating-dark
        background-color: $a-rating

      &.b-rating
        color: darken($b-rating-dark, 25%)
        background-color: $b-rating

      &.c-rating
        color: $c-rating-dark
        background-color: $c-rating

      &.d-rating
        color: $d-rating-dark
        background-color: $d-rating

      &.f-rating
        color: $f-rating-dark
        background-color: $f-rating

      &.no-rating
        color: inherit
        background-color: $a-rating


// Recommendations modal
.modal.rec-modal
  height: 100vh

  .modal-body
    height: 83vh
    overflow: auto
    padding: 0px 0px 64px 0px

  .modal-footer
    position: absolute
    bottom: 0px
    left: 0px
    background-color: $primary-light
    width: 100%
    height: 64px

  .section
    padding: 0px 10px 10px 10px

    &:not(.minimized)
      height: auto
      min-height: 40px
      max-height: calc(83vh - 143px)
      overflow: auto

      .subtitle
        margin-top: 0px

    &.minimized
      height: 40px !important
      overflow: hidden
      background-color: $gray-100

    .subtitle
      display: flex !important
      align-items: center !important
      font-weight: 300
      margin-top: 10px

      @include media-breakpoint-down(sm)
        margin-bottom: 18px
      @include media-breakpoint-up(md)
        margin-bottom: 12px

  .card
    margin-bottom: 15px
    box-shadow: none !important

    .card-img-top
      height: 75px
    
    .select-impact-assessment, .select-recommended-list
      cursor: pointer

      &:hover, &.active
        color: $primary
        background-color: $primary-light

        .cause-icon
          opacity: 1

    .cat-cause
      font-weight: 600
      display: flex !important
      align-items: center !important
      padding-bottom: 7px
      padding-top: 3px

    .select-cause, .select-recommended-list
      cursor: pointer
      padding-left: 3px
      padding-right: 3px

      &.active, &:hover
        background-color: $primary-light

        .cause-icon
          opacity: 1

    .sub-cause
      border-top: 1px dashed $gray-300

      .select-cause, .select-recommended-list
        padding-top: 3px
        padding-bottom: 3px

    .select-cause.active, .sub-cause.active, .select-recommended-list.active
      background-color: $primary-light

  .select-record-type
    font-weight: 300
    padding-top: 5px
    padding-bottom: 5px
    color: inherit
    display: flex !important
    align-items: center !important
    cursor: pointer

    &:not(:first-child)
      border-top: 1px dashed $gray-300

    &:hover, &.active
      color: $primary
      background-color: $primary-light

      .cause-icon
        opacity: 1 !important


// Metric names -- on cards
.metric-name

  @include media-breakpoint-down(md)
    font-size: $h6-font-size !important
    overflow: hidden
    text-overflow: ellipsis
    height: 33px

  @include media-breakpoint-up(lg)
    overflow: visible
    min-height: 21px
    max-height: 42px

.metric-value
  font-weight: 400
  @include media-breakpoint-down(lg)
    font-size: $h4-font-size

  @include media-breakpoint-up(xl)
    font-size: $h2-font-size

.cause-card-icon

  @include media-breakpoint-down(md)
    font-size: $h5-font-size
    margin-top: 10px

  @include media-breakpoint-up(lg)
    font-size: 35px !important

