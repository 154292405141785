/*=========================================
 * App navbar global
 *=======================================*/
// Navbars for all non-public pages

body

  .nav-link.active
    background-color: $primary-light

  .navbar.nav-app.nav-top.nav-account, .navbar.nav-app.nav-sub
    background-color: $primary-light

  .navbar.nav-app.nav-top

    .custom-control-input:checked ~ .custom-control-label::before
      background-color: $primary

    @include media-breakpoint-up(lg)
      .navbar-nav 

        .nav-item 
          border-bottom: 4px solid transparent

          &.active
            border-bottom: 4px solid $primary

          &.nav-dropdown:hover
            border-bottom: 4px solid $primary

body[data-color-mode="legacy"]

  .nav-link.active
    background-color: $primary_light !important

body[data-color-mode="high_contrast"]

  .nav-link.active
    background-color: $primary_light !important

  .navbar.nav-app.nav-top.nav-account, .navbar.nav-app.nav-sub
    background-color: lighten($primary-light, 1%)

  .navbar.nav-app.nav-top

    .custom-control-input:checked ~ .custom-control-label::before
      background-color: $primary

    @include media-breakpoint-up(lg)
      .navbar-nav 

        .nav-item 
          border-bottom: 4px solid transparent

          &.active
            border-bottom: 4px solid $primary

          &.nav-dropdown:hover
            border-bottom: 4px solid $primary

body[data-color-mode="aca"]

  .nav-link.active
    background-color: $aca_blue_light

  .navbar.nav-app
    border-bottom: transparent
    border-bottom: 1px solid $aca_blue

  .navbar.nav-app.nav-top.nav-account
    background-color: $aca_blue_x_light

  .navbar.nav-app.nav-sub
    border-top: 1px solid $gray-300
    border-bottom: 1px solid $gray-300
    background-color: lighten($aca_blue, 52%)

  .navbar.nav-app.nav-top

    .custom-control-input:checked ~ .custom-control-label::before
      background-color: $aca_blue

    @include media-breakpoint-up(lg)
      .navbar-nav 

        .nav-item 
          border-bottom: 4px solid transparent

          &.active
            border-bottom: 4px solid $aca_blue

          &.nav-dropdown:hover
            border-bottom: 4px solid $aca_blue


body[data-color-mode="black"]

  .nav-link.active
    background-color: $white
    color: $black

  .navbar.nav-app
    border-bottom: 1px solid $black

  .navbar.nav-app.nav-top.nav-account
    background-color: $black
    color: $white

  .navbar.nav-app.nav-sub
    border-top: 1px solid $black_gray3
    border-bottom: 1px solid $black_gray3
    background-color: $black_gray1
    color: $black

    a
      color: $black !important

  .navbar.nav-app.nav-top

    .custom-control-input:checked ~ .custom-control-label::before
      background-color: $black
      color: $white

    @include media-breakpoint-up(lg)
      .navbar-nav 

        .nav-item 
          border-bottom: 4px solid transparent

          &.active
            border-bottom: 4px solid $black

          &.nav-dropdown:hover
            border-bottom: 4px solid $black



body[data-color-mode="guide"]

  .navbar.nav-app
    border-bottom: transparent
    border-bottom: 1px solid $guide_green

  .navbar.nav-app.nav-top.nav-account
    background-color: $guide_green_light

  .navbar.nav-app.nav-sub
    border-top: 1px solid $gray-300
    border-bottom: 1px solid $gray-300
    background-color: lighten($guide_green, 52%)

  .navbar.nav-app.nav-top

    .custom-control-input:checked ~ .custom-control-label::before
      background-color: $guide_green

    @include media-breakpoint-up(lg)
      .navbar-nav 

        .nav-item 
          border-bottom: 4px solid transparent

          &.active
            border-bottom: 4px solid $guide_green

          &.nav-dropdown:hover
            border-bottom: 4px solid $guide_green


.navbar.nav-app
  border-bottom: transparent
  border-bottom: 1px solid $gray-300

  .accounts-nav-logo
    @include media-breakpoint-down(md)
      width: 50px
      min-width: 50px
    @include media-breakpoint-up(lg)
      width: 250px
      min-width: 250px

  #navbarSearchSm
    @include media-breakpoint-down(md)

      .input-group-prepend
        margin-left: 10px
        cursor: pointer

        .input-group-text
          background-color: $primary-light !important

      input
        width: 0px !important
        min-width: 0px !important

  &:not(.nav-sub)
    height: $app-navbar-height
  &.nav-sub
    height: 50px

  // Dark or light purple, depending on whether signed in with account
  &.nav-top.nav-account

    .navbar-nav.icons-nav .nav-link
      color: inherit !important


  &.nav-top:not(.nav-account)
    background-color: $primary

    .navbar-nav.icons-nav .nav-link
      color: $white !important


  &.nav-top
    z-index: 1041 // More than bootstrap's 'sticky-top' class, same as modal, same as position-absolute banner on dashboards

    // Toggle
    .custom-control-label
      vertical-align: middle

    .custom-control.custom-switch
      white-space: nowrap
      width: 134px
      height: 70px

    .custom-switch .custom-control-label::after
      top: -5px !important
      width: 15px !important
      height: 14px !important
      left: 6px !important

    .custom-control-label::before
      width: 33px !important
      height: 18px !important
      top: -7px !important
      left: 3px

    .navbar-nav.icons-nav
      display: flex !important
      flex-direction: row !important

      .nav-link
        padding-left: 1.25rem
        padding-right: 1.25rem

        i, svg
          font-size: 18px !important

    .navbar-nav

      .nav-item

        // Make nav links 100% line height of the navbar
        .nav-link
          line-height: $app-navbar-height
          padding-top: 0px
          padding-bottom: 0px
          margin-bottom: -5px
          font-size: 18px


    @include media-breakpoint-down(md)

      .navbar-collapse
        top: $app-navbar-height
        left: 0
        width: 100%
        background-color: $primary-light
        border-bottom: 1px solid $gray-400
        position: absolute
        max-height: 350px
        overflow: auto
        margin: 0 auto

        @include media-breakpoint-down(sm)
          padding: 0 20px

        @include media-breakpoint-up(md)
          padding: 0 30px

        &.show
          display: block

        ul
          background-color: $primary-light

        .navbar-nav
          .nav-item

            .nav-link
              padding: 10px 0px

            &:first-child .nav-link
              padding-top: 20px !important

            &:last-child .nav-link
              padding-bottom: 20px !important

  &.nav-sub
    transition: 0.3s all ease
    z-index: 49

    &.sidebar-offset.minimized
      width: 100% !important

    &.sidebar-offset:not(.minimized)

      @include media-breakpoint-down(sm)
        width: calc(100% - 70px) !important

      @include media-breakpoint-up(lg)
        width: calc(100% - 250px) !important

    .navbar-title
      margin-right: 0px !important
      padding-right: 20px
      white-space: nowrap
      display: flex
      align-items: center !important
      font-weight: 300
      transition: all 0.3s ease
      opacity: 1

      &.opaque
        opacity: 0
        margin-left: 0px
        padding-right: 0px
        width: 0px

    .nav-item
      white-space: nowrap

      @include media-breakpoint-down(xs)
        .nav-link
          padding-left: 0.95rem
          padding-right: 0.95rem

      &:first-child .nav-link
        padding-left: 0px !important

      &:not(.active) .nav-link, .nav-link:not(.active)
        font-weight: 300