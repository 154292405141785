body[data-page="PublicLanding"]

  .legal-content

    p, li
      font-size: $h5-font-size
      font-weight: 300

    li
      margin-bottom: 10px

    table

      th
        vertical-align: bottom !important

      th, td
        white-space: initial !important

      th:first-child, td:first-child
        width: 55%

      th:not(:first-child), td:not(:first-child)
        width: 15% !important