body[data-page="PublicAbout"]

  .public-section.about-top
    display: flex
    background-color: $primary-x-light
    margin-top: 100px
    min-height: 60vh

  .public-section#team
    padding-top: 100px !important
    padding-bottom: 260px !important

  // Values containers
  .public-section#values
    padding-top: 40px !important
    padding-bottom: 80px

    .values-list
      margin-bottom: 15px

      .values-item
        overflow: hidden
        margin-top: 15px

        .values-title
          font-size: $h5-font-size
          font-weight: 600
          display: flex !important
          align-items: center !important
          cursor: pointer !important
          margin-bottom: 10px

        .values-statement

          @include media-breakpoint-down(md)

            .collapse-item
              margin-top: 15px

          @include media-breakpoint-up(lg)
            display: flex !important
            align-items: flex-start !important

        &.minimized

          .values-statement
            .less
              display: none !important
            .more
              display: flex !important
              align-items: center !important

          .values-details
            max-height: 0px

        &:not(.minimized)

          .values-statement
            margin-bottom: 15px

            .more
              display: none !important
            .less
              display: flex !important
              align-items: center !important

          .values-details
            max-height: 300px
