body[data-page="QuestionnairesResponsesAnswersIndex"]

  .dropdown

    .dropdown-menu
      border: 1px solid $gray-500
      width: 400px
      min-width: 400px

    input
      height: 41.5px

  .remove-name-short
    width: 95%
    max-width: 200px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

