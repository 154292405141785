.breadcrumbs
  margin-right: auto
  display: flex !important
  align-items: center !important
  font-size: $h5-font-size

  &.account-breadcrumbs
    white-space: nowrap
    margin-right: 30px !important

    .crumb-arrow, .crumb
      color: $gray-700 !important

  &:not(.account-breadcrumbs)
    font-weight: 300

    .crumb-arrow, .crumb
      color: lighten($gray-700, 15%) !important

  .crumb
    
    &:first-child
      padding: 0px 10px 0px 0px

    &:not(:first-child)
      text-align: center
      overflow: hidden
      white-space: nowrap
      padding: 0px 10px

    &:hover
      color: $primary !important