.card.featured-item-card
	height: 115px
	min-height: 115px
	margin-bottom: 1rem

	.card-body
		display: flex !important

		.card-logo
			display: flex !important
			justify-content: center !important
			margin-right: 10px
			padding-top: 5px
			width: 50px
			min-width: 50px
			height: 50px
			min-height: 50px

			i, svg
				font-size: 38px

		.card-content
			padding-left: 10px

		.card-tag
			background-color: $gray-100
			border: 1px solid $gray-200
			border-radius: 5px
			padding: 5px 10px
			font-size: 12px

	&:hover
		background-color: $gray-100
