// Graphic showing the percentage complete (e.g., of a questionnaire)

.percent-complete-container
  height: 135px
  display: flex !important
  justify-content: center !important
  position: relative !important
  width: 100%
  flex-direction: column !important

  .percent-complete.progress
    display: block
    position: relative
    overflow: visible
    border-radius: 15px
    background-color: $gray-300

  .stem.progress-bar
    height: 70px

  .bulb
    width: 100px
    height: 100px
    border-radius: 50%
    position: absolute
    left: -11px
    bottom: -16px
    box-shadow: 0px 0px 0px 2px #fff inset

    i, svg
      height: 50px !important
      width: 50px !important

  .marker
    height: 70px
    position: absolute
    top: 0px
    display: flex !important
    align-items: flex-end !important    

    &:first-child .marker-label
      @include media-breakpoint-up(md)
        margin-bottom: -37px
        margin-left: -49px
      @include media-breakpoint-down(sm)
        margin-bottom: -40px
        margin-left: -28px

    &:last-child
      .marker-label
        margin-bottom: -22px
        margin-left: -22px

    &:not(:first-child):not(:last-child)
      background-color: $white
      width: 3px

      .marker-label
        margin-bottom: -22px
        margin-left: -16px

