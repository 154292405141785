@import 'charts/bar-container'
@import 'charts/carbon-footprint'
@import 'charts/carbon-underground'
@import 'charts/container'
@import 'charts/c3'
@import 'charts/gauge'
@import 'charts/nav'
@import 'charts/characteristics-chart'
@import 'charts/pie'
@import 'charts/tooltips'

// Chart sizes
.chart

  &.chart-lg
    height: 340px

  &.chart-md
    height: 290px

  &.chart-sm-md
    height: 240px

  &.chart-sm
    height: 190px

  &.chart-xs
    height: 140px

  &.chart-xxs
    height: 100px   

  &.opaque
    opacity: 0.2 

