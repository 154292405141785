// Basic coin with a fa-icon
// Used on public pages

.coin    
  border-radius: 100%
  border: 1px dashed $primary-light
  margin: auto
  width: 40px
  height: 40px
  font-size: 1.25rem !important
  color: $primary-light !important
  position: relative
  display: flex
  align-items: center !important
  justify-content: center !important
  z-index: 1 // Make sure stays above graphics container (below) on public page

  &:nth-child(2)
    margin-left: -10px

  &:nth-child(4)
    @include media-breakpoint-up(md)
      margin-left: -5px