.thermometer-container
  height: 135px
  display: flex !important
  justify-content: center !important
  position: relative !important
  width: 100%
  flex-direction: column !important

  .temperature-rise
    top: 0px
    right: 0px
    position: absolute

  .thermometer.progress
    display: block
    position: relative
    overflow: visible
    border-radius: 15px
    background-color: $gray-200
    padding-left: 60px
    padding-right: 40px

  .stem.progress-bar
    height: 70px
    border-top-left-radius: 15px
    border-bottom-left-radius: 15px

  .bulb
    width: 100px
    height: 100px
    border-radius: 50%
    position: absolute
    left: -11px
    bottom: -16px
    box-shadow: 0px 0px 0px 2px #fff inset

  .marker
    height: 70px
    position: absolute
    top: 0px
    display: flex !important
    align-items: flex-end !important    

    &:first-child .marker-label
      @include media-breakpoint-up(md)
        margin-bottom: -37px
        margin-left: -49px
      @include media-breakpoint-down(sm)
        margin-bottom: -40px
        margin-left: -28px

    &:not(:first-child)
      background-color: $white
      width: 3px

      .marker-label
        margin-bottom: -22px
        margin-left: -16px

