/*==============================================================
 * Color helpers for cause categoreis
 *==============================================================*/

.cause-text.faith_based_values
  color: $faith-based-values-color
.cause-colored.faith_based_values
  color: $faith-based-values-color
  &.opaque
    background-color: lighten($faith-based-values-bg, 10%)
  &:not(.opaque)
    background-color: $faith-based-values-bg

.cause-text.climate
  color: $climate-color
.cause-colored.climate
  color: $climate-color
  &.opaque
    background-color: lighten($climate-bg, 10%)
  &:not(.opaque)
    background-color: $climate-bg

.cause-text.life_on_earth
  color: $life-color
.cause-colored.life_on_earth
  color: $life-color
  &.opaque
    background-color: lighten($life-bg, 10%)
  &:not(.opaque)
    background-color: $life-bg

.cause-text.health
  color: $health-color
.cause-colored.health
  color: $health-color
  &.opaque
    background-color: lighten($health-bg, 10%)
  &:not(.opaque)
    background-color: $health-bg

.cause-text.justice
  color: $peace-color

.cause-colored.justice
  color: $peace-color
  &.opaque
    background-color: lighten($peace-bg, 10%)
  &:not(.opaque)
    background-color: $peace-bg

.cause-text.water
  color: $water-color
.cause-colored.water
  color: $water-color
  &.opaque
    background-color: lighten($water-bg, 10%)
  &:not(.opaque)
    background-color: $water-bg

.cause-text.inclusive_economies
  color: $economy-color
.cause-colored.inclusive_economies
  color: $economy-color
  &.opaque
    background-color: lighten($economy-bg, 10%)
  &:not(.opaque)
    background-color: $economy-bg

.cause-text.education
  color: $education-color
.cause-colored.education
  color: $education-color
  &.opaque
    background-color: lighten($education-bg, 10%)
  &:not(.opaque)
    background-color: $education-bg

.cause-text.gender_equality
  color: $gender-color
.cause-colored.gender_equality
  color: $gender-color
  &.opaque
    background-color: lighten($gender-bg, 10%)
  &:not(.opaque)
    background-color: $gender-bg

.cause-text.innovation
  color: $innovation-color
.cause-colored.innovation
  color: $innovation-color
  &.opaque
    background-color: lighten($innovation-bg, 10%)
  &:not(.opaque)
    background-color: $innovation-bg

.cause-text.sustainability
  color: $sustain-color
.cause-colored.sustainability
  color: $sustain-color
  &.opaque
    background-color: lighten($sustain-bg, 10%)
  &:not(.opaque)
    background-color: $sustain-bg
