/* Configuration of Bootstrap's baseline / override of Bootstrap defaults */
/* Color overrides in _colors.sass */

/*=========================================
 * Body overrides
 *=======================================*/

$body-bg: #fafaff
$body-color: $gray-700
$grid-gutter-width: 20px

/*=========================================
 * Typography settings
 *=======================================*/

$font-size-base: 0.875rem // Base font size for app ~14px (based on typical browser default of 16px)
$font-family-sans-serif: 'Source Sans Pro', sans-serif
$font-family-guardian-sans: 'Guardian Sans', sans-serif

// $font-family-sans-serif: "Rubik", sans-serif // Base font family for app (imported from _fonts.sass)
// $font-family-sans-serif: 'Montserrat', sans-serif
// $font-family-sans-serif: 'Raleway', sans-serif
// $h1-font-size:                $font-size-base * 2.5 !default
// $h2-font-size:                $font-size-base * 2 !default
// $h3-font-size:                $font-size-base * 1.75 !default
// $h4-font-size:                $font-size-base * 1.5 !default
// $h5-font-size:                $font-size-base * 1.25 !default
// $h6-font-size:                $font-size-base !default
$font-weight-bold: 600 // Lighten bold font slightly (Bootstrap default is 700)
$headings-font-weight: 400 // Lighten heading font weight slightly (default is 500)
$headings-color: inherit // Default is null
$line-height-base: 1.25 // 1.5 is default


/*=========================================
 * Links
 *=======================================*/
// $link-color: $themecolor // Default
$link-hover-decoration: none

/*=========================================
 * Progress bars
 *=======================================*/
$progress-bg: $gray-300
$progress-height: auto // Auto-fit progress bar height


/*=========================================
 * Cards
 *=======================================*/

/* Remove border from cards */

$card-border-width: 1px
$card-border-color: $gray-200
$card-border-radius: 5px

/* Reduce padding on cards */

$card-spacer-x: 1.0rem

/*=========================================
 * Tables
 *=======================================*/

$table-bg-accent: $gray-100
$table-bg-hover: $primary-x-light
$table-hover-bg: #fafaff // $primary-x-light
$table-cell-padding: 0.25rem
$table-cell-padding-sm: 0.15rem
$table-head-color: $primary-light

/*=========================================
 * Forms
 *=======================================*/

$input-border-color: $gray-200
$input-btn-focus-box-shadow: transparent
$input-color: $black
$input-placeholder-color: $gray-600
$label-margin-bottom: .25rem

/*=========================================
 * Focus
 *=======================================*/

// Remove blue focus by default
*:focus
  outline: none !important

/*=========================================
 * Buttons
 *=======================================*/

// Inherit rather than match font size base
$input-btn-font-size: inherit

// No box shadow or outline on button focus
$input-btn-focus-box-shadow: none
$btn-focus-width: 0
$btn-focus-box-shadow: none


/*=========================================
 * Borders
 *=======================================*/

$border-width: 1px
$border-color: $gray-300

/*=========================================
 * Dropdowns
 *=======================================*/

/* Increase dropdown default padding */
$dropdown-item-padding-y: 0.75rem
$dropdown-item-padding-x: 1.0rem

/* Match overall link color for app */
$dropdown-link-color: $gray-700
$dropdown-link-hover-color: $primary

/* Make backdrops for dropdown hovers light purple */
/* Using 'primary-x-light' color var doesn't work, need to use hex here */
$dropdown-link-hover-bg: #efedff

/*=========================================
 * Spacers
 *=======================================*/

/* Add a larger spacer than 5 (default max) */

$spacer: 1rem
$spacers: (6: ($spacer * 5))

/*=========================================
 * Tooltips
 *=======================================*/

$tooltip-bg: $gray-700
$tooltip-font-size: 0.875rem !important
$tooltip-max-width: 300px !important // 200px default

/*=========================================
 * Toasts
 *=======================================*/

$toast-font-size: $font-size-base
$toast-header-background-color: #efedff
$toast-background-color: $white
$toast-box-shadow: 0 .25rem .75rem rgba($black, .3)


/*=========================================
 * Nav
 *=======================================*/

$navbar-nav-link-padding-x: 1.25rem
