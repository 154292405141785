body[data-page="ReportsShow"], body[data-page="ReportsImpactAssessmentsShow"]

  // Move sidebar closer to top
  .sticky-sidebar
    @include media-breakpoint-up(lg)
      top: 25px !important

body[data-controller-module="Reports"]

  .report-section
    padding: 15px 0px
    border-bottom: 1px dashed $gray-300

  .account-reports-footer
    position: fixed
    bottom: 0px
    left: 0px
    width: 100%
    z-index: 102

  #benchmarkingContainer
    .carbon-metric-score
      font-size: 100% !important

  .report-modules-content
    max-width: calc(100% - 355px)

  .controversy-row
    padding: 4px 10px !important

  #controversiesList .btn-group .btn.active
    background-color: $primary-light !important

  .card#researchResultsCard

    .logo-name-name
      max-width: 370px !important

    .logo-name-link
      max-width: 230px !important
      overflow: hidden
      text-overflow: ellipsis

    .cont-title
      // max-width: 500px !important
      overflow: hidden
      text-overflow: ellipsis

    .card-body
      min-height: 400px 


  #realWorldMetricsList

    .btn.active
      background-color: $primary-light

  .questionnaire-module .card-list-items
    min-height: 400px

  .questionnaire-module-report-table
    position: relative
    border-collapse: collapse

    &.sum-table 

      tr:first-child td
        border-top: 0px !important

      td
        background-color: $primary-light !important

    tr
      cursor: default !important

    td, th
      padding-right: 5px

      &:last-child
        text-align: right !important
        width: 100% !important
        min-width: 150px !important
        padding-right: 1rem !important

      &:first-child
        width: 250px !important
        min-width: 250px !important
        padding-left: 1rem !important
        text-align: left !important

    td
      height: 30px !important

    th
      background-color: $primary-light !important
      height: 35px !important
      position: sticky
      top: 0
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4)


  .sdgs-contribution-chart

    .btn-group .btn.active
      background-color: $primary-light !important

    .pie-chart-container
      min-height: 200px !important
      height: inherit !important

    .pie-chart-label.opaque
      color: $gray-400

      span.pie-chart-label-box
        opacity: 0.2

    .pie-chart-label.bolded
      font-weight: 600 !important

    .c3-legend-item text
      font-size: $h6-font-size !important
      color: $gray-700 !important


  // Risk drivers
  #riskDriversList .card
    min-height: 400px

  // Featured metrics
  .featured-metric-top
    font-size: $h5-font-size

  .featured-metric-score
    font-size: $h2-font-size

  .featured-metric-score-sm
    @include media-breakpoint-down(md)
      font-size: $h4-font-size
    @include media-breakpoint-up(lg)
      font-size: $h3-font-size

  .featured-metric-uom
    font-size: $h4-font-size

  .featured-metric-uom-sm
    @include media-breakpoint-down(sm)
      font-size: $h6-font-size
    @include media-breakpoint-up(md)
      font-size: $h5-font-size

  // Assessment visuals proposed
  .assessment-vis-proposed 

    .subcategory-title
      line-height: 1

    .subcategory-title, .category-title
      @include media-breakpoint-down(md)
        font-size: $h6-font-size
      @include media-breakpoint-up(lg)
        font-size: $h5-font-size

    .category-row
      padding-bottom: 3px

      .card
        height: 75px
        border-bottom-left-radius: 0px
        border-bottom-right-radius: 0px

        .bg-icon
          position: absolute
          top: 0
          left: 0
          height: 100%
          width: 100%
          display: flex !important
          align-items: center !important
          justify-content: center !important
          opacity: 0.1

          i, svg
            font-size: 70px

    .subcategory-row
      margin-top: -1px !important
      padding-top: 3px
      padding-bottom: 3px

      &:not(:last-child) 

        .col-4:first-child, .col-3:first-child, .col-20:first-child
          border-bottom: 1px dashed $gray-300

        .card
          border-radius: 0px

      &:last-child .card
        border-top-right-radius: 0px
        border-top-left-radius: 0px

      .card
        height: 40px

        .bg-icon
          position: absolute
          top: 0
          left: 0
          height: 100%
          width: 100%
          display: flex !important
          align-items: center !important
          justify-content: center !important
          opacity: 0.15

          i, svg
            font-size: $h4-font-size


  .assessment-sdgs-proposed 

    .sdgs-title
      @include media-breakpoint-down(md)
        font-size: $h6-font-size
      @include media-breakpoint-up(lg)
        font-size: $h5-font-size

    .card:not(.t-card):hover
      opacity: 1 !important

    .sdg-number
      @include media-breakpoint-down(sm)
        width: 25px
      @include media-breakpoint-up(md)
        width: 35px


  .assessment-sdgs
    .card:hover
      opacity: 1 !important

  #sdgs_table 
    th:first-child, td:first-child
      width: 35px !important
      padding-right: 0px !important

    th:nth-child(2), td:nth-child(2)
      text-align: left !important
      padding-left: 20px !important

.account-reports-about-footer
  padding: 40px 0px 100px
  background-color: $gray-800
  color: $gray-200

  a, .gray-fixed
    color: $gray-500 !important
