// Theme customization for tables
body[data-color-mode="black"]

  .table

    tbody

      tr:hover
        background-color: $white
        color: $black

