body[data-page="ResearchFundsScreensIndex"]

  .holding-screen-details

    .screen-details
      display: none
      border-left: 1px solid $gray-300
      border-right: 1px solid $gray-300
      overflow: auto

    .screen-title
      font-weight: 300

    &.expanded

      .screen-details
        display: block !important
        border-bottom: 1px solid $gray-300

      .screen-title
        font-weight: 400 !important
        background-color: $primary-light
        border-left: 1px solid $gray-300
        border-right: 1px solid $gray-300
        padding-left: 5px
        padding-right: 5px

      .screen-title
        border-bottom: 1px solid $gray-300

    &:not(:last-child) .screen-title
      border-bottom: 1px solid $gray-300


  table
    tr.expanded
      background-color: $primary-light

      .cell-text
        font-weight: 400 !important

    td
      height: auto !important
      min-height: 40px
      vertical-align: top !important
      padding-right: 0px !important

    td:first-child, th:first-child
      @include media-breakpoint-up(md)
        width: 200px !important
      @include media-breakpoint-down(sm)
        width: 100px !important

    td:nth-child(2), th:nth-child(2)
      text-align: left !important
      width: 100%
      min-width: 200px

  .screens-info
    margin-top: 5px
    white-space: pre-wrap
    display: unset

