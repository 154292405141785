.carbon-footprint-bar-container, .carbon-footprint-label-container

  .footprint-label
    height: 25px !important
    min-height: 25px !important

  .footprint-score
    font-size: 80%

.carbon-footprint-bar-container

  .footprint-chart-bar
    opacity: 0.25

// Carbon footprint bar chart
#portfolioCarbonFootprintChart

  .c3-bars-spacers .c3-bar
    fill: transparent !important
    stroke: transparent !important
    opacity: 0 !important

  .c3-bars-emissions .c3-bar
    fill: #aea6df !important
    stroke: #aea6df !important
    opacity: 0.25 !important

  .c3-text, text
    fill: $gray-600 !important
    color: $gray-600 !important

