.sustain-icons
  width: 100%
  margin-bottom: 0px
  justify-content: center !important
  align-items: flex-end !important
  font-size: 1.5rem
  overflow-x: hidden
  position: absolute
  bottom: 0px
  height: 200px
  overflow: hidden !important

  .trees-container
    position: relative
