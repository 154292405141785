body[data-page="PublicProduct"]

  // Overflow hidden for large wind turbine icon
  .container-fluid
    overflow: hidden

  .product-top
    display: flex
    margin-top: 100px
    padding-bottom: 0px !important
    min-height: 65vh

  .product-details-section
    padding-top: 100px !important
    padding-bottom: 100px !important
