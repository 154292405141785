.page-title
  padding: 10px 0px


.page-title-section
  display: flex
  align-items: flex-start
  width: 100%
  margin-top: 1.75rem

  @include media-breakpoint-down(md)
    margin-bottom: 1rem
  @include media-breakpoint-up(lg)
    margin-bottom: 1.75rem

  i.title-fa, svg.title-fa
    align-self: center // Don't make important over-ride on cause profile pages
    color: $primary
    margin-right: 1rem
    @include media-breakpoint-down(md)
      display: none
    @include media-breakpoint-up(lg)
      display: block

  .page-title-container
    padding-right: 10px

    .page-title
      line-height: 1
      font-weight: 600
      width: 100%
      @include media-breakpoint-down(xs)
        font-size: 6.5vw
      @include media-breakpoint-between(sm, md)
        font-size: 4.5vw
      @include media-breakpoint-up(md)
        font-size: $h1-font-size

    .page-subtitle
      padding-top: 5px

    .page-subtitle, .page-subtitle a
      display: inline-block !important
      font-size: $h4-font-size
      font-weight: 300

      // Make content within page subtitles inline
      span, i, svg
        display: inline-block !important

  .chart-total-indicator
    font-weight: 300
    font-size: $h2-font-size

// Chart title and indicator (used across cards, page titles)
.chart-title-section
  width: 100%

.chart-total-indicator, .card-total-indicator
  white-space: nowrap
  margin-left: auto

  // Hide change total on mobile screen size
  @include media-breakpoint-down(xs)
    .change-div
      display: none !important      

