body[data-page="AccountsImpactCalculatorsPublic"]
  width: 100%
  display: flex !important
  justify-content: center !important

  // Adjust to narrower card image overlay
  .card-img-overlay
    padding: 0.75rem !important

  .card-footer
    border-top: 1px solid $gray-500
    position: relative !important
    padding: 10px

  .calculator-card
    overflow: hidden !important

    // Adjust height for small windows
    @include media-breakpoint-down(sm)
      width: 100vw !important

  // Don't display last example metric card on small devices
  @include media-breakpoint-down(xs)
    .example-metric-cards:last-child, .example-metric-cards:nth-child(3)
      display: none !important

  @include media-breakpoint-between(sm,md)
    .example-metric-cards:last-child
      display: none !important

  // Standard height/size for tile cards
  .calculator-tile-card
    height: 100px !important

  // Screens title size
  @include media-breakpoint-down(sm)
    .screens-percent-title
      font-size: $h5-font-size
    .screens-number-title
      font-size: $h5-font-size
    .screens-name
      font-size: 0.75rem

  @include media-breakpoint-up(md)
    .screens-percent-title
      font-size: $h5-font-size
    .screens-number-title
      font-size: $h4-font-size
    .screens-name
      font-size: $h6-font-size

  #hypothetical_investment_amount
    border-width: 1px 
    border-style: solid
    border-radius: 5px !important

  .card.humanized-score 
    font-size: $h6-font-size !important

    img.card-img
      height: 75px !important


body[data-page="AccountsImpactCalculatorsPublic"], body[data-page="AccountsImpactCalculatorsShow"]

  .assessment-results-visual-calc
    display: flex !important
    flex-direction: flex-column !important
    justify-content: center !important
    position: relative

    .impact-persona
      cursor: default !important
      font-weight: 600
      opacity: 0.8
      border-radius: 5px !important
      padding: 5px
      border: 1px solid $gray-300
      line-height: 1
      box-shadow: 0 .05rem .25rem rgba($black, .1)

      @include media-breakpoint-down(xs)
        font-size: 3vw
      @include media-breakpoint-up(sm)
        font-size: $h5-font-size

    .result-categories

      &.climate .category .result-container, 
      &.climate:hover .category .result-container, 
      &.climate:hover .cause .result-container, 
      .result.cause.climate .result-container
        background-color: $climate-bg
        color: $climate-color

      &.peace .category .result-container, 
      &.peace:hover .category .result-container, 
      &.peace:hover .cause .result-container, 
      .result.cause.peace .result-container
        background-color: $peace-bg
        color: $peace-color

      &.economy .category .result-container, 
      &.economy:hover .category .result-container, 
      &.economy:hover .cause .result-container, 
      .result.cause.economy .result-container
        background-color: $economy-bg
        color: $economy-color

      &.life .category .result-container, 
      &.life:hover .category .result-container, 
      &.life:hover .cause .result-container, 
      .result.cause.life .result-container
        background-color: $life-bg
        color: $life-color

      &.sustain .category .result-container, 
      &.sustain:hover .category .result-container, 
      &.sustain:hover .cause .result-container, 
      .result.cause.sustain .result-container
        background-color: $sustain-bg
        color: $sustain-color

      &.innovation .category .result-container, 
      &.innovation:hover .category .result-container, 
      &.innovation:hover .cause .result-container, 
      .result.cause.innovation .result-container
        background-color: $innovation-bg
        color: $innovation-color

      &.water .category .result-container, 
      &.water:hover .category .result-container, 
      &.water:hover .cause .result-container, 
      .result.cause.water .result-container
        background-color: $water-bg
        color: $water-color

      &.education .category .result-container, 
      &.education:hover .category .result-container, 
      &.education:hover .cause .result-container, 
      .result.cause.education .result-container
        background-color: $education-bg
        color: $education-color

      &.gender .category .result-container, 
      &.gender:hover .category .result-container, 
      &.gender:hover .cause .result-container, 
      .result.cause.gender .result-container
        background-color: $gender-bg
        color: $gender-color

      &.health .category .result-container, 
      &.health:hover .category .result-container, 
      &.health:hover .cause .result-container, 
      .result.cause.health .result-container
        background-color: $health-bg
        color: $health-color

    .result-categories:not(.active) .result:not(.active) .result-container:not(:hover), .result.cause:not(.active), .result-container.bg-no-rating
      box-shadow: 0 .05rem .25rem rgba($gray-800, .1)
      opacity: 0.21

    .result-categories:hover
      opacity: 1

      .result .result-container
        opacity: 1 !important

    .result
      position: absolute
      cursor: pointer
      display: flex !important
      align-items: center !important
      justify-content: center !important
      text-align: center
      overflow: hidden

      .result-container
        border-radius: 5px !important
        padding: 5px
        border: 1px solid $gray-200

      &.active .result-container, &:hover .result-container
        box-shadow: 0 .05rem .25rem rgba($black, .1)

      &.category.active, &.category:hover
        opacity: 1

      &.cause
        padding: 10px        

        // Only set hover opacity active opacity set in controller based on weight of cause
        &:hover
          opacity: 0.6

      // Background icon
      .bg-icon
        opacity: 0.13
        position: absolute
        font-size: 96px !important

  .assessment-results-visual-calc
    height: 430px
    width: 310px
    margin: 20px auto

    .result.impact-persona
      top: 180px
      height: 70px
      left: 90px
      width: 130px

    .result.category
      font-size: $h3-font-size

      .result-container
        height: 40px
        width: 50px

      &.climate
        top: 0px
        left: 130px

      &.life
        top: 50px
        left: 100px

      &.sustain
        top: 50px
        left: 160px

      &.water
        top: 110px
        left: 220px

      &.health
        top: 280px
        left: 220px

      &.gender
        top: 340px
        left: 160px

      &.education
        top: 340px
        left: 100px

      &.innovation
        top: 390px
        left: 130px

      &.economy
        top: 280px
        left: 40px

      &.peace
        top: 110px
        left: 30px

    .result.cause
      font-size: $h6-font-size

      .result-container
        height: 30px
        width: 30px

      &.climate:first-child
        top: 0px
        left: 80px

      &.climate:nth-child(2)
        top: 10px
        left: 40px

      &.climate:nth-child(3)
        top: 0px
        left: 180px

      &.life:first-child
        top: 90px
        left: 90px

      &.life:nth-child(2)
        top: 90px
        left: 130px

      &.life:nth-child(3)
        top: 130px
        left: 130px

      &.sustain:first-child
        top: 90px
        left: 170px

      &.sustain:nth-child(2)
        top: 10px
        left: 220px

      &.sustain:nth-child(3)
        top: 50px
        left: 210px

      &.water:first-child
        top: 130px
        left: 170px

      &.water:nth-child(2)
        top: 150px
        left: 220px

      &.water:nth-child(3)
        top: 150px
        left: 260px

      &.health:first-child
        top: 190px
        left: 220px

      &.health:nth-child(2)
        top: 190px
        left: 260px

      &.health:nth-child(3)
        top: 230px
        left: 220px

      &.health:nth-child(4)
        top: 230px
        left: 260px

      &.health:nth-child(5)
        top: 250px
        left: 170px

      &.gender:first-child
        top: 320px
        left: 210px

      &.gender:nth-child(2)
        top: 290px
        left: 170px

      &.gender:nth-child(3)
        top: 360px
        left: 220px

      &.education:first-child
        top: 290px
        left: 130px

      &.education:nth-child(2)
        top: 320px
        left: 50px

      &.education:nth-child(3)
        top: 250px
        left: 130px

      &.innovation:first-child
        top: 380px
        left: 80px

      &.innovation:nth-child(2)
        top: 380px
        left: 180px

      &.economy:first-child
        top: 250px
        left: 90px

      &.economy:nth-child(2)
        top: 230px
        left: 40px

      &.economy:nth-child(3)
        top: 230px
        left: 0px

      &.economy:nth-child(4)
        top: 190px
        left: 40px

      &.economy:nth-child(5)
        top: 190px
        left: 0px

      &.economy:nth-child(6)
        top: 290px
        left: 90px

      &.peace:first-child
        top: 150px
        left: 40px

      &.peace:nth-child(2)
        top: 130px
        left: 90px

      &.peace:nth-child(3)
        top: 50px
        left: 50px

      &.peace:nth-child(4)
        top: 150px
        left: 0px


  @include media-breakpoint-up(sm)

    .assessment-results-visual-calc.sm, .assessment-results-visual-calc.lg
      height: 400px
      width: 536px
      margin: 20px auto

      .result.impact-persona
        top: 160px
        height: 80px
        left: 190px
        width: 160px

      .result.category
        font-size: $h1-font-size

        .result-container
          height: 50px
          width: 70px

        &.climate
          top: 0px
          left: 190px

        &.life
          top: 0px
          left: 280px

        &.sustain
          top: 60px
          left: 350px

        &.water
          top: 160px
          left: 460px

        &.health
          top: 270px
          left: 350px

        &.gender
          top: 350px
          left: 280px

        &.education
          top: 350px
          left: 190px

        &.innovation
          top: 270px
          left: 120px

        &.economy
          top: 160px
          left: 10px

        &.peace
          top: 60px
          left: 120px

      .result.cause
        font-size: $h3-font-size

        .result-container
          height: 40px
          width: 40px

        &.climate:first-child
          top: 100px
          left: 190px

        &.climate:nth-child(2)
          top: 50px
          left: 240px

        &.climate:nth-child(3)
          top: 100px
          left: 240px

        &.life:first-child
          top: 50px
          left: 290px

        &.life:nth-child(2)
          top: 100px
          left: 290px

        &.life:nth-child(3)
          top: 0px
          left: 350px

        &.sustain:first-child
          top: 0px
          left: 400px

        &.sustain:nth-child(2)
          top: 60px
          left: 420px

        &.sustain:nth-child(3)
          top: 110px
          left: 350px

        &.water:first-child
          top: 110px
          left: 400px

        &.water:nth-child(2)
          top: 160px
          left: 350px

        &.water:nth-child(3)
          top: 160px
          left: 400px

        &.health:first-child
          top: 210px
          left: 350px

        &.health:nth-child(2)
          top: 210px
          left: 400px

        &.health:nth-child(3)
          top: 260px
          left: 420px

        &.health:nth-child(4)
          top: 320px
          left: 400px

        &.health:nth-child(5)
          top: 320px
          left: 350px

        &.gender:first-child
          top: 240px
          left: 290px

        &.gender:nth-child(2)
          top: 290px
          left: 290px

        &.gender:nth-child(3)
          top: 240px
          left: 240px

        &.education:first-child
          top: 290px
          left: 190px

        &.education:nth-child(2)
          top: 290px
          left: 240px

        &.education:nth-child(3)
          top: 320px
          left: 130px

        &.innovation:first-child
          top: 240px
          left: 190px

        &.innovation:nth-child(2)
          top: 260px
          left: 60px

        &.economy:first-child
          top: 110px
          left: 80px

        &.economy:nth-child(2)
          top: 210px
          left: 130px

        &.economy:nth-child(3)
          top: 210px
          left: 80px

        &.economy:nth-child(4)
          top: 160px
          left: 130px

        &.economy:nth-child(5)
          top: 160px
          left: 80px

        &.economy:nth-child(6)
          top: 110px
          left: 130px

        &.peace:first-child
          top: 50px
          left: 190px

        &.peace:nth-child(2)
          top: 60px
          left: 60px

        &.peace:nth-child(3)
          top: 0px
          left: 80px

        &.peace:nth-child(4)
          top: 0px
          left: 130px

  @include media-breakpoint-up(md)
 
    .assessment-results-visual-calc.lg
      height: 400px
      width: 800px
      margin: 0px auto 20px auto      

      .result.impact-persona
        top: 160px
        height: 80px
        left: 320px
        width: 160px

      .result.category
        font-size: $h1-font-size

        .result-container
          height: 60px
          width: 70px

        &.peace
          top: 50px
          left: 170px

        &.climate
          top: 40px
          left: 300px

        &.life
          top: 40px
          left: 430px

        &.sustain
          top: 50px
          left: 560px

        &.innovation
          top: 290px
          left: 170px

        &.water
          top: 170px
          left: 590px

        &.economy
          top: 170px
          left: 140px

        &.education
          top: 300px
          left: 300px

        &.gender
          top: 300px
          left: 430px

        &.health
          top: 290px
          left: 560px

      .result.cause
        font-size: $h3-font-size

        .result-container
          height: 40px
          width: 40px

        &.peace:first-child
          top: 60px
          left: 240px

        &.peace:nth-child(2)
          top: 110px
          left: 270px

        &.peace:nth-child(3)
          top: 110px
          left: 170px

        &.peace:nth-child(4)
          top: 110px
          left: 220px

        &.climate:first-child
          top: 100px
          left: 320px

        &.climate:nth-child(2)
          top: 100px
          left: 370px

        &.climate:nth-child(3)
          top: 50px
          left: 370px

        &.life:first-child
          top: 110px
          left: 470px

        &.life:nth-child(2)
          top: 60px
          left: 500px

        &.life:nth-child(3)
          top: 100px
          left: 420px

        &.sustain:first-child
          top: 110px
          left: 620px

        &.sustain:nth-child(2)
          top: 110px
          left: 520px

        &.sustain:nth-child(3)
          top: 110px
          left: 570px

        &.innovation:first-child
          top: 280px
          left: 240px

        &.innovation:nth-child(2)
          top: 230px
          left: 220px

        &.water:first-child
          top: 170px
          left: 480px

        &.water:nth-child(2)
          top: 170px
          left: 660px

        &.water:nth-child(3)
          top: 170px
          left: 530px

        &.economy:first-child
          top: 230px
          left: 120px

        &.economy:nth-child(2)
          top: 110px
          left: 120px

        &.economy:nth-child(3)
          top: 170px
          left: 210px

        &.economy:nth-child(4)
          top: 170px
          left: 80px

        &.economy:nth-child(5)
          top: 230px
          left: 170px

        &.economy:nth-child(6)
          top: 170px
          left: 260px

        &.education:first-child
          top: 240px
          left: 320px

        &.education:nth-child(2)
          top: 290px
          left: 370px

        &.education:nth-child(3)
          top: 230px
          left: 270px

        &.gender:first-child
          top: 240px
          left: 420px

        &.gender:nth-child(2)
          top: 230px
          left: 470px

        &.gender:nth-child(3)
          top: 240px
          left: 370px

        &.health:first-child
          top: 230px
          left: 620px

        &.health:nth-child(2)
          top: 280px
          left: 500px

        &.health:nth-child(3)
          top: 230px
          left: 520px

        &.health:nth-child(4)
          top: 230px
          left: 570px

        &.health:nth-child(5)
          top: 280px
          left: 630px

