.tooltip 
  // Required to avoid flickering of tooltips on hover, when container does 
  // not have room to display away from mouse
  pointer-events: none

  // Increase z-index of tooltips
  z-index: 100000000 !important

  font-size: 14px !important

.tooltip-inner
  font-size: 14px !important