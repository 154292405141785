.article-content
  margin-bottom: 50px

  .article 

    li
      margin-bottom: 0.75rem !important

    p, li, span
      font-size: $h5-font-size !important
      font-weight: 300 !important

    p
      margin-bottom: 25px

    h4
      margin-bottom: 25px
      color: darken($warning, 25%)
