// Checkbox
input.checkbox
  margin: revert !important

  &:after
    cursor: pointer !important
    width: 20px
    height: 20px
    top: -3px
    left: 0px
    position: relative
    background-color: $gray-100
    content: ''
    display: inline-block
    visibility: visible
    border: 1px solid $gray-400

  &:checked:after
    cursor: pointer !important
    width: 20px
    height: 20px
    top: -3px
    left: 0px
    position: relative
    background-color: $primary
    content: ''
    display: inline-block
    visibility: visible

input.checkbox.disabled:checked:after
  background-color: $gray-400 !important

// Checked disabled switches
.custom-control-input:disabled ~ .custom-control-label::before
  background-color: $gray-200 !important

body[data-color-mode="black"]

  .custom-control-input:checked ~ .custom-control-label::before
    background-color: $black !important
    border-color: $black !important    

  input.checkbox:not(.disabled):checked:after
    background-color: $black !important

body[data-color-mode="aca"]

  .custom-control-input:checked ~ .custom-control-label::before
    background-color: $aca_blue !important
    border-color: $aca_blue !important    

  input.checkbox:not(.disabled):checked:after
    background-color: $aca_blue !important


