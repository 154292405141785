/*=========================================
 * Datatables table actions
 *=======================================*/
/* Style for hover and other action items in datatables */

.dt-hover-outer
  position: absolute
  right: 15px

  .dt-hover-inner
    display: none
    z-index: 48
    border: 0px
    text-align: right
    min-width: 25px
    align-items: center
    justify-content: center
    min-height: 32px
    height: 100%
    font-size: $font-size-base

    .btn
      padding-top: 3px
      padding-bottom: 3px
      border: 1px solid $gray-200 !important

      &:not(:last-child)
        margin-right: 12px

    .btn-group
      margin-right: 12px

      .btn:not(:last-child)
        margin-right: 0px

// Only show on hover if not on mobile
@include media-breakpoint-up(sm)

  tr:hover

    .dt-hover-inner
      display: flex !important


.dt-hover-outer-fixed
  right: 15px
  position: absolute
  top: 0px

  .dt-hover-inner
    display: none
    z-index: 48
    border: 0px
    text-align: right
    min-width: 25px
    align-items: center
    justify-content: center
    height: 31.5px
    font-size: $font-size-base

    .btn
      padding-top: 3px
      padding-bottom: 3px
      border: 1px solid $gray-200 !important

      &:not(:last-child)
        margin-right: 12px

    .btn-group
      margin-right: 12px

      .btn:not(:last-child)
        margin-right: 0px

// Only show on hover if not on mobile
@include media-breakpoint-up(sm)

  tr:hover

    .dt-hover-inner
      display: flex !important

