.border-dark
  border: 1px solid $gray-500 !important

.border-darker
  border: 1px solid $gray-600 !important

.border-top-dark
  border-top: 1px solid $gray-500

.border-bottom-dark
  border-bottom: 1px solid $gray-500

.border-dashed-bottom-dark
  border-bottom: 1px dashed $gray-600

.border-right-dark
  border-right: 1px solid $gray-500

.border-dashed
  border-bottom: 1px dashed $gray-300

.border-dashed-top-dark
  border-top: 1px dashed $gray-600

.border-dashed-top
  border-top: 1px dashed $gray-300

.border-dashed-right
  border-right: 1px dashed $gray-300

.border-right-lg
  @include media-breakpoint-up(lg)
    border-right: 1px solid $gray-300

.border-dashed-dark-except-last:not(:last-child)
  border-bottom: 1px dashed $gray-400

.border-dark-except-last:not(:last-child)
  border-bottom: 1px solid $gray-400

.border-bottom-except-last:not(:last-child)
  border-bottom: 1px solid $gray-300

.border-dashed-except-last:not(:last-child)
  border-bottom: 1px dashed $gray-300

.border-right-except-last:not(:last-child)
  border-right: 1px solid $gray-300

.border-rounded
  border-radius: 5px