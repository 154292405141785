/*=========================================
 * Typeaheads
 *=======================================*/

// Global styling for typeaheads response (wrapped in tt-dataset class)

.twitter-typeahead
  display: flex
  width: 100%

// Expand typeahead after input group
.input-group .twitter-typeahead
  flex-basis: 0
  flex-grow: 1
  max-width: 100%

// Make input body color by default
.tt-menu
  width: 100%
  background: $white
  color: $body-color
  border: 0px
  overflow: auto
  box-shadow: 0 .25rem .75rem rgba($black, .1)
  border-top: 3px solid $primary
  max-height: 210px
  font-weight: 300

  .tt-suggestion, .empty-message
    width: 100%
    cursor: pointer
    padding: 5px 0px 5px 10px
    align-items: center !important
    border-bottom: 1px dashed $gray-300

    &:hover
      background-color: lighten($primary-light, 2%)

.tt-hint
  color: $gray-700

.tt-cursor
  background-color: lighten($primary-light, 2%)

.typeahead-container

  .typeahead-clear-input
    font-size: $h5-font-size
    position: absolute
    right: 20px
    top: 8px
    display: none
    cursor: pointer

  &:hover .typeahead-clear-input
    display: block !important

