/*=========================================
 * Datatables formatting
 *=======================================*/
body[data-color-mode="black"]

  .table.dataTable

    td, th
      background-color: $white !important
      color: $black !important

  .paginate_button

    a
      background-color: $white !important
      color: $black !important

    &.active a, &:hover:not(.disabled) a
      background: $cobalt_10 !important
      color: $black !important

body[data-color-mode="legacy"], body[data-color-mode="high_contrast"]

  .table.dataTable

    td, th
      background-color: $white !important
      color: inherit !important

body[data-color-mode="aca"], body[data-color-mode="guide"]

  .table.dataTable

    td, th
      background-color: $white !important


// Larger y-margins than default datatable css (6px) 
.table
  margin-top: 0px
  margin-bottom: 0px

  td, th
    background-color: $white
    max-width: none !important

  tr
    position: relative

  tr[data-read="false"]

    td, th
      background-color: lighten($danger, 30%) !important
      color: $black !important


.dataTables_scroll, , .dataTables_wrapper, .table-responsive
  height: calc(100% - 3px)

.dataTables_scrollBody
  min-height: 100px

// Filter dropdowns
.table-filter-dropdown .dropdown-menu 
  width: 300px
  font-size: $h6-font-size
  font-weight: 300

  input, .twitter-typeahead
    border-radius: 0px !important
    border-left: 0px !important
    border-right: 0px !important
    height: 42px !important

.dataTables_scrollHeadInner

  .fixed-table
    th
      border-bottom: 1px solid $gray-300 !important

.fixed-table
  tbody

    // Fix for firefox border-collapse https://stackoverflow.com/questions/7517127/borders-not-shown-in-firefox-with-border-collapse-on-table-position-relative-o
    td
      background-clip: padding-box

    tr:first-child td
      border-top: 0px !important

    tr
      cursor: default !important

// Fixed column widths
.card, .modal-content
  transition: 0s all ease

  &:not(.full-view) 

    .table-responsive
      padding-bottom: 58px

    .dataTables_paginate
      position: absolute

    .dataTables_length
      position: absolute

  &.full-view 

    .table-responsive
      min-height: calc(100vh - 150px)

    .dataTables_paginate
      position: fixed

    .dataTables_length
      position: fixed

    .card-footer
      position: fixed

  .card-footer
    z-index: 1
    bottom: 0px
    width: 100%
    height: 58px
    min-height: 58px
    background-color: darken($primary-x-light, 1%)

  .card-body, .modal-body

    // Show scrollbars
    ::-webkit-scrollbar
      -webkit-appearance: none
      height: 10px !important
      width: 10px !important

    ::-webkit-scrollbar-thumb
      border-radius: 4px
      background-color: rgba(0, 0, 0, .5)
      box-shadow: 0 0 1px rgba(255, 255, 255, .5)

    .table
      table-layout: fixed

      th:not(.text-right), td:not(.text-right)
        text-align: left

      th.text-right, td.text-right
        text-align: right !important

      th, td

        &:last-child
          padding-right: 10px !important

        &:last-child:not(.dataTables_empty)
          padding: 0px !important

        &:not(:first-child):not(:last-child)
          padding-left: 10px
          padding-right: 10px

        &.checkbox-cell
          width: 30px !important
          padding-right: 0px !important

    .dataTables_length, .dataTables_paginate
      font-weight: 300 !important
      font-size: $h6-font-size !important
      z-index: 2
      bottom: 12px

    .dataTables_paginate
      float: none !important
      right: 15px

    .dataTables_length
      left: 15px

      label
        font-weight: 300 !important
        margin-bottom: 0px !important
        @include media-breakpoint-down(xs)
          display: none !important
        @include media-breakpoint-up(sm)
          display: inline-flex !important
          align-items: center !important

      select
        margin-left: 6px
        margin-right: 6px
        padding: 0.25rem 1.75rem 0.25rem 0.5rem !important
        font-size: $h6-font-size !important
        font-weight: 300 !important

.dataTables_empty
  padding-top: 15px



.metrics-card

  .table-container
    padding-right: 1px // Required for border to be visible on selected rows



