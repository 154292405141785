// Card headers
body[data-color-mode="legacy"], body[data-color-mode="high_contrast"]

  .card 

    .card-header
      border-bottom: 1px solid $gray-200


body[data-color-mode="black"]

  .card 
    border-color: $black_gray3 !important

    .card-header
      background-color: $black_gray1
      color: $black
      border-bottom: 1px solid $black_gray3

    &:not(.t-card)
      color: $black

    .card-footer
      background-color: $black_gray1
      color: $black


body[data-color-mode="aca"]

  .card 

    .card-header
      background-color: lighten($aca_blue, 52%)
      border-bottom: 1px solid $gray-200

    .card-footer
      background-color: lighten($aca_blue, 52%)

body[data-color-mode="guide"]

  .card 

    .card-header
      background-color: lighten($guide_green, 52%)
      border-bottom: 1px solid $gray-200

    .card-footer
      background-color: lighten($guide_green, 52%)

.card-container
  width: 100%
  margin-bottom: 1.5rem
  display: flex !important
  min-height: calc(100vh - 200px)

  &:not(.sm)
    min-height: calc(100vh - 200px)

  &.md
    min-height: 550px

  &.sm
    min-height: 400px

.card
  transition: 0.3s all ease
  overflow: hidden

  .card-body

    &.opaque
      opacity: 0.3

    &.minimized
      height: 0px
      min-height: 0px

    li
      margin-bottom: 0.5rem

  // Unread/read
  &.read
    font-weight: 400 !important

    h3, h4, h5, h6
      font-weight: 400 !important

    img.card-img
      opacity: 0.08 !important

  &.unread
    font-weight: 600 !important
    filter: brightness(92%)

    h3, h4, h5, h6
      font-weight: 600 !important

  // Dashboard card
  &.dashboard-card
    overflow: hidden !important

    &.sm
      height: 80px

    &:not(.sm)
      height: 100px

    .tiers-badge
      font-size: 12px

    img.card-img
      height: 125px
      object-fit: cover

    &:hover
      filter: brightness(95%)

  .card-title
    line-height: 1.1
    font-size: $h3-font-size
    margin-bottom: 0px
    padding-bottom: 0px
    width: 100%

    &.sm
      font-size: $h4-font-size !important

    // Adjust font size on xs devices to keep within card div
    @include media-breakpoint-down(xs)
      font-size: 5.5vw

    &:not(.light)
      font-weight: 600

    &.light
      font-weight: 300

  .card-subtitle
    font-size: $h5-font-size
    font-weight: 300
    margin-bottom: 1rem
    margin-top: 0px !important

  .card-title, .card-subtitle
    i:not(.display-all), svg:not(.display-all)
      margin-right: 5px
      @include media-breakpoint-down(xs)
        display: none
      @include media-breakpoint-up(sm)
        display: inline-block 

  .chart-title-section
    display: flex !important
    align-items: flex-start !important
    width: 100%

    // Chart total indicator is updated through JS with chart
    // Card total indicator is not updated through JS (if we want to keep text static)
    .chart-total-indicator, .card-total-indicator
      font-weight: 300
      font-size: $h3-font-size
      padding-left: 10px
      text-align: right
      margin-top: -2px

  .dashboard-to-do-fa
    position: absolute
    opacity: 0.05
    font-size: 146px

  // Transparent card
  &.t-card
    box-shadow: none
    background-color: transparent
    border: 0px !important
    overflow: visible !important

    .card-body
      padding-left: 0px !important
      padding-right: 0px !important
      padding-top: 0px !important

  &:not(.t-card)
    background-color: $white
    box-shadow: $shadow
    border: 1px solid $gray-300

    .card-body

      // Reduce top padding on xs devices
      @include media-breakpoint-down(xs)
        padding-top: 0.7rem

    .card-footer
      background-color: darken($primary-x-light, 1%)

  // Screen cards
  &.portfolio-screen-card
    min-height: 125px
    overflow: hidden

  // Risk category cards
  &.risk-category-card
    min-height: 140px
    overflow: hidden

    .card-footer
      border-top: 1px solid $gray-400 !important

  // Full-view class (used in admin)
  &.full-view
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 10000

  // Excerpt card (shorter with link to expand)
  &.excerptable
    
    &:not(.expanded)
      overflow: hidden

      .summary-text .excerpted
        height: 65px
        overflow: hidden
        position: relative

      .expand-excerpt
        margin-top: -1px

  // Minimized
  &.minimized
    max-height: 60px
    overflow: hidden

  &:not(.minimized)
    max-height: none
    overflow: auto

  .card-header
    width: 100%
    display: flex 
    align-items: center 
    background-color: darken($primary-x-light, 1%)
    border-bottom: 1px solid $gray-200

    .card-title
      font-size: $h3-font-size
      font-weight: 600

    .collapse-item
      width: 100%

  // Humanized scores
  &.humanized-score
    img.card-img
      height: 150px
      opacity: 0.1
      object-fit: cover

    &.sm img.card-img
      height: 100px !important

    &:hover
      filter: brightness(90%)

  // Featured update
  &.featured-update
    overflow: hidden

    img.card-img
      height: 100px
      opacity: 0.15
      object-fit: cover

    &.sm img.card-img
      height: 100px !important

    &:hover
      filter: brightness(90%)

  // Risk cards
  &.environment-risk
    background-color: lighten($climate-bg, 15%)

  &.social-capital
    background-color: lighten($economy-bg, 15%)

  &.human-capital
    background-color: lighten($water-bg, 15%)

  &.business-model
    background-color: lighten($innovation-bg, 12%)

  &.leadership
    background-color: lighten($peace-bg, 20%)

  &.active-risk
    border: 2px dashed $danger
  &.inactive-risk
    opacity: 0.7

    .card-body
      background-color: $gray-100 !important


// Search input at top right 
.card-table-search
  @include media-breakpoint-down(md)
    min-width: 120px
  @include media-breakpoint-up(lg)
    min-width: 250px

// Carbon comparison
#portfolioCarbonComparisonMetrics

  h6 .border-bottom.position-relative
    min-height: 290px


