.navbar

  .top-nav-dropdown

    a
      cursor: pointer

      .badge.notifications-count
        background-color: darken($danger, 6%)
        color: $white
        position: absolute
        top: 16px
        right: 6px
        font-size: 12px

    &.show .dropdown-menu
      display: block

    &:not(.show) .dropdown-menu
      display: none

    // Set dropdown to position relative so menu not set relative to it
    @include media-breakpoint-down(sm)
      .dropdown
        position: inherit !important

    .dropdown-menu
      min-height: 90px
      overflow: auto
      padding: 0px !important

      &.flex-width
        margin-top: -1px !important
        border-top: 3px solid $primary

      @include media-breakpoint-down(sm)
        width: 90vw
        margin-left: 15px
        margin-right: 15px

      @include media-breakpoint-up(md)
        width: 500px
        
        &.narrower
          width: 350px !important

        &.flex-width
          width: inherit !important

      .dropdown-header
        padding: 10px
        font-weight: 400
        font-size: $h6-font-size
        background-color: darken($primary-x-light, 1%)
        border-bottom: 1px solid $gray-300

        i, svg
          font-size: 4px !important
          margin-top: -2px

      .dropdown-footer
        width: 100%
        padding: 10px
        font-weight: 400
        font-size: $h6-font-size
        background-color: darken($primary-x-light, 1%)
        border-top: 1px solid $gray-300
        display: flex !important
        justify-content: center !important
        align-items: center !important

        i, svg
          font-size: 4px !important
          margin-top: -2px

      .dropdown-items
        overflow: auto
        max-height: 424px        

        .dropdown-item
          font-weight: 300
          min-height: 58px
          padding: 10px

          &:not(.d-lg-none)
            display: flex !important

          &.d-lg-none
            @include media-breakpoint-down(md)
              display: flex !important
            @include media-breakpoint-up(lg)
              display: none !important

          &.unread
            background-color: $primary-light

          &.read
            background-color: $primary-x-light

          &.border-dashed:not(:last-child)
            border-bottom: 1px dashed $gray-300

          &.border-dashed:last-child
            border-bottom: none !important

          &.active, &:hover
            color: inherit !important
            background-color: lighten($primary-light, 2%) !important

          .notification-image-container
            width: 85px
            padding-right: 10px

            img
              max-width: 50px
              max-height: 42px

          .notification-text
            width: 100%

            .text
              white-space: normal

            i, svg
              font-size: 15px !important

          .notification-actions
            width: 75px
            padding-right: 10px
            flex-direction: column !important
            align-items: flex-end !important
            display: flex !important
            opacity: 0

            &:hover
              opacity: 1

            i, svg
              font-size: 12px !important
