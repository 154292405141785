.report-opt-card
  position: relative
  margin-bottom: 0.5rem

  &:hover
    background-color: $primary-light

  &.locked-card:not(:hover)
    background-color: $gray-100
    opacity: 0.8


#newReportModal

  .modal-dialog
    margin: 1rem auto

  .modal-content
    height: 95vh

    .modal-footer
      height: 64px

    .modal-header
      height: 62px

    .modal-body
      overflow: auto

      .report-type-opt.active, .report-type-opt:hover
        background-color: darken($primary-x-light, 1%)

#new_report_form
  font-size: $h5-font-size

  .form-check:not(:last-child)
    border-bottom: 1px dashed $gray-300

  .twitter-typeahead

    .tt-menu .tt-suggestion
      padding: 8px 0px 8px 10px

      &:not(:last-child)
        border-bottom: 1px dashed $gray-200

      &:hover
        background-color: lighten($primary-light, 2%)


  .filter-search, .company-data-typeahead, .asset-profile-select
    cursor: pointer
