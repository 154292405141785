.sidebar-title-info
  transition: 0.3s height ease

  &.min-height
    height: 0px !important
    border: 0px !important
    box-shadow: none !important

// Adjust padding for sidebar list
.sidebar-content

  ul
    font-size: 1rem !important

  ul.featured-list
    margin-left: 0px !important
    margin-right: 0px !important
    margin-bottom: 0px

    li a.disabled
      color: $gray-500 !important

    li:first-child
      padding: 7px 15px 3px 15px !important
      border-bottom: 1px solid $gray-400

      &:hover
        background-color: inherit !important

    li:last-child
      padding: 3px 15px 7px 15px !important

    li:not(:first-child):not(:last-child)
      padding: 3px 15px !important
      border-bottom: 1px dashed $gray-400

