body[data-page="ApiPublicDocs"], body[data-page="ApiDocsIndex"], body[data-page="ApiWebhooksIndex"]

  .scrollable-card
    height: 600px

  .request-preview, .response-preview
    overflow: auto
    
    .pl-a
      padding-left: 10px
    .pl-b
      padding-left: 20px
    .pl-c
      padding-left: 30px
    .pl-d
      padding-left: 40px
    .pl-e
      padding-left: 50px
    .pl-f
      padding-left: 60px
    .pl-g
      padding-left: 70px

    li
      margin-bottom: 5px
      white-space: nowrap

      &::marker
        color: $gray-500 !important

      .blue
        color: #5cc8ea
      .red 
        color: #db93a0
      .green
        color: #a6f8d5
      .yellow
        color: #cebf5a


