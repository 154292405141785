// In page title
.social-share-page-title
  font-size: 16px

  span, a
    color: $gray-700 !important
    margin-right: 3px
  
    &:hover
      color: $primary !important

  i, svg
    font-size: 16px

.fb-share
  color: #3b5998

.twitter-share
  color: #1da1f2

.linked-in-share
  color: #0076b5


.social-share-container
  padding: 25px 0px
  display: flex !important

  .btn
    display: flex !important
    align-items: center !important
    justify-content: center !important

    &.fb-share
      background-color: #3b5998
      color: #fff

    &.twitter-share
      background-color: #1da1f2
      color: #fff

    &.linked-in-share
      background-color: #0076b5
      color: #fff

    &.email-share
      background-color: $gray-500
      color: #fff

    &.primary-share
      background-color: transparent
      color: $primary
      
      i, svg
        opacity: 0.8

        &:hover
          opacity: 1


  @include media-breakpoint-down(sm)
    .btn:not(:last-child)
      margin-right: 3px

  @include media-breakpoint-up(md)
    position: fixed
    right: 0px
    top: 200px
    z-index: 53
    flex-direction: column !important
    align-items: flex-end !important

    .btn
      width: 50px
      height: 50px
      border: 0px !important
      border-radius: 0px !important
      box-shadow: none !important
      transition: 0.3s all ease
      padding: 0px !important

      &:hover
        width: 75px
