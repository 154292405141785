body[data-page="QuestionnairesResponsesApprovalRequestsIndex"]

  .dropdown

    .dropdown-menu
      border: 1px solid $gray-500
      width: 400px
      min-width: 400px

    input
      height: 41.5px
