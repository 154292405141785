// Small cards with images or icons
.card.small-image-card
  min-height: 170px

  &.inactive
    opacity: 0.6

  &:hover
    opacity: 1

    .card-body, .card-footer
      background-color: $gray-100 !important

  .card-header
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    font-weight: 600

  .card-body
    display: flex !important
    align-items: center !important
    justify-content: center !important
    padding: 15px
    background: $white
    cursor: pointer
    position: relative

    &:not(.sm)
      height: 170px

      img
        max-height: 100%
        max-width: 170px

    &.sm
      height: 120px

      img
        max-height: 100%
        max-width: 120px

    .instructions
      opacity: 0
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex !important
      align-items: center !important
      justify-content: center !important
      flex-direction: column !important

    &:hover

      .instructions
        opacity: 1 !important

      img, i, svg
        opacity: 0 !important

  .card-footer
    position: relative !important
    background-color: transparent !important
    border-top: 0px 
    height: 40px !important

