.sticky-title-container
  height: 100%
  width: 100%

  @include media-breakpoint-up(lg)
    position: absolute

.sticky-title-section
  position: -webkit-sticky
  position: sticky !important
  // Z-index must be below navbar z-index for IE10 and IE11, which render sticky as position-relative
  // https://getbootstrap.com/docs/4.4/utilities/position/
  z-index: 48

  @include media-breakpoint-up(lg)
    max-height: calc(100vh - 100px)
    transition: 0.3s ease-in    
    top: $app-navbar-height // Offset so content not hidden behind navbar

.main-section.sticky-title-offset
  padding-top: $app-navbar-height + 55px
  padding-bottom: 100px

