/*==============================================================
 * Background display helpers for ratings
 *==============================================================*/

body
  .bg-a-rating, .bg-b-rating, .bg-c-rating, .bg-d-rating, .bg-f-rating, .bg-no-rating
    &.opaque:not(.c3-shape)
      color: $gray-700

body[data-color-mode="high_contrast"], body[data-color-mode="aca"], body[data-color-mode="guide"]
  .bg-a-rating, .bg-b-rating, .bg-c-rating, .bg-d-rating, .bg-f-rating, .bg-no-rating
    &.opaque:not(.c3-shape)
      color: #000 !important

.bg-a-rating

  &.c3-region
    fill: $a-rating !important

  &.opaque

    &.c3-bar
      fill: $a-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $a-rating-opaque !important

  &.dark

    &.c3-bar
      fill: $a-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $a-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $a-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $a-rating !important
      color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($a-rating, 55%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: lighten($a-rating, 53%) !important
      color: lighten($a-rating-dark, 3%) !important

  &.x-lightest
    background-color: lighten($a-rating, 58%) !important
    color: lighten($a-rating-dark, 3%) !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: lighten($a-rating, 53%) !important
    color: $gray-700 !important

.bg-b-rating

  &.c3-region
    fill: $b-rating !important

  &.opaque

    &.c3-bar
      fill: $b-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $b-rating-opaque !important

  &.dark

    &.c3-bar
      fill: $b-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $b-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $b-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $b-rating !important
      color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($b-rating, 34%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: lighten($b-rating, 32%) !important
      color: lighten($b-rating-dark, 3%) !important

  &.x-lightest, &.x-lightest .card-body
    background-color: lighten($b-rating, 37%) !important
    color: lighten($b-rating-dark, 3%) !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: lighten($b-rating, 32%) !important
    color: $gray-700 !important

.bg-c-rating

  &.c3-region
    fill: $c-rating !important

  &.opaque

    &.c3-bar
      fill: $c-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $c-rating-opaque !important

  &.dark

    &.c3-bar
      fill: $c-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $c-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $c-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $c-rating !important
      color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($c-rating, 55%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: lighten($c-rating, 59%) !important
      color: lighten($c-rating-dark, 3%) !important

  &.x-lightest, &.x-lightest .card-body
    background-color: lighten($c-rating, 63%) !important
    color: lighten($c-rating-dark, 3%) !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: lighten($c-rating, 59%) !important
    color: $gray-700 !important

.bg-d-rating

  &.c3-region
    fill: $d-rating !important

  &.opaque

    &.c3-bar
      fill: $d-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $d-rating-opaque !important

  &.dark

    &.c3-bar
      fill: $d-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $d-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $d-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $d-rating !important
      color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($d-rating, 55%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: lighten($d-rating, 55%) !important
      color: lighten($d-rating-dark, 3%) !important

  &.x-lightest, &.x-lightest .card-body
    background-color: lighten($d-rating, 60%) !important
    color: lighten($d-rating-dark, 3%) !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: lighten($d-rating, 55%) !important
    color: $gray-700 !important

.bg-f-rating

  &.c3-region
    fill: $f-rating !important

  &.opaque

    &.c3-bar
      fill: $f-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $f-rating-opaque !important

  &.dark

    &.c3-bar
      fill: $f-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $f-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $f-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $f-rating !important
      color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($f-rating, 55%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: lighten($f-rating, 55%) !important
      color: lighten($f-rating-dark, 3%) !important

  &.x-lightest, &.x-lightest .card-body
    background-color: lighten($f-rating, 60%) !important
    color: lighten($f-rating-dark, 3%) !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: lighten($f-rating, 55%) !important
    color: $gray-700 !important

.bg-no-rating

  &.opaque

    &.c3-bar
      fill: $no-rating-opaque !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $white !important

  &.dark

    &.c3-bar
      fill: $no-rating-dark !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $no-rating-dark !important
      color: $white !important

  &:not(.opaque):not(.dark):not(.lightest):not(.x-lightest):not(.lightest-gray)

    &.c3-bar
      fill: $no-rating !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $white !important

  &.lightest
    &.c3-bar
      fill: lighten($primary-light, 1.5%) !important

    &:not(.c3-shape), &:not(.c3-shape) .card-body
      background-color: $white !important

  &.x-lightest, &.x-lightest .card-body
    background-color: $white !important

  &.lightest-gray, &.lightest-gray .card-body
    background-color: $white !important
    color: $gray-700 !important


