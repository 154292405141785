body[data-page="PublicCompaniesIndex"], body[data-page="PublicFundsIndex"] 

  .navbar.nav-public:not(.nav-public-bordered)
    background-color: $primary-light !important

    .navbar-collapse
      background-color: transparent !important

  // Keep impact profile container min height to avoid shifting layout 
  // when update filters in sidebar
  .impact-profiles-section
    min-height: 2146px

    // Adjustments to card lists just for these pages
    .card-list-container-wide

      .record-name
        height: 30px

        @include media-breakpoint-down(xs)
          width: 55vw
        @include media-breakpoint-between(sm, lg)
          width: 70vw      
        @include media-breakpoint-up(lg)
          width: 45vw

        h2
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

  .sidebar-container
    overflow: visible

    .card, .card-body
      overflow: visible !important

      input
        border-radius: 0px
        border: 0px
        padding-left: 15px !important
        height: 42px !important
        background-color: transparent


  // Cause modal
  #causeModal, #metricModal

    .cat-link:not(:last-child)
      border-bottom: 1px solid $gray-300

  // Search causes
  .modal, .sidebar-container

    .twitter-typeahead

      .tt-menu .tt-suggestion
        padding: 8px 0px 8px 10px

        &:not(:last-child)
          border-bottom: 1px dashed $gray-200

        &:hover
          background-color: lighten($primary-light, 2%)

