body[data-page="AssessmentsScreens"]

  .impact-assessment-icon
    display: flex !important
    margin-right: auto
    align-items: center !important
    text-align: center

    &:not(:last-child)
      border-bottom: 1px dashed $gray-400

    span.fa-layers
      opacity: 0.3
      svg, i
        cursor: pointer !important

    &:hover, &.active
      span.fa-layers
        opacity: 1

        .fa-check
          display: flex !important

    .impact-category-name
      font-weight: 300
      white-space: nowrap
      text-align: left

  // Sidebar
  .screens-categories

    .screen-set
      font-size: $h5-font-size
      white-space: nowrap
      border-radius: 10px
      padding: 5px
      width: min-content
      opacity: 0.3

      &:hover, &.active
        opacity: 1

  // Impact icons
  .screens-container

    @include media-breakpoint-down(sm)
      justify-content: center !important
    
    .impact-assessment-icon

      span.fa-layers
        font-size: 20px

      .impact-category-name
        font-size: $h6-font-size
