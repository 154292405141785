.card.full-view .table.dataTable .DTFC_ScrollWrapper

  .table.dataTable
    margin-top: 0px !important
    margin-bottom: 0px !important

.DTFC_ScrollWrapper

  .table.dataTable
    margin-top: 0px !important
    margin-bottom: 0px !important

  .dataTables_scrollHead, .DTFC_LeftHeadWrapper, .DTFC_RightHeadWrapper
    border-bottom: 1px solid $gray-300 !important

  table tbody tr:first-child td
    border-top: none !important

  table tbody tr:last-child td
    border-bottom: 1px solid $gray-300 !important

  .DTFC_LeftBodyWrapper table tbody tr td:first-child, .DTFC_RightBodyWrapper table tbody tr td:first-child
    border-right: 1px solid $gray-300

  table.DTFC_Cloned tr
    background-color: $primary-x-light !important

.dtfc-fixed-right
  overflow: visible !important