.c3-gauge-value
  font-size: $h5-font-size !important
  font-weight: 400 !important
  font-family: 'Source Sans Pro', sans-serif

.bg-a-rating .c3-gauge-value
  fill: $a-rating !important

.bg-b-rating .c3-gauge-value
  fill: $b-rating !important

.bg-c-rating .c3-gauge-value
  fill: $c-rating !important

.bg-d-rating .c3-gauge-value
  fill: $d-rating !important

.bg-f-rating .c3-gauge-value
  fill: $f-rating !important

.c3-arc
  cursor: default !important

.questionnaire-gauge.inactive, .risk-topic-gauge.inactive
  opacity: 0.3

.questionnaire-gauge.active, .questionnaire-gauge:hover, .risk-topic-gauge.active, .risk-topic-gauge:hover
  opacity: 1
  border-color: $gray-500
  box-shadow: $shadow

.gauge-chart-footer
  border-top: 1px solid $gray-400

.gauge-css-chart 
  position: relative 
  border-radius: 50%/100% 100% 0 0 
  background-color: var(--color, #a22) 
  overflow: hidden 
  &:before 
    content: "" 
    display: block 
    padding-top: 50%
  .chart 
    overflow: hidden 
  .mask 
    position: absolute 
    left: 20% 
    right: 20% 
    bottom: 0 
    top: 40% 
    background-color: $primary-x-light 
    border-radius: 50%/100% 100% 0 0 
  .percentage 
    position: absolute 
    top: -1px 
    left: -1px 
    bottom: 0 
    right: -1px 
    background-color: $gray-300
    transform: rotate(var(--rotation)) 
    transform-origin: bottom center 
    transition-duration: 600 
  &:hover 
    --rotation: 100deg 
  .value 
    position: absolute 
    bottom: 5% 
    left: 0 
    width: 100% 
    text-align: center 
  .min 
    position: absolute 
    bottom: 0 
    left: 5% 
  .max 
    position: absolute 
    bottom: 0 
    right: 5% 
