body[data-page="QuestionnairesCollectionModulesShow"]

  .twitter-typeahead
    height: 100%

  .form-control:disabled.temp
    background-color: inherit
    font-weight: 300 !important

  input, select, textarea, .btn, .input-group-text, .filepicker-label
    border-color: $gray-400 !important

  .polling-indicator
    position: relative
    width: 30px
    min-width: 30px

    .spinner-border
      width: 15px !important
      height: 15px !important
      border-style: dotted !important

  .navbar
    z-index: 54 !important

  .fix-width.wider 
    padding: 0px 50px !important

  .get-included-section-content
    ul li
      margin-bottom: 0.5rem

  .table-container
    position: relative
    overflow: auto
    white-space: nowrap
    max-height: 500px

  .included-table-header
    border-top: 1px solid $gray-300 !important
    border: 1px solid $gray-300 !important
    background-color: $white !important

  .questionnaire-element-table
    font-size: $h6-font-size !important
    overflow: scroll !important

    select
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      cursor: pointer !important
      border: 0px !important
      text-align: center
      color: $gray-600 !important

    th
      padding: 10px !important

    td:not(:first-child)
      padding: 0px !important
      overflow: visible

    th, td
      white-space: normal !important

    td, th
      height: 35px !important
      text-align: center !important

    th:not(.add-cell), td:not(.add-cell)
      border-top: 1px solid $gray-300 !important
      border: 1px solid $gray-300 !important
      background-color: $white !important

    th
      top: 0px
      position: -webkit-sticky
      position: sticky
      z-index: 52

      &:first-child
        z-index: 53

    td:first-child, th:first-child
      width: 200px !important
      min-width: 200px !important
      max-width: 200px
      left: 0px
      text-align: left !important
      padding: 10px !important
      position: -webkit-sticky
      position: sticky
      vertical-align: middle !important

    td:not(:first-child), th:not(:first-child)
      max-width: 100px
      min-width: 100px
      width: 100px
      height: 1px

      .response-table-cell, .response-table-cell input, .response-table-cell select
        height: 100%

    tr:hover
      background-color: inherit !important

    td
      input
        text-align: center !important
        border: 0px !important
        border-radius: 0px !important


  .response-document-row

    &:not(:last-child)
      border-bottom: 1px solid $gray-300

  .element-row
    padding: 0px
    width: 100%

    &:first-child
      border-top: 1px dashed $gray-500

    &:not(:last-child)
      border-bottom: 1px dashed $gray-500

    .card-list-header
      padding: 10px 0px

  .questions-list
    padding-left: 0px
    padding-right: 0px

  .response-table-cell

    &.completed

      select, input
        background-color: lighten($a-rating, 53%) !important
        color: lighten($a-rating-dark, 3%) !important

    &:not(.completed)

      select, input
        background-color: lighten($f-rating, 55%) !important
        color: lighten($f-rating-dark, 3%) !important

  .question-row

    &.completed

      .card-header
        background-color: lighten($a-rating, 53%) !important
        color: lighten($a-rating-dark, 3%) !important

      .answered-indicator
        display: inline-block !important

      .card-body
        background-color: lighten($a-rating, 58%) !important
        color: lighten($a-rating-dark, 3%) !important

      .input-group-text
        color: $a-rating-dark !important

    &:not(.completed)

      .card-header
        background-color: lighten($f-rating, 55%) !important
        color: lighten($f-rating-dark, 3%) !important

      .answered-indicator
        display: none !important

      .card-body
        background-color: lighten($f-rating, 60%) !important
        color: lighten($f-rating-dark, 3%) !important

      .input-group-text
        color: $f-rating-dark !important

  .input-group select
    position: relative
    flex: 1 1 auto
    width: 1%
    min-width: 0
    margin-bottom: 0
    border-color: $gray-300 !important


  .file-upload-progress

    .progress
      margin-bottom: 15px
      max-width: none !important

  .filepicker-container
    position: relative
    height: 50px
    width: 100%
    display: flex !important
    align-items: center !important
    justify-content: center !important

    .response-filepicker
      opacity: 0

    .filepicker-label
      position: absolute
      top: -2px
      height: 100%
      width: 100%
      left: 0
      z-index: 1
      border: 1px dashed $gray-300
      border-radius: 5px
      background-color: $white
      color: $gray-500

      &:hover
        background-color: darken($gray-100, 2%)

      &.opaque
        opacity: 0.2
