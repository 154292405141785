// Conveyor belt
.conveyor-belt
  position: absolute
  bottom: 0px
  width: 100%
  height: 17px
  border-radius: 15px
  background-color: $primary-light
  border: 1px dashed $gray-500
  z-index: 1

  .circle
    border-radius: 100%
    border: 1px dashed $gray-400
    background-color: $primary-light
    margin: auto
    width: 15px
    height: 15px
    position: relative

    .cross-line
      width: 100%
      border: 1px dotted $gray-400
      height: 1px
      position: absolute

      &:nth-child(2)
        transform: rotate(45deg)

      &:nth-child(3)
        transform: rotate(90deg)

      &:nth-child(4)
        transform: rotate(135deg)

      &:nth-child(5)
        transform: rotate(180deg)

      &:nth-child(6)
        transform: rotate(225deg)

      &:nth-child(7)
        transform: rotate(270deg)

      &:nth-child(8)
        transform: rotate(315deg)
