body[data-page="AssessmentsWidget"]

  .widget-card
    position: relative
    margin-bottom: 0.5rem

    .account-logo
      width: 100%
      padding-top: 10px
      text-align: center
      
  .card.embed-code
    background-color: $gray-800
    color: lighten($gray-100, 5%)

  .preview-assessment-card
    position: relative
    margin-bottom: 0.5rem

    &:hover
      background-color: $primary-light

  .widget-cols
    
    @include media-breakpoint-up(md)
      .col-12:first-child
        border-right: 1px dashed $gray-300

    .input-group-text
      width: 135px
      padding-left: 10px
      padding-right: 10px
