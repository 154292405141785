@import 'stylesheets/_colors'

/*==============================================================
 * Text color helpers for ratings
 *==============================================================*/

.text-a-rating
  color: $a-rating-dark !important

.text-b-rating
  color: $b-rating-dark !important

.text-c-rating
  color: $c-rating-dark !important

.text-d-rating
  color: $d-rating-dark !important

.text-f-rating
  color: $f-rating-dark !important

.text-no-rating
  color: inherit !important