body[data-page="ReportsNew"], body[data-page="ReportsEdit"]

  input::-webkit-input-placeholder
    color: $gray-700 !important

  .form-row
    width: 100%

  .form-group.dashed-bottom:last-child
    margin-left: -16px
    margin-right: -16px
    padding-left: 16px
    padding-right: 16px
    border-bottom: 1px dashed $gray-300

  .btn-white
    color: $gray-700 !important

  .module-card:not(.minimized)
    overflow: visible !important

  #addNewModSection input
    background-color: $white !important

  li.module-option
    background-color: $primary-light

  .tt-menu
    max-height: 275px
    background-color: $primary-x-light
    box-shadow: 1px 0px 40px rgba(0, 0, 0, 0.3)

  .image-container
    margin: 15px
    display: flex !important
    align-items: center !important
    justify-content: center !important
    background: $white
    border: 1px solid $gray-300
    position: relative
    cursor: pointer
    overflow: hidden

    &.default-container
      height: 205px
      width: 160px
      padding: 0px

      img
        height: 100% !important

    &:not(.default-container)
      height: 200px
      width: 200px
      padding: 10px

    &:hover, &.active
      border: 2px solid $primary
      background: $primary-x-light

  .filepicker-container
    position: relative

    .filepicker
      opacity: 0

    .filepicker-label
      position: absolute
      top: -2px
      height: 31px
      right: 0
      z-index: 1

  li#questionnaire_response_section .card-body
    padding-bottom: 200px !important

  .card.excluded
    opacity: 0.3

    .card-body    
      color: $black !important

      i, svg
        color: $black !important

  // Next steps container
  #includeNextSteps
    cursor: pointer !important

  .custom-benchmark-disclosure-container, .custom-disclosure-container, .custom-confidentiality-container, .filter-to-list

    &.minimized
      opacity: 0
      height: 0

    &:not(.minimized)
      opacity: 1

  // Modules
  .module-option
    margin-bottom: 8px

  // Sections 
  .section-option
    margin-bottom: 8px

    // Choose options
    .card.choose-options
      max-height: none !important

    a.choose-report-options
      background-color: $white
      color: $gray-700

    &.minimized, .card.minimized:not(.choose-options)
      max-height: 48px !important

    &.excluded
      opacity: 0.6

      .card-header
        background-color: $primary-x-light !important

      input
        background-color: $gray-100

    .card-header
      padding-top: 5px !important
      padding-bottom: 5px !important
      height: 48px
      min-height: 48px

    select:not(.no-excluded)
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      padding-right: 15px !important

    picture
      max-width: 90% !important
      opacity: 0.85
      text-align: center

  .drag-section, .drag-module
    cursor: move !important

  .available-section-space, .available-module-space
    border: 1px dashed $gray-300
    background-color: $gray-100
    height: 60px
    margin-bottom: 1.5rem

  // Maturity table
  .maturity-assessment-table

    th, td
      text-align: left !important
      vertical-align: top !important
      white-space: normal !important
      padding: 5px 3px

    th:first-child, td:first-child
      width: 200px !important
      min-width: 200px !important
      max-width: 200px !important

    th:not(:first-child), td:not(:first-child)
      width: 33% !important

  // Client select
  .client-id-select
    cursor: pointer !important

  // Featured metrics
  .card-list-container-wide .list-card
    min-height: 110px !important

    .card.humanized-score
      height: 105px !important

      &.inactive
        opacity: 0.45

      &.active
        opacity: 1 !important

      .featured-metric-top
        font-size: $h6-font-size

      .featured-metric-score
        font-size: $h5-font-size

      .featured-metric-uom
        font-size: $h6-font-size

  .typeahead.selected
    border: 2px solid #0075ff !important

  // Add featured metrics card and table
  #reportChooseFundStories .table.dataTable
    margin-bottom: 15px !important

    tr
      position: relative !important // Needed for hover links

      &:hover 
        td:nth-child(3)
          opacity: 0

  .characteristics-tabs
    font-size: $h5-font-size
    font-weight: 300

    li.nav-item
      width: 100%

      .nav-link
        border-style: solid
        border-color: $gray-300
        border-radius: 0px

        &.active, &:hover
          background-color: $primary-light

        &.active
          font-weight: 400 !important

      &:first-child .nav-link
        border-width: 0px 1px 1px 0px
        border-top-left-radius: 0.25rem

      &:not(:first-child):not(:last-child) .nav-link
        border-width: 1px 1px 1px 0px

      &:last-child .nav-link
        border-width: 1px 1px 0px 0px

  #screensTabContent.tab-content
    height: 450px

  .screen-option-row
    font-size: $h6-font-size
    display: flex !important
    align-items: center !important
    padding: 4px 10px
    z-index: 0

    &:first-child
      border-top: 1px dashed $gray-400

    &:not(:last-child)
      border-bottom: 1px dashed $gray-400

    &:not(.excluded)
      background-color: $primary-light


