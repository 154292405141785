/*=========================================
 * C3 chart global styling
 *=======================================*/
// Make axis labels gray on bar charts
.c3 text
  fill-opacity: 1
  fill: $gray-600 !important
  color: $gray-600 !important
  font-size: $h6-font-size * 0.8
  font-weight: 300

// Make axis lines gray
.c3 path, .c3 line
  stroke: $gray-300

// Hide y-axis vertical line (display none)
.c3 path.domain
  stroke: none !important

// Make x-axis ticks cursor
.c3-axis-x .tick
  cursor: pointer !important

// Hide x-axis line, y-axis ticks, x-axis ticks
.c3-axis-x .domain, .c3-axis-x .tick line, .c3 g.tick line
  display: none

// Grid lines
.c3-grid line
  stroke: $gray-300 !important

.c3-legend-item text
  color: $gray-700 !important
