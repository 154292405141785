// Add alternate gray link option
a.gray-link
  color: inherit

  &:hover
    color: $primary

body[data-color-mode="black"]

  a.gray-link:hover
    color: $taupe_50 !important

body[data-color-mode="aca"]

  a.gray-link:hover
    color: $aca_blue !important

a.bolded-gray-link
  color: inherit
  font-weight: 600
  &:hover
    color: $primary

.clickable
  cursor: pointer

.no-clickable
  cursor: auto !important

.logo-name-link

  .logo-name-logo
    width: 60px
    min-width: 60px

  .logo-name-name div
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
