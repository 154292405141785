body[data-page="ResearchFundsShow"]

  .card.esg-overview-list

    .indicator
      transition: 0.3s all ease

      &.opaque
        opacity: 0.2

  .filter-security-weights

    &.active
      background-color: $primary-light

  .security-weights-table .dataTables_paginate
    position: absolute
    right: 15px
    bottom: 15px

  // Sortable sections for fund manager tabs and fund manager profiles
  .sortable-sidebar

    .manager-tab-section
      position: relative
      padding-right: 10px
      padding-left: 10px

      &:nth-child(odd)
        flex: 0 0 33.3333%
        max-width: 33.333%

      &:nth-child(even)
        flex: 0 0 66.6667%
        max-width: 66.6667%

  .sortable-split

    .manager-tab-section
      flex: 0 0 50%
      max-width: 50%
      position: relative
      padding-right: 10px
      padding-left: 10px

  #fundWeightTabs
    flex-wrap: nowrap !important

    li.nav-item
      margin-bottom: 0px !important

      @include media-breakpoint-up(md)
        padding-left: 5px
        padding-right: 5px

      a.nav-link
        background-color: transparent !important
        color: $gray-700
        border: 0px
        font-size: $h5-font-size

        @include media-breakpoint-down(sm)
          padding: 0.5rem 0.85rem !important

        &:not(.active)
          border-bottom: 4px solid transparent
          font-weight: 300

          &:hover
            color: $primary !important

        &.active
          font-weight: 400
          border-bottom: 4px solid $primary

