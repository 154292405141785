// Pagination container for all datatables
.dataTables_paginate
  font-weight: 300

  @include media-breakpoint-down(sm)
    .pagination .paginate_button 
      width: 100%
      text-align: center

  @include media-breakpoint-up(md)
    float: right

  .pagination 
    margin-top: 0px !important
    margin-bottom: 0px !important

    .paginate_button

      a
        padding: 6px 10px
        border: 1px solid $gray-300
        color: inherit
        background-color: lighten($primary-x-light, 3%)

      &.active a, &:hover:not(.disabled) a
        background: $primary-light
        color: inherit

      &.active a:hover
        cursor: default !important

      &.disabled, &.disabled:hover, &.disabled a
        cursor: default !important
        color: $gray-400 !important

