// List of cards, e.g., in clients show or assessment results
.card-list
  margin: 0px
  position: relative

  // Min height for rec section 
  &.rec-request.opaque.generating
    min-height: 170px

    .card-list-header, .card-list-items, .cause-filters
      opacity: 0.2

  .card-list-header
    font-weight: 400

    .list-title-section      
      display: flex !important
      width: 100%
      font-size: $h4-font-size
      font-weight: 600

      &:not(.align-items-start)
        align-items: center !important      

      .list-title
        display: flex !important
        align-items: center !important

      // Only clickable pointer if has collapse action
      .list-title[data-toggle="collapse"]
        cursor: pointer

      .indicators
        margin-left: auto
        align-items: center !important
        height: 26px

        i, svg
          font-size: $h5-font-size !important

      i.fas, svg[data-prefix="fas"]
        color: $gray-500

      i:not(.fas), svg:not[data-prefix="fas"]
        color: inherit

      i:hover, svg:hover
        color: $primary

  .card-list-items
    margin: 0px -20px
    padding: 0px 20px

    .list-carousel
      position: relative
      padding-top: 10px

      .row
        opacity: 1
        min-height: 30px

        &.opaque
          opacity: 0.3

        .carousel-item-container
          align-items: center !important
          display: flex !important
          margin-right: -17px

    // Height different for different types of cards
    .card-container-list.active-formula

      @include media-breakpoint-up(lg)

        .card
          height: 260px

          .card-img-top .card-go-button
            top: 65px

    .card-container-list:not(.active-formula) // Recommendation with user/org rating

      @include media-breakpoint-up(lg)

        .card:not(.sm)
          height: 280px

          .card-img-top .card-go-button
            top: 55px

        .card.sm
          height: 180px

          .card-img-top .card-go-button
            top: 55px

    .card-container-list
      overflow: visible
      display: flex !important
      align-items: center
      justify-content: center !important

      // Adjust spacing between columns on small screens
      @include media-breakpoint-down(sm)
        
        &:not(:first-child)
          padding-left: 5px !important
        &:not(:last-child)
          padding-right: 5px !important

      .card
        width: 100%
        background-color: #fff
        font-weight: 300
        cursor: pointer
        transition: 0.1s all ease !important
        margin-top: 0px
        z-index: 0
        color: inherit !important

        
        &:hover
          .card-top-rank, .card-top-rating
            opacity: 1 !important

        @include media-breakpoint-up(lg)

          &:hover
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.25)

          &:hover:not(.no-description)

            .card-img-top 

              img, h5, h4, h3, h2, .metric-value, .card-title-text, .card-subtitle-text
                opacity: 0.05

              .card-go-button
                opacity: 1

            .description-text
              height: 68px !important

              .description
                max-height: 52px
                min-height: 18px
                overflow: hidden

              .read-more
                height: 18px
                overflow: hidden

          &:not(:last-child)
            margin-right: 10px

        .card-img-top
          display: flex !important
          flex-direction: column !important
          align-items: center !important
          justify-content: center !important
          text-align: center
          font-size: $h5-font-size
          font-weight: 300
          position: absolute
          top: 0px 
          left: 0px
          padding: 0px 15px

          @include media-breakpoint-up(lg)
            min-height: 190px
            max-height: 220px
            height: calc(100% - 40px)

            img
              max-height: 85px

          @include media-breakpoint-down(md)
            height: 100%

            img
              max-height: 35px

          @include media-breakpoint-down(xs)
            img
              margin-top: 15px

          img
            transition: 0.2s all ease
            opacity: 1
            height: auto

            @include media-breakpoint-up(lg)
              max-width: 100px

            @include media-breakpoint-down(md)
              max-width: 100%

          .name
            max-height: 45px
            overflow: hidden
            color: inherit !important            

          .card-title-text
            word-break: break-word

            &:not(.sm)
              @include media-breakpoint-down(sm)
                font-size: 2.75vw
              @include media-breakpoint-between(md, lg)
                font-size: $h5-font-size
              @include media-breakpoint-up(xl)
                font-size: $h3-font-size
            &.sm
              @include media-breakpoint-down(sm)
                font-size: 1.5vw
              @include media-breakpoint-between(md, lg)
                font-size: $h6-font-size
              @include media-breakpoint-up(xl)
                font-size: $h5-font-size

          .card-go-button
            opacity: 0
            position: absolute
            left: 50%
            display: flex !important
            align-items: center !important
            justify-content: center !important
            color: inherit !important

            @include media-breakpoint-down(xs)
              width: 80px
              margin-left: -40px

            @include media-breakpoint-up(sm)
              width: 100px
              margin-left: -50px

          .card-top-rank
            position: absolute
            top: 5px
            left: 10px
            font-size: $h5-font-size
            color: inherit
            opacity: 0.4
            @include media-breakpoint-down(sm)
              display: none
            @include media-breakpoint-up(md)
              display: flex !important
              align-items: center !important

          .card-top-rating
            position: absolute
            top: 5px
            right: 10px
            font-size: $h5-font-size
            opacity: 0.8

        .card-body
          overflow: hidden
          padding: 0px 15px
          display: flex !important
          flex-direction: column !important
          justify-content: flex-end !important

          .card-text

            a
              color: inherit !important          

            .search-description
              display: flex !important
              flex-direction: column !important
              justify-content: center !important
              height: 84px !important

              .description-text
                height: 0px
                overflow: hidden
                transition: 0.2s height ease

  // Load more in show modals
  .load-more
    height: 38px !important

    .spinner-border
      height: 1.5rem !important
      width: 1.5rem !important


.search-data
  height: 30px
  align-items: center !important
  border-top: 1px solid $gray-300
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  @include media-breakpoint-up(lg)
    display: flex !important

  @include media-breakpoint-down(md)
    display: none !important

.search-actions
  border-top: 1px solid $gray-300
  height: 40px
  display: flex !important
  align-items: center !important
  justify-content: center !important

  @include media-breakpoint-down(md)
    opacity: 0 // adjust vis for small screens

  .fa-heart
    font-size: $h4-font-size

  .saved-indicator, .unsaved-indicator
    margin-right: 14px

  .more-info
    line-height: 0px
    font-size: 20px
    opacity: 0.7

