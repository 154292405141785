// Main footer used across app
.footer
  margin: 0 auto

  &.footer-user
    padding: 0px 0px 10px
    background-color: darken($primary-x-light, 1%)
    border-top: 1px solid $gray-300

    a, .gray-fixed
      color: $gray-700 !important

      &:hover
        color: $primary !important

  &.footer-public
    padding: 70px 0px 10px
    background-color: $gray-800
    color: $gray-200

    a, .gray-fixed
      color: $gray-500 !important

  .row
    margin-bottom: 20px // Add spacing below first fow of links in footer

  ul
    margin: 0px
    padding: 0px

    li
      list-style: none
      line-height: 1rem
      transition: 0.2s ease-in

      &:not(:last-child)
        @include media-breakpoint-down(md)
          margin: 7px 0px
        @include media-breakpoint-up(lg)
          margin: 15px 0px

      i
        font-size: 1rem
        margin-right: 5px
        padding-right: 8px

      a:hover, i:hover
        color: $gray-700