body[data-page="PortfoliosClimateRiskIndex"]

  // Text rotation on chart
  .carbon-footprint-label-container
    overflow: visible
    height: 55px
    white-space: nowrap
    margin-top: 5px
    transform: rotate(35deg)
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(35deg)
    /* Firefox */
    -moz-transform: rotate(35deg)
    /* IE */
    -ms-transform: rotate(35deg)
    /* Opera */
    -o-transform: rotate(35deg)
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3)

  #portfolio_locations_table
    td:last-child, th:last-child
      width: 1px !important

  #climate_hazards_chart
    position: relative 
    height: 650px

  .climate-tooltip
    position: absolute
    width: 350px
    min-height: 200px
    border: 1px solid $gray-600

    .tooltip-header
      padding: 10px

    .tooltip-body
      height: 100%

      td
        padding: 10px

        &:first-child
          font-weight: 600

  .counties
    fill: #fff
    stroke: $gray-600
    stroke-width: 0.2px
    stroke-linejoin: round
    stroke-linecap: round

  .counties 
    fill: $gray-200

    :hover
      fill: $primary

  .county-borders
    fill: none
    stroke: $white
    stroke-width: 0.4px
    stroke-linejoin: round
    stroke-linecap: round
    pointer-events: none

  #climate_hazards_chart

    .a1
      fill: #28A745
      color: #28A745
    .a2
      fill: #35A841
      color: #35A841
    .a3
      fill: #42AA3C
      color: #42AA3C
    .a4
      fill: #4FAB38
      color: #4FAB38
    .a5
      fill: #5CAC33
      color: #5CAC33

    .b1
      fill: #69AD2F
      color: #69AD2F
    .b2
      fill: #77AF2A
      color: #77AF2A
    .b3
      fill: #84B026
      color: #84B026
    .b4
      fill: #91B121
      color: #91B121
    .b5
      fill: #9EB21D
      color: #9EB21D

    .c1
      fill: #ABB418
      color: #ABB418
    .c2
      fill: #B8B514
      color: #B8B514
    .c3
      fill: #B9AC13
      color: #B9AC13
    .c4
      fill: #BAA313
      color: #BAA313
    .c5
      fill: #BB9A12
      color: #BB9A12

    .d1
      fill: #BC9111
      color: #BC9111
    .d2
      fill: #BD8810
      color: #BD8810
    .d3
      fill: #BD7E10
      color: #BD7E10
    .d4
      fill: #BE750F
      color: #BE750F
    .d5
      fill: #BF6C0E
      color: #BF6C0E

    .f1
      fill: #C0630D
      color: #C0630D
    .f2
      fill: #C15A0D
      color: #C15A0D
    .f3
      fill: #C2510C
      color: #C2510C
    .f4
      fill: #4d2004
      color: #4d2004
    .f5
      fill: #4d2004
      color: #4d2004

    .ns
      fill: #adb5bd
      color: #adb5bd


  .sticky-sidebar

    .sidebar
      transition: 0.5s all ease

      &.minimized:not(:hover)
        width: 35px

        .card-header
          .category-name, .category-info
            display: none

        .card-body
          display: none

      &:not.minimized, &:hover
        width: 350px

      #climateRiskFilters
        overflow: visible

        .btn
          white-space: normal
          text-align: left

        // Remove filter
        .remove-filter div div
          white-space: pre-wrap
          text-align: left

        .card
          &:not(:first-child)
            border-top-left-radius: 0px !important
            border-top-right-radius: 0px !important

          &:not(:last-child)
            border-bottom: 0px !important
            border-bottom-left-radius: 0px !important
            border-bottom-right-radius: 0px !important

          .card-header
            padding: 0.4rem !important

          &.minimized
            overflow: hidden !important
            min-height: 30px !important
            max-height: 30px !important

            .card-header
              border-bottom: 0px !important
              background-color: $gray-100 !important

            .card-body
              padding: 0px !important
              height: 0px !important
              min-height: 0px !important

          &:not(.minimized), .card-body
            overflow: visible !important

            input:not(.normal-height)
              border-radius: 0px
              border: 0px
              background-color: transparent
              padding-left: 15px !important
              height: 38px !important


