.assessment-results-visual
  display: flex !important
  flex-direction: flex-column !important
  justify-content: center !important
  position: relative

  .impact-persona
    cursor: default !important
    font-weight: 600
    opacity: 0.8
    border-radius: 5px !important
    padding: 5px
    border: 1px solid $gray-300
    line-height: 1
    box-shadow: 0 .05rem .25rem rgba($black, .1)

    @include media-breakpoint-down(xs)
      font-size: 3vw
    @include media-breakpoint-up(sm)
      font-size: $h5-font-size

  .result-categories

    &.faith-based-values .category .result-container, 
    &.faith-based-values:hover .category .result-container, 
    &.faith-based-values:hover .cause .result-container, 
    .result.cause.faith-based-values .result-container
      background-color: $faith-based-values-bg
      color: $faith-based-values-color

    &.climate .category .result-container, 
    &.climate:hover .category .result-container, 
    &.climate:hover .cause .result-container, 
    .result.cause.climate .result-container
      background-color: $climate-bg
      color: $climate-color

    &.peace .category .result-container, 
    &.peace:hover .category .result-container, 
    &.peace:hover .cause .result-container, 
    .result.cause.peace .result-container
      background-color: $peace-bg
      color: $peace-color

    &.economy .category .result-container, 
    &.economy:hover .category .result-container, 
    &.economy:hover .cause .result-container, 
    .result.cause.economy .result-container
      background-color: $economy-bg
      color: $economy-color

    &.life .category .result-container, 
    &.life:hover .category .result-container, 
    &.life:hover .cause .result-container, 
    .result.cause.life .result-container
      background-color: $life-bg
      color: $life-color

    &.sustain .category .result-container, 
    &.sustain:hover .category .result-container, 
    &.sustain:hover .cause .result-container, 
    .result.cause.sustain .result-container
      background-color: $sustain-bg
      color: $sustain-color

    &.innovation .category .result-container, 
    &.innovation:hover .category .result-container, 
    &.innovation:hover .cause .result-container, 
    .result.cause.innovation .result-container
      background-color: $innovation-bg
      color: $innovation-color

    &.water .category .result-container, 
    &.water:hover .category .result-container, 
    &.water:hover .cause .result-container, 
    .result.cause.water .result-container
      background-color: $water-bg
      color: $water-color

    &.education .category .result-container, 
    &.education:hover .category .result-container, 
    &.education:hover .cause .result-container, 
    .result.cause.education .result-container
      background-color: $education-bg
      color: $education-color

    &.gender .category .result-container, 
    &.gender:hover .category .result-container, 
    &.gender:hover .cause .result-container, 
    .result.cause.gender .result-container
      background-color: $gender-bg
      color: $gender-color

    &.health .category .result-container, 
    &.health:hover .category .result-container, 
    &.health:hover .cause .result-container, 
    .result.cause.health .result-container
      background-color: $health-bg
      color: $health-color

    // Risk rating colors
    &.environment-risk .category .result-container, 
    &.environment-risk:hover .category .result-container, 
    &.environment-risk:hover .cause .result-container, 
    .result.cause.environment-risk .result-container
      background-color: $climate-bg
      color: $climate-color

    &.social-capital .category .result-container, 
    &.social-capital:hover .category .result-container, 
    &.social-capital:hover .cause .result-container, 
    .result.cause.social-capital .result-container
      background-color: $economy-bg
      color: $economy-color

    &.human-capital .category .result-container, 
    &.human-capital:hover .category .result-container, 
    &.human-capital:hover .cause .result-container, 
    .result.cause.human-capital .result-container
      background-color: $water-bg
      color: $water-color

    &.business-model .category .result-container, 
    &.business-model:hover .category .result-container, 
    &.business-model:hover .cause .result-container, 
    .result.cause.business-model .result-container
      background-color: $innovation-bg
      color: $innovation-color

    &.leadership .category .result-container, 
    &.leadership:hover .category .result-container, 
    &.leadership:hover .cause .result-container, 
    .result.cause.leadership .result-container
      background-color: $peace-bg
      color: $peace-color


  .result-categories:not(.active) .result:not(.active) .result-container:not(:hover), .result.cause:not(.active), .result-container.bg-no-rating
    box-shadow: 0 .05rem .25rem rgba($gray-800, .1)
    opacity: 0.41

  .result-categories:hover
    opacity: 1

    .result .result-container
      opacity: 1 !important

  .result
    position: absolute
    cursor: pointer
    display: flex !important
    align-items: center !important
    justify-content: center !important
    text-align: center
    overflow: hidden

    .result-container
      border-radius: 5px !important
      padding: 5px
      border: 1px solid $gray-200

    &.active .result-container, &:hover .result-container
      box-shadow: 0 .05rem .25rem rgba($black, .1)

    &.category.active, &.category:hover
      opacity: 1

    &.cause
      padding: 10px        

      // Only set hover opacity active opacity set in controller based on weight of cause
      &:hover
        opacity: 0.6

    // Background icon
    .bg-icon
      opacity: 0.13
      position: absolute
      font-size: 96px !important


@include media-breakpoint-down(xs)

  .assessment-results-visual
    width: 340px
    margin: 20px auto

    &.faith-based-offset
      height: 530px

    &:not(.faith-based-offset)
      height: 430px

    .result.impact-persona
      top: 180px
      height: 70px
      left: 90px
      width: 130px

    .result.category
      font-size: $h3-font-size

      .result-container
        height: 40px
        width: 50px

      &.climate
        top: 0px
        left: 130px

      &.life
        top: 50px
        left: 100px

      &.sustain
        top: 50px
        left: 160px

      &.water
        top: 110px
        left: 220px

      &.health
        top: 280px
        left: 220px

      &.gender
        top: 340px
        left: 160px

      &.education
        top: 340px
        left: 100px

      &.innovation
        top: 390px
        left: 130px

      &.economy
        top: 280px
        left: 40px

      &.peace
        top: 110px
        left: 30px

      &.faith-based-values
        top: 475px
        left: 130px

      // Risk
      &.environment-risk
        top: 0px
        left: 130px

      &.social-capital
        top: 50px
        left: 160px

      &.human-capital
        top: 340px
        left: 160px

      &.business-model
        top: 390px
        left: 130px

      &.leadership
        top: 110px
        left: 30px


    .result.cause
      font-size: $h6-font-size

      .result-container
        height: 30px
        width: 30px

      &.climate:first-child
        top: 0px
        left: 80px

      &.climate:nth-child(2)
        top: 10px
        left: 40px

      &.climate:nth-child(3)
        top: 0px
        left: 180px

      &.life:first-child
        top: 90px
        left: 90px

      &.life:nth-child(2)
        top: 90px
        left: 130px

      &.life:nth-child(3)
        top: 130px
        left: 130px

      &.sustain:first-child
        top: 90px
        left: 170px

      &.sustain:nth-child(2)
        top: 10px
        left: 220px

      &.sustain:nth-child(3)
        top: 50px
        left: 210px

      &.water:first-child
        top: 130px
        left: 170px

      &.water:nth-child(2)
        top: 150px
        left: 220px

      &.water:nth-child(3)
        top: 150px
        left: 260px

      &.health:first-child
        top: 190px
        left: 220px

      &.health:nth-child(2)
        top: 190px
        left: 260px

      &.health:nth-child(3)
        top: 230px
        left: 220px

      &.health:nth-child(4)
        top: 230px
        left: 260px

      &.health:nth-child(5)
        top: 250px
        left: 170px

      &.gender:first-child
        top: 320px
        left: 210px

      &.gender:nth-child(2)
        top: 290px
        left: 170px

      &.gender:nth-child(3)
        top: 360px
        left: 220px

      &.education:first-child
        top: 290px
        left: 130px

      &.education:nth-child(2)
        top: 320px
        left: 50px

      &.education:nth-child(3)
        top: 250px
        left: 130px

      &.innovation:first-child
        top: 380px
        left: 80px

      &.innovation:nth-child(2)
        top: 380px
        left: 180px

      &.economy:first-child
        top: 250px
        left: 90px

      &.economy:nth-child(2)
        top: 230px
        left: 40px

      &.economy:nth-child(3)
        top: 230px
        left: 0px

      &.economy:nth-child(4)
        top: 190px
        left: 40px

      &.economy:nth-child(5)
        top: 190px
        left: 0px

      &.economy:nth-child(6)
        top: 290px
        left: 90px

      &.peace:first-child
        top: 150px
        left: 40px

      &.peace:nth-child(2)
        top: 130px
        left: 90px

      &.peace:nth-child(3)
        top: 50px
        left: 50px

      &.peace:nth-child(4)
        top: 150px
        left: 0px

      &.faith-based-values:nth-child(1)
        top: 440px
        left: 85px

      &.faith-based-values:nth-child(2)
        top: 440px
        left: 175px

      &.faith-based-values:nth-child(3)
        top: 430px
        left: 130px

      // Risk
      &.environment-risk:first-child
        top: 0px
        left: 80px

      &.environment-risk:nth-child(2)
        top: 10px
        left: 40px

      &.environment-risk:nth-child(3)
        top: 0px
        left: 180px

      &.environment-risk:nth-child(4)
        top: 90px
        left: 130px

      &.environment-risk:nth-child(5)
        top: 130px
        left: 130px

      &.environment-risk:nth-child(6)
        top: 90px
        left: 170px

      &.social-capital:first-child
        top: 10px
        left: 220px

      &.social-capital:nth-child(2)
        top: 50px
        left: 210px

      &.social-capital:nth-child(3)
        top: 130px
        left: 170px

      &.social-capital:nth-child(4)
        top: 150px
        left: 220px

      &.social-capital:nth-child(5)
        top: 150px
        left: 260px

      &.social-capital:nth-child(6)
        top: 230px
        left: 260px

      &.social-capital:nth-child(7)
        top: 250px
        left: 170px

      &.human-capital:first-child
        top: 320px
        left: 210px

      &.human-capital:nth-child(2)
        top: 290px
        left: 170px

      &.human-capital:nth-child(3)
        top: 360px
        left: 220px

      &.business-model:first-child
        top: 250px
        left: 130px

      &.business-model:nth-child(2)
        top: 380px
        left: 80px

      &.business-model:nth-child(3)
        top: 380px
        left: 180px

      &.leadership:first-child
        top: 290px
        left: 90px

      &.leadership:nth-child(2)
        top: 150px
        left: 40px

      &.leadership:nth-child(3)
        top: 130px
        left: 90px

      &.leadership:nth-child(4)
        top: 50px
        left: 50px

      &.leadership:nth-child(5)
        top: 150px
        left: 0px



@include media-breakpoint-between(sm, md)

  .assessment-results-visual
    width: 600px
    margin: 20px auto

    &.faith-based-offset
      height: 500px

    &:not(.faith-based-offset)
      height: 400px

    .result.impact-persona
      top: 160px
      height: 80px
      left: 220px
      width: 160px

    .result.category
      font-size: $h1-font-size

      .result-container
        height: 50px
        width: 70px

      &.climate
        top: 0px
        left: 220px

      &.life
        top: 0px
        left: 310px

      &.sustain
        top: 60px
        left: 380px

      &.water
        top: 160px
        left: 490px

      &.health
        top: 270px
        left: 380px

      &.gender
        top: 350px
        left: 310px

      &.education
        top: 350px
        left: 220px

      &.innovation
        top: 270px
        left: 150px

      &.economy
        top: 160px
        left: 40px

      &.peace
        top: 60px
        left: 150px

      &.faith-based-values
        top: 460px
        left: 265px

      // Risk
      &.environment-risk
        top: 0px
        left: 220px

      &.social-capital
        top: 60px
        left: 380px

      &.human-capital
        top: 350px
        left: 310px

      &.business-model
        top: 270px
        left: 150px

      &.leadership
        top: 60px
        left: 150px


    .result.cause
      font-size: $h3-font-size

      .result-container
        height: 40px
        width: 40px

      &.climate:first-child
        top: 100px
        left: 220px

      &.climate:nth-child(2)
        top: 50px
        left: 270px

      &.climate:nth-child(3)
        top: 100px
        left: 270px

      &.life:first-child
        top: 50px
        left: 320px

      &.life:nth-child(2)
        top: 100px
        left: 320px

      &.life:nth-child(3)
        top: 0px
        left: 380px

      &.sustain:first-child
        top: 0px
        left: 430px

      &.sustain:nth-child(2)
        top: 60px
        left: 450px

      &.sustain:nth-child(3)
        top: 110px
        left: 380px

      &.water:first-child
        top: 110px
        left: 430px

      &.water:nth-child(2)
        top: 160px
        left: 380px

      &.water:nth-child(3)
        top: 160px
        left: 430px

      &.health:first-child
        top: 210px
        left: 380px

      &.health:nth-child(2)
        top: 210px
        left: 430px

      &.health:nth-child(3)
        top: 260px
        left: 450px

      &.health:nth-child(4)
        top: 320px
        left: 430px

      &.health:nth-child(5)
        top: 320px
        left: 380px

      &.gender:first-child
        top: 240px
        left: 320px

      &.gender:nth-child(2)
        top: 290px
        left: 320px

      &.gender:nth-child(3)
        top: 240px
        left: 270px

      &.education:first-child
        top: 290px
        left: 220px

      &.education:nth-child(2)
        top: 290px
        left: 270px

      &.education:nth-child(3)
        top: 320px
        left: 160px

      &.innovation:first-child
        top: 240px
        left: 220px

      &.innovation:nth-child(2)
        top: 260px
        left: 90px

      &.economy:first-child
        top: 110px
        left: 110px

      &.economy:nth-child(2)
        top: 210px
        left: 160px

      &.economy:nth-child(3)
        top: 210px
        left: 110px

      &.economy:nth-child(4)
        top: 160px
        left: 160px

      &.economy:nth-child(5)
        top: 160px
        left: 110px

      &.economy:nth-child(6)
        top: 110px
        left: 160px

      &.peace:first-child
        top: 50px
        left: 220px

      &.peace:nth-child(2)
        top: 60px
        left: 90px

      &.peace:nth-child(3)
        top: 0px
        left: 110px

      &.peace:nth-child(4)
        top: 0px
        left: 160px

      &.faith-based-values:nth-child(1)
        top: 415px
        left: 210px

      &.faith-based-values:nth-child(2)
        top: 415px
        left: 330px

      &.faith-based-values:nth-child(3)
        top: 405px
        left: 270px

      // Risk
      &.environment-risk:first-child
        top: 100px
        left: 220px

      &.environment-risk:nth-child(2)
        top: 50px
        left: 270px

      &.environment-risk:nth-child(3)
        top: 100px
        left: 270px

      &.environment-risk:nth-child(4)
        top: 50px
        left: 320px

      &.environment-risk:nth-child(5)
        top: 100px
        left: 320px

      &.environment-risk:nth-child(6)
        top: 0px
        left: 380px

      &.social-capital:first-child
        top: 0px
        left: 430px

      &.social-capital:nth-child(2)
        top: 60px
        left: 450px

      &.social-capital:nth-child(3)
        top: 110px
        left: 380px

      &.social-capital:nth-child(4)
        top: 110px
        left: 430px

      &.social-capital:nth-child(5)
        top: 160px
        left: 380px

      &.social-capital:nth-child(6)
        top: 160px
        left: 430px

      &.social-capital:nth-child(7)
        top: 210px
        left: 380px

      &.human-capital:first-child
        top: 240px
        left: 320px

      &.human-capital:nth-child(2)
        top: 290px
        left: 320px

      &.human-capital:nth-child(3)
        top: 240px
        left: 270px

      &.business-model:first-child
        top: 320px
        left: 160px

      &.business-model:nth-child(2)
        top: 240px
        left: 220px

      &.business-model:nth-child(3)
        top: 260px
        left: 90px

      &.business-model:nth-child(4)
        top: 110px
        left: 110px

      &.business-model:nth-child(5)
        top: 210px
        left: 160px

      &.leadership:first-child
        top: 110px
        left: 160px

      &.leadership:nth-child(2)
        top: 50px
        left: 220px

      &.leadership:nth-child(3)
        top: 60px
        left: 90px

      &.leadership:nth-child(4)
        top: 0px
        left: 110px

      &.leadership:nth-child(5)
        top: 0px
        left: 160px

@include media-breakpoint-up(lg)

  .assessment-results-visual
    width: 800px
    margin: 0px auto 20px auto

    &.faith-based-offset
      height: 500px

    &:not(.faith-based-offset)
      height: 400px

    .result.impact-persona
      top: 160px
      height: 80px
      left: 320px
      width: 160px

    .result.category
      font-size: $h1-font-size

      .result-container
        height: 60px
        width: 70px

      &.peace
        top: 50px
        left: 170px

      &.climate
        top: 40px
        left: 300px

      &.life
        top: 40px
        left: 430px

      &.sustain
        top: 50px
        left: 560px

      &.innovation
        top: 290px
        left: 170px

      &.water
        top: 170px
        left: 590px

      &.economy
        top: 170px
        left: 140px

      &.education
        top: 300px
        left: 300px

      &.gender
        top: 300px
        left: 430px

      &.health
        top: 290px
        left: 560px

      &.faith-based-values
        top: 425px
        left: 365px

      // Risk
      &.environment-risk
        top: 30px
        left: 350px

      &.social-capital
        top: 120px
        left: 490px

      &.human-capital
        top: 250px
        left: 500px

      &.business-model-and-innovation
        top: 310px
        left: 380px

      &.leadership-and-governance
        top: 160px
        left: 180px

    .result.cause
      font-size: $h3-font-size

      .result-container:not(.risk-container)
        height: 40px
        width: 40px

      .result-container.risk-container
        height: 50px
        width: 50px

      &.peace:first-child
        top: 60px
        left: 240px

      &.peace:nth-child(2)
        top: 110px
        left: 270px

      &.peace:nth-child(3)
        top: 110px
        left: 170px

      &.peace:nth-child(4)
        top: 110px
        left: 220px

      &.climate:first-child
        top: 100px
        left: 320px

      &.climate:nth-child(2)
        top: 100px
        left: 370px

      &.climate:nth-child(3)
        top: 50px
        left: 370px

      &.life:first-child
        top: 110px
        left: 470px

      &.life:nth-child(2)
        top: 60px
        left: 500px

      &.life:nth-child(3)
        top: 100px
        left: 420px

      &.sustain:first-child
        top: 110px
        left: 620px

      &.sustain:nth-child(2)
        top: 110px
        left: 520px

      &.sustain:nth-child(3)
        top: 110px
        left: 570px

      &.innovation:first-child
        top: 280px
        left: 240px

      &.innovation:nth-child(2)
        top: 230px
        left: 220px

      &.water:first-child
        top: 170px
        left: 480px

      &.water:nth-child(2)
        top: 170px
        left: 660px

      &.water:nth-child(3)
        top: 170px
        left: 530px

      &.economy:first-child
        top: 230px
        left: 120px

      &.economy:nth-child(2)
        top: 110px
        left: 120px

      &.economy:nth-child(3)
        top: 170px
        left: 210px

      &.economy:nth-child(4)
        top: 170px
        left: 80px

      &.economy:nth-child(5)
        top: 230px
        left: 170px

      &.economy:nth-child(6)
        top: 170px
        left: 260px

      &.education:first-child
        top: 240px
        left: 320px

      &.education:nth-child(2)
        top: 290px
        left: 370px

      &.education:nth-child(3)
        top: 230px
        left: 270px

      &.gender:first-child
        top: 240px
        left: 420px

      &.gender:nth-child(2)
        top: 230px
        left: 470px

      &.gender:nth-child(3)
        top: 240px
        left: 370px

      &.health:first-child
        top: 230px
        left: 620px

      &.health:nth-child(2)
        top: 280px
        left: 500px

      &.health:nth-child(3)
        top: 230px
        left: 520px

      &.health:nth-child(4)
        top: 230px
        left: 570px

      &.health:nth-child(5)
        top: 280px
        left: 630px

      &.faith-based-values:nth-child(1)
        top: 390px
        left: 307px

      &.faith-based-values:nth-child(2)
        top: 390px
        left: 432px

      &.faith-based-values:nth-child(3)
        top: 370px
        left: 370px

      // Risk
      &.environment-risk:first-child
        top: 30px
        left: 220px

      &.environment-risk:nth-child(2)
        top: 30px
        left: 280px

      &.environment-risk:nth-child(3)
        top: 90px
        left: 180px

      &.environment-risk:nth-child(4)
        top: 90px
        left: 240px

      &.environment-risk:nth-child(5)
        top: 90px
        left: 300px

      &.environment-risk:nth-child(6)
        top: 90px
        left: 360px

      &.social-capital:first-child
        top: 30px
        left: 420px

      &.social-capital:nth-child(2)
        top: 90px
        left: 420px

      &.social-capital:nth-child(3)
        top: 50px
        left: 480px

      &.social-capital:nth-child(4)
        top: 50px
        left: 540px

      &.social-capital:nth-child(5)
        top: 180px
        left: 540px

      &.social-capital:nth-child(6)
        top: 110px
        left: 560px

      &.social-capital:nth-child(7)
        top: 180px
        left: 480px

      &.human-capital:first-child
        top: 240px
        left: 570px

      &.human-capital:nth-child(2)
        top: 310px
        left: 510px

      &.human-capital:nth-child(3)
        top: 310px
        left: 450px

      &.business-model-and-innovation:first-child
        top: 240px
        left: 430px

      &.business-model-and-innovation:nth-child(2)
        top: 240px
        left: 370px

      &.business-model-and-innovation:nth-child(3)
        top: 240px
        left: 310px

      &.business-model-and-innovation:nth-child(4)
        top: 300px
        left: 310px

      &.business-model-and-innovation:nth-child(5)
        top: 270px
        left: 250px

      &.leadership-and-governance:first-child
        top: 150px
        left: 250px

      &.leadership-and-governance:nth-child(2)
        top: 210px
        left: 250px

      &.leadership-and-governance:nth-child(3)
        top: 220px
        left: 190px

      &.leadership-and-governance:nth-child(4)
        top: 280px
        left: 190px

      &.leadership-and-governance:nth-child(5)
        top: 220px
        left: 130px

