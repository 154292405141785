.banner-container
  width: 100%
  position: relative
  background: $primary-light
  display: flex !important
  margin-bottom: 0px

  &.banner-lg
    height: 200px

  &.banner-md
    height: 150px

  &.banner-sm
    height: 75px

  &.opaque
    i, svg
      opacity: 0.2

  &.more-opaque
    i, svg
      opacity: 0.15