body[data-page="ResearchFundsRealWorldMetricsShow"], body[data-page="ResearchCompaniesRealWorldMetricsShow"]

  // Comparison chart
  .chart-container
    
    @include media-breakpoint-down(md)
      flex-direction: column !important

    .chart
      @include media-breakpoint-down(md)
        max-width: 400px
      @include media-breakpoint-between(md, lg)
        max-width: 500px
      @include media-breakpoint-up(xl)
        max-width: 700px

    .c3 text
      font-size: $h5-font-size !important
      color: $gray-700 !important
      fill: $gray-700 !important
      font-family: 'Source Sans Pro', sans-serif

    .c3-chart-texts .c3-text
      transform: translate(0px, -5px)

    .c3-axis text
      font-weight: 400 !important

