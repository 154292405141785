body[data-page="QuestionnairesIncludedModulesEdit"]

  .section-row
    padding: 0px

    &:not(:last-child)
      border-bottom: 1px dashed $gray-500

    .grip
      cursor: move !important
      z-index: 51

      svg[data-prefix=fas]
        color: $gray-700 !important

    .card-list-header
      padding: 10px 0px

  .questions-sortable, .texts-sortable
    width: calc(100% + 60px)
    margin-left: -30px
    margin-right: -30px
    padding-left: 30px
    padding-right: 30px
    border-top: 1px solid $gray-300

    .question-row, .text-row

      .question-grip, .text-grip
        cursor: move !important
        z-index: 51

      &:not(:last-child)
        border-bottom: 1px solid $gray-300

  // Name/title
  #setModuleInfo h3
    border: 1px solid transparent
    padding: 5px
    margin-left: -5px

    &:hover
      border: 1px solid $gray-200
      background-color: $white
      border-radius: 5px

  .available-section-space
    border: 2px dashed $gray-400
    background-color: $gray-100
    height: 50px
    margin-bottom: 1.5rem
    width: 100%
    position: relative
    padding-left: 10px
    padding-right: 10px

  .available-question-space, .available-text-space
    border: 2px dashed $gray-400
    background-color: $gray-100
    height: 37px
    margin-bottom: 1.5rem
    width: 100%
    position: relative
    padding-left: 10px
    padding-right: 10px
