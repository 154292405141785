.pie-chart-container
  min-height: 200px !important
  height: inherit !important

  .c3-chart-arc 

    path
      stroke-width: 5

    text
      font-size: $h5-font-size

    .c3-shape
      z-index: 500

      &:hover
        cursor: default !important

  .pie-chart-labels
    position: absolute
    height: 300px
    width: 300px
    opacity: 0
    display: flex !important
    justify-content: center !important
    align-items: center !important

    .label-container
      position: relative

      .pie-chart-label
        position: absolute
        font-size: $h5-font-size
        white-space: nowrap

  #assessment_causes_chart
    height: 300px
    z-index: 49
    margin-top: 20px


  .pie-chart-label.opaque
    color: $gray-400

    span.pie-chart-label-box
      opacity: 0.2

  .pie-chart-label.bolded
    font-weight: 600 !important

