.chart-container

  .legend-container

    .legend button
      display: inline-block
      cursor: pointer

      // Limit width of text in buttons, hide overflow
      .display-text
        display: inline-block
        vertical-align: middle

  // Color span in legend buttons
  .legend span
    float: left
    margin-top: 4px
    margin-right: 7px
    padding: 5px
