body[data-color-mode="aca"]

  .navbar #navbarSearchSm .search-menu-expanded .row
    background-color: $white !important
    color: $gray-700 !important

    &:hover
      background-color: $white !important

body[data-color-mode="black"]

  .navbar #navbarSearchSm .search-menu-expanded .row
    background-color: $white !important
    color: $black !important

    &:hover
      background-color: $white !important


.navbar

  #navbarSearchSm
    display: block !important

    // Expand to full-width on small devices
    @include media-breakpoint-down(sm)
      width: 100%

    // Show
    &.show .search-menu-expanded
      display: block
      border-bottom: 1px solid $gray-400

    // Expanded search menu (full-width)
    .search-menu-expanded
      width: 100%
      left: 0px !important
      display: none
      position: absolute
      top: $app-navbar-height
      left: 0px
      padding-left: 0px

      // Adjust height and scrolling on medium and smaller devices
      @include media-breakpoint-down(md)
        height: calc(100vh - 175px)
        overflow: auto

      .row
        background-color: $primary-x-light
        color: $gray-700

        &:hover
          background-color: $primary-x-light !important

        // Close dropdown
        .close
          cursor: pointer
          font-size: 14px !important
          font-weight: 300 !important
          margin-top: -12px

        .search-list
          border-bottom: 1px solid $gray-300
          height: 200px
          overflow: auto

          div
            padding: 5px 3px 5px
            cursor: pointer

            &:hover
              background-color: $primary-light !important

        input
          border: 0px !important
          height: 55px
          padding: 15px 0px 16px 16px !important

        .input-group-append .input-group-text
          border: 0px !important
          padding-right: 16px !important