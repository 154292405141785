body[data-page="AccountsManagedFundsPreview"]

  .modal-header

    .modal-title, .page-title
      font-size: $h3-font-size
      display: flex // Do not make important override in view for social share icons
      align-items: center !important

    .modal-subtitle
      font-size: $h5-font-size
      font-weight: 300

  .modal-body
    background-color: $primary-x-light
    font-size: $h5-font-size

    .tab-pane
      padding-top: 15px

      // Transparent cards
      .card.t-card
        margin-left: 10px
        margin-right: 10px

        .card-header
          padding: 0px 0px 0.75rem 0px
          background-color: transparent !important

        .metric-scores-table
          margin-top: 10px !important


  // Sortable sections for fund manager tabs and fund manager profiles
  .sortable-sidebar

    .manager-tab-section
      position: relative
      padding-right: 10px
      padding-left: 10px

      &:nth-child(odd)
        flex: 0 0 33.3333%
        max-width: 33.333%

      &:nth-child(even)
        flex: 0 0 66.6667%
        max-width: 66.6667%

  .sortable-split

    .manager-tab-section
      flex: 0 0 50%
      max-width: 50%
      position: relative
      padding-right: 10px
      padding-left: 10px

