.circle
  height: 10px
  width: 10px
  border-radius: 50%
  display: inline-block
  margin: 0px 5px

  &:not(.active):not(.lighter)
    background-color: $gray-400 !important

  &.lighter
    background-color: $gray-200

.commentary, .summary-text
  font-weight: 300
  font-size: $h5-font-size
  
.overflow-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.white-space-nowrap
  white-space: nowrap

