body[data-page="ResearchCompaniesShow"], body[data-page="ResearchFundsShow"], body[data-page="PublicCompaniesShow"], body[data-page="PublicFundsShow"]

  .characteristic-row-comparison
    cursor: default !important

  .questionnaire-scoring-table

    tr:hover 
      background-color: inherit !important

      td:not(.segment-cell)
        background-color: $primary-x-light !important

    th
      font-weight: 400 !important

    th, td
      height: 30px !important

      &:nth-child(1), &:nth-child(2)
        text-align: left !important

      &:not(:nth-child(1)):not(:nth-child(2))
        text-align: right !important

      &:last-child
        text-align: right !important

      &.text-cell
        width: 20% !important
        min-width: 20% !important
        max-width: 20% !important

      &:not(.text-cell)
        width: 15% !important
        min-width: 15% !important
        max-width: 15% !important

    td
      border-color: $gray-500 !important
      font-weight: 300 !important

  // Adjust sidebar diff to top
  .sticky-sidebar
    @include media-breakpoint-up(lg)
      top: 20px !important

  #search_questionnaire_response_answers_table
    min-width: 300px
    width: 300px

  #questionnaire_response_answers_table_wrapper

    th:first-child, td:first-child
      width: 300px !important

    th:nth-child(3), th:nth-child(4), td:nth-child(3), td:nth-child(4)
      width: 200px !important

    th:nth-child(2), th:nth-child(2)
      width: 100% !important

    th:nth-child(3), th:nth-child(4), td:nth-child(3), td:nth-child(4)
      text-align: left !important


  #questionnaire_response_answer_trends_table_wrapper

    .table

      td 
        white-space: normal !important
        padding-top: 5px
        padding-bottom: 5px
        vertical-align: top

      tr
        cursor: pointer !important

      thead th
        font-weight: 400 !important

      tbody td
        font-weight: 300 !important


  .controversies-table

    td:first-child
      width: 75% !important
      max-width: 600px !important

  #profileNavTabs li.nav-item 
    display: flex !important
    align-items: end !important

    a.nav-link
      text-align: center

  #metricScoreScatterCard, #riskScorecardTab
    overflow: visible !important

    .c3-tooltip-container
      width: 400px !important
      pointer-events: auto !important

      @include media-breakpoint-down(sm)
        bottom: -175px !important
        height: 175px !important
        left: 0px !important
        overflow: auto !important
        top: inherit !important
        width: 100% !important

        .c3-tooltip
          opacity: 1 !important
          width: 100% !important

  .navbar.nav-public:not(.nav-public-bordered)
    background-color: $primary-light !important

    .navbar-collapse
      background-color: $primary-light !important

  .modal-header

    .modal-title, .page-title
      font-size: $h3-font-size
      display: flex // Do not make important override in view for social share icons
      align-items: center !important

    .modal-subtitle
      font-size: $h5-font-size
      font-weight: 300

  .modal-body

    .tab-pane
      padding-top: 15px

      // Transparent cards
      .card.t-card
        margin-left: 10px
        margin-right: 10px

        .card-header
          padding: 0px 0px 0.75rem 0px
          background-color: transparent !important

        .metric-scores-table
          margin-top: 10px !important

  .about-section
    font-size: $h5-font-size

    .sub-section
      overflow: hidden
      margin-right: 0px !important
      margin-left: 0px !important

      &:not(:first-child)
        padding-top: 8px

      &:not(:last-child)
        border-bottom: 1px solid $gray-200

      .col-auto
        padding-bottom: 8px
        overflow: hidden
        padding-right: 0px !important
        padding-left: 0px !important

  .impact-screens-list .table

    tr

      &:first-child td
        border-top: 0px !important

      td
        height: 20px !important
        white-space: normal !important
        padding-top: 4px
        padding-bottom: 4px

        &:first-child
          padding-left: 5px

        &:last-child
          padding-right: 8px

          div
            // width: 75px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

  // About tab
  #aboutTab

    // Google map of business address
    .business-address-map
      height: 300px
      width: 100%

    // Add some margin to LIs in descriptions
    li
      margin-bottom: 15px

    p:last-child
      margin-bottom: 0px

// Excerpt card (shorter with link to expand)
.excerptable
  
  &:not(.expanded)
    overflow: hidden

    .description .excerpted
      height: 128px
      overflow: hidden
      position: relative

    .expand-excerpt
      margin-top: 6px


  #allFormulas, #metricScoreDetailsCard, #controversiesDetailsCard
    .chart-container
      height: 110px

      .chart
        height: 100px

  // Commentary on company impact
  #commentaryCard
    
    .metric-name, .formula-name
      font-style: italic !important

  .custom-ratings-explorer

    .progress
      border-radius: 0px !important
