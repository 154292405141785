body[data-page="QuestionnairesCollectionModulesGrid"]

  .form-control:disabled.temp
    background-color: inherit
    font-weight: 300 !important

  .incomplete

    td
      border-top: 2px solid $f-rating-dark !important
      border-bottom: 2px solid $f-rating-dark !important

      &:first-of-type
        border-left: 2px solid $f-rating-dark !important

      &:last-child
        border-right: 2px solid $f-rating-dark !important

  input.filled, select.filled, textarea.filled
    background-color: #e5f9e9 !important
    color: #1c7631 !important

  input.no-asc, select.no-asc, textarea.no-asc
    background-color: #e5f9e9 !important
    color: #1c7631 !important

  input.low-asc, select.low-asc, textarea.low-asc
    background-color: #e5f9e9 !important
    color: #1c7631 !important

  input.yes-desc, select.yes-desc, textarea.yes-desc
    background-color: #e5f9e9 !important
    color: #1c7631 !important

  input.high-desc, select.high-desc, textarea.high-desc
    background-color: #e5f9e9 !important
    color: #1c7631 !important

  input.information-not-available, select.information-not-available, textarea.information-not-available
    background-color: #fffefa !important
    color: #989511 !important

  input.medium, select.medium, textarea.medium
    background-color: #fffefa !important
    color: #989511 !important

  input.yes-asc, select.yes-asc, textarea.yes-asc
    background-color: #fef1e9 !important
    color: #a0430a !important

  input.high-asc, select.high-asc, textarea.high-asc
    background-color: #fef1e9 !important
    color: #a0430a !important

  input.low-desc, select.low-desc, textarea.low-desc
    background-color: #fef1e9 !important
    color: #a0430a !important

  input.no-desc, select.no-desc, textarea.no-desc
    background-color: #fef1e9 !important
    color: #a0430a !important

  input, textarea, select
    font-weight: 300

  .current-documents

    .response-document-name
      max-width: 300px
      width: 85%
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

  .module-row
    padding: 5px

  .input-group
    .twitter-typeahead:not(:last-child) input
      border-top-right-radius: 0px
      border-bottom-right-radius: 0px

  .not-applicable-input
    border-top-right-radius: 0px
    border-bottom-right-radius: 0px

  .mark-not-applicable, .pre-fill-answer, .prefer-not-disclose
    border-color: $gray-500

  .table-sticky-header
    position: -webkit-sticky
    position: sticky !important
    z-index: 101
    top: 0px
    background-color: $white

    th
      padding-top: 20px !important
      border-bottom: 1px solid $gray-400 !important

  .data-collection-wrapper span.unchecked
    padding: 10px !important

  input::-webkit-input-placeholder
    color: $gray-600 !important

  .form-control::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray-600
    opacity: 1 /* Firefox */

  .form-control:-ms-input-placeholder /* Internet Explorer 10-11 */
    color: $gray-600

  .form-control::-ms-input-placeholder /* Microsoft Edge */
    color: $gray-600

  table

    th
      vertical-align: bottom !important

    tr
      cursor: default !important

      &.section-row td
        background-color: $gray-100 !important

      &:not(.question-row) > td
        padding: 5px

      &.question-row td
        padding: 10px 5px

    &:last-child tr td
      border-bottom: 1px solid $gray-300

    td
      text-align: left !important
      white-space: normal !important
      overflow: visible

      &:not(.header-cell)
        vertical-align: top !important

      &:last-child
        min-width: 150px !important

      &:not(:last-child)
        width: 30% !important
        max-width: 30% !important

      &.summernote-cell
        width: 400px !important
        min-width: 400px !important
        max-width: 400px !important
        overflow: hidden

    input, textarea, select
      border: 1px solid $gray-500 !important

  .polling-indicator
    position: relative
    width: 30px
    min-width: 30px

    .spinner-border
      width: 15px !important
      height: 15px !important
      border-style: dotted !important

  .navbar
    z-index: 102 !important

  .fix-width.wider 
    padding: 0px 50px !important

  .get-included-section-content
    ul li
      margin-bottom: 0.5rem

  .table-container
    position: relative
    overflow: auto
    max-height: 500px

  .included-table-header
    border-top: 1px solid $gray-300 !important
    border: 1px solid $gray-300 !important
    background-color: $white !important

  .response-document-row

    &:not(:last-child)
      border-bottom: 1px solid $gray-300

  .questions-list
    padding-left: 0px
    padding-right: 0px
    border-top: 1px solid $gray-300

  .input-group select
    position: relative
    flex: 1 1 auto
    width: 1%
    min-width: 0
    margin-bottom: 0
    border-color: $gray-500 !important


  .file-upload-progress

    .progress
      margin-bottom: 0px !important
      max-width: none !important

  .filepicker-container
    position: relative
    height: 36px
    width: 100%
    display: flex !important
    align-items: center !important
    justify-content: center !important

    .response-filepicker
      opacity: 0

    .filepicker-label
      position: absolute
      top: 0px
      height: 100%
      width: 100%
      left: 0
      z-index: 1
      border: 1px solid $gray-500
      border-radius: 5px
      background-color: $white
      color: $gray-500

      &:hover
        background-color: darken($gray-100, 2%)

      &.opaque
        opacity: 0.2
